import { ForwardedRef, forwardRef } from 'react'
import { IconProps } from './types'

export const RefreshSalesIconWithRef = (
  { size = 24, ...restProps }: IconProps,
  ref: ForwardedRef<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      ref={ref}
      {...restProps}
    >
      <path
        fill="currentColor"
        d="M12.15 16.15q.825 0 1.413-.387t.587-1.213q0-.725-.612-1.175T11.35 12.35q-1.475-.525-2.162-1.25T8.5 9.2q0-1.025.713-1.862T11.15 6.25v-.375q0-.35.263-.612T12.025 5q.35 0 .613.263t.262.612v.375q.725.05 1.338.488t.987 1.062q.175.275.025.588t-.5.437q-.325.125-.65.025t-.525-.375q-.25-.3-.625-.487t-.9-.188q-.875 0-1.338.375T10.25 9.2q0 .65.575 1.025t2.075.875q1.8.65 2.4 1.525t.6 1.925q0 .725-.25 1.275t-.663.938q-.412.387-.962.624t-1.175.363v.375q0 .35-.262.613t-.613.262q-.35 0-.612-.262t-.263-.613V17.7q-.95-.2-1.625-.75T8.4 15.55q-.15-.35.013-.675t.512-.45q.325-.125.65.012t.5.438q.35.65.888.962t1.187.313M12 23q-2.8 0-5.15-1.275T3 18.325V20q0 .425-.288.713T2 21q-.425 0-.712-.288T1 20v-4q0-.425.288-.712T2 15h4q.425 0 .713.288T7 16q0 .425-.288.713T6 17H4.525q1.2 1.8 3.163 2.9T12 21q3.525 0 6.063-2.35t2.887-5.775q.05-.4.35-.638T22 12q.425 0 .725.263t.25.637q-.175 2.125-1.1 3.962t-2.4 3.2q-1.475 1.363-3.387 2.15T12 23m0-20Q8.475 3 5.938 5.35T3.05 11.125q-.05.4-.35.638T2 12q-.425 0-.725-.262t-.25-.638q.175-2.125 1.1-3.962t2.4-3.2Q6 2.575 7.913 1.788T12 1q2.8 0 5.15 1.275t3.85 3.4V4q0-.425.288-.712T22 3q.425 0 .713.288T23 4v4q0 .425-.288.713T22 9h-4q-.425 0-.712-.288T17 8q0-.425.288-.712T18 7h1.475q-1.2-1.8-3.162-2.9T12 3"
      />
    </svg>
  )
}

export const RefreshSalesIcon = forwardRef(RefreshSalesIconWithRef)
