import { IconProps } from '../icons'

export const RegisterIllustration = ({ size = 20, ...rest }: IconProps) => {
  return (
    <svg
      width="324"
      height="450"
      viewBox="0 0 324 551"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_29_602)">
        <path
          d="M93.0363 151.092C93.0363 151.092 93.3153 185.483 96.0584 200.606C98.8014 215.728 100.942 235.284 109.465 237.878C117.988 240.472 133.058 233.868 132.28 208.885C131.501 183.901 98.1111 149.65 93.0363 151.092Z"
          fill="#F1B52E"
        />
        <path
          d="M34.6279 194.063C34.5544 195.535 48.1962 200.947 50.943 204.733C53.6897 208.519 56.2675 188.544 56.2675 188.544L46.5879 181.969C46.5879 181.969 35.1493 183.562 34.6279 194.063Z"
          fill="#F4937F"
        />
        <path
          d="M59.4806 37.335H58.9806V37.835V509.526H34.8708V16.9355H292.467V509.526H270.722V37.835V37.335H270.222H59.4806Z"
          fill="#349898"
          stroke="#363D47"
        />
        <path
          d="M72.9021 186.175C71.7564 184.42 69.23 180.545 69.23 180.457C68.4656 179.251 67.5942 178.117 66.6265 177.068C65.6644 176.008 65.1577 175.328 63.5787 174.235C60.4647 172.064 59.8625 171.236 59.0987 172.929C58.9811 173.186 59.0766 173.569 59.2933 174.014L58.0925 172.859C58.0925 172.859 52.4816 167.509 51.402 169.485C50.4949 171.144 52.9406 173.186 53.451 173.576L49.195 171.862C49.195 171.862 43.2058 168.065 43.8558 171.862C43.977 172.572 43.8558 172.929 45.6368 174.526C47.0359 175.784 50.7447 177.819 52.2466 178.625C51.3669 178.835 50.5347 179.209 49.7936 179.728C48.3399 180.746 46.9651 181.873 45.6809 183.099C43.4152 185.365 42.8607 186.042 45.8424 186.565C47.785 186.896 50.539 184.699 51.0237 184.431C51.5084 184.162 56.5319 183.765 56.5319 183.765L59.9322 186.785C61.6542 188.266 62.8255 190.286 63.2555 192.518L63.3216 192.908C63.5786 194.361 62.22 195.664 60.4243 195.664L55.8746 192.823C55.8746 192.823 50.6162 188.68 48.4313 190.027C46.3419 191.315 49.5622 194.291 49.5622 194.291L54.8133 199.546C56.0011 200.708 57.449 201.568 59.0363 202.055L71.1065 205.874C71.1065 205.874 78.1348 193.659 78.1348 193.261C78.1495 193.044 75.2743 189.258 72.9021 186.175Z"
          fill="#F4937F"
        />
        <path
          d="M67.6217 196.458C69.6047 189.975 75.2597 187.941 75.6049 188.125C81.113 191.068 119.13 213.384 123.709 218.664C128.615 224.315 148.654 252.654 137.215 255.763C125.777 258.872 99.0732 238.735 87.7338 229.124C76.3944 219.514 71.246 217.115 71.246 217.115C71.246 217.115 64.4123 206.956 67.6217 196.458Z"
          fill="#F1B52E"
        />
        <path
          d="M118.15 73.9162C118.517 73.1803 122.42 71.5246 134.729 120.082C147.038 168.639 120.823 128.051 119.806 127.047C118.789 126.043 113.042 83.7439 118.15 73.9162Z"
          fill="#363D47"
        />
        <path
          d="M106.013 65.9792C106.013 65.9792 87.003 62.9878 79.8277 85.9695C73.0931 107.523 95.4341 165.507 95.6654 164.823C95.8968 164.139 110.952 131.889 110.952 131.889L106.013 65.9792Z"
          fill="#363D47"
        />
        <path
          d="M105.573 133.997C105.573 133.997 95.614 147.313 93.0362 151.099C90.4583 154.885 101.258 196.304 104.787 207.663C108.316 219.021 123.966 237.366 130.15 249.361C136.334 261.356 145.422 287.69 145.422 287.69C145.422 287.69 191.793 270.397 197.463 241.616C203.133 212.836 175.309 125.564 175.309 125.564L135.401 122.223C135.401 122.223 121.891 110.592 105.573 133.997Z"
          fill="#F1B52E"
        />
        <path
          d="M128.931 197.331C128.244 180.123 140.957 149.83 140.957 149.83L134.931 146.033L142.918 144.657C142.918 144.657 146.069 120.564 124.084 117.466C124.084 117.466 112.847 154.76 117.5 172.951C122.152 191.142 128.931 197.331 128.931 197.331Z"
          fill="#FAE6C9"
        />
        <path
          d="M105.217 134.115C103.381 141.474 100.685 153.123 100.175 159.812L109.006 152.585C109.006 152.585 105.672 162.91 104.6 167.042C104.6 167.042 124.473 189.07 128.938 197.331C128.938 197.331 115.678 170.946 116.916 138.129L111.676 132.459C111.213 131.955 110.622 131.586 109.966 131.392C109.311 131.197 108.615 131.184 107.953 131.354C107.29 131.524 106.686 131.87 106.204 132.356C105.723 132.842 105.381 133.45 105.217 134.115Z"
          fill="#FAE6C9"
        />
        <path
          d="M130.022 199.203C123.573 187.106 117.026 163.638 116.964 163.403L118.025 163.108C118.087 163.344 124.602 186.693 130.995 198.685L130.022 199.203Z"
          fill="#363D47"
        />
        <path
          d="M128.78 120.762C125.108 129.019 117.492 163.256 117.492 163.256C117.492 163.256 111.544 140.767 110.02 136.963C109.208 135.167 108.245 133.444 107.141 131.812C105.154 128.747 103.002 125.895 103.002 125.895C102.657 124.912 102.457 123.883 102.407 122.841C101.699 110.121 120.716 93.428 120.25 103.34C119.754 114.04 128.78 120.762 128.78 120.762Z"
          fill="#F4937F"
        />
        <path
          d="M129.636 249.523C126.819 240.273 123.639 236.34 119.244 230.895C114.266 224.93 109.892 218.485 106.186 211.655C94.4867 190.347 92.5037 151.515 92.4854 151.125L93.587 151.073C93.6053 151.441 95.5736 190.038 107.152 211.121C110.827 217.895 115.164 224.287 120.099 230.203C124.407 235.534 127.811 239.743 130.69 249.2L129.636 249.523Z"
          fill="#363D47"
        />
        <path
          d="M122.259 293.779C122.259 293.779 112.124 306.34 111.437 309.438C110.75 312.537 117.621 324.068 117.621 324.068C117.621 324.068 122.945 324.068 123.977 322.692C125.009 321.316 125.523 319.079 125.006 318.56C124.488 318.041 120.445 317.702 120.445 317.702V310.134L128.27 299.979C128.27 299.979 124.833 292.745 122.259 293.779Z"
          fill="#F4937F"
        />
        <path
          d="M116.942 308.468C116.942 308.468 119.431 320.834 122.45 321.394C125.468 321.953 126.452 321.471 127.826 318.719L125.457 306.856L133.954 298.942L125.354 295.34C125.354 295.34 117.18 303.81 116.942 308.468Z"
          fill="#F4937F"
        />
        <path
          d="M128.266 299.976C128.123 300.097 124.227 305.388 124.062 305.914C122.89 309.862 126.775 318.38 128.266 319.598C129.955 320.974 131.531 321.143 132.559 319.252C133.587 317.361 133.932 305.311 133.932 305.311L137.197 299.976L129.9 297.253L128.266 299.976Z"
          fill="#F4937F"
        />
        <path
          d="M131.652 305.31C131.652 305.31 131.534 319.767 135.482 319.594C139.429 319.421 142.165 319.068 142.87 317.872C145.103 314.086 140.88 315.042 140.3 313.571C139.958 312.71 139.44 308.364 139.44 308.364C139.44 308.364 142.532 308.581 144.251 306.34C145.969 304.1 145.624 300.148 145.624 299.629C145.624 299.11 148.195 291.902 144.423 291.027C140.652 290.151 124.657 290.747 122.266 293.779C119.876 296.811 124.385 303.934 131.652 305.31Z"
          fill="#F4937F"
        />
        <path
          d="M117.694 112.34C117.694 112.34 115.381 123.213 106.891 131.613C104.772 128.345 102.485 125.302 102.485 125.302C102.113 124.256 101.898 123.16 101.846 122.05L117.694 112.34Z"
          fill="#363D47"
        />
        <path d="M324 509.475H0V510.578H324V509.475Z" fill="#363D47" />
        <path
          d="M275.374 511.008L139.819 551V0L275.374 39.9916V511.008Z"
          fill="#349898"
        />
        <path d="M139.819 0H128.942V551H139.819V0Z" fill="#363D47" />
        <path
          d="M81.7776 88.6633C81.2194 93.0381 81.7776 98.6456 84.7153 105.589C84.7153 105.589 85.0825 111.737 87.1095 115.225C94.5638 128.103 100.289 128.103 105.397 125.7C107.49 124.718 116.934 119.416 119.765 107.671C122.597 95.9265 123.467 82.5334 117.808 77.4999C113.207 73.4121 105.305 69.4678 95.379 72.7388C88.0679 75.1819 82.7323 81.275 81.7776 88.6633Z"
          fill="#F4937F"
        />
        <path
          d="M119.505 74.7918C116.303 69.3794 110.211 65.3468 103.975 66.0642C101.038 66.3991 98.324 67.72 95.7168 69.1108C92.2394 70.9505 88.7949 73.0294 86.1142 75.9214C83.8743 78.3314 82.2109 81.4111 81.8547 84.6526C80.215 89.0187 79.5579 93.6938 79.9305 98.3438C81.0101 93.9064 83.5732 89.7928 86.7936 86.5696C85.5267 88.9134 84.6638 91.879 84.7446 94.5429C86.4157 90.3685 89.1292 86.6943 92.6249 83.8726C91.7693 86.209 91.2588 88.6227 91.7839 91.0512C92.6006 88.2453 93.6268 85.505 94.8539 82.8534C94.2516 85.0611 96.1464 87.4968 98.1587 88.5491C100.171 89.6015 102.518 89.8296 104.691 90.514C110.453 92.3242 114.54 102.546 119.563 102.546C116.486 102.546 117.918 99.9222 116.604 95.3487C116.27 94.1934 116.38 91.7502 117.213 90.8782C117.677 90.3933 118.274 90.0573 118.928 89.9128C119.582 89.7684 120.265 89.8219 120.889 90.0667C121.513 90.3115 122.05 90.7365 122.433 91.2878C122.815 91.8391 123.026 92.4918 123.037 93.1631C123.18 86.8713 122.707 80.2006 119.505 74.7918Z"
          fill="#363D47"
        />
        <path
          d="M118.418 89.0568C118.781 88.732 119.22 88.504 119.694 88.3941C120.168 88.2841 120.663 88.2957 121.131 88.4277C122.567 88.9244 123.368 90.6574 123.261 92.177C122.494 103.296 121.176 103.34 119.956 103.296C117.206 103.197 116.145 98.6197 115.869 96.9161C115.462 94.3883 116.387 90.7494 118.418 89.0568Z"
          fill="#F4937F"
        />
        <path
          d="M93.1243 112.52C92.9695 112.52 92.8149 112.509 92.6616 112.487C91.9396 112.362 91.2764 112.009 90.769 111.479C90.2616 110.95 89.9366 110.271 89.8415 109.544C89.713 108.388 90.2087 107.317 90.6273 106.372L90.8035 105.978C91.581 104.213 92.0386 102.323 92.1548 100.396L93.3887 100.474C93.2593 102.547 92.7619 104.58 91.9199 106.479C91.8611 106.611 91.7987 106.747 91.7399 106.883C91.3433 107.759 90.9651 108.587 91.0569 109.407C91.1052 109.873 91.3063 110.309 91.6287 110.648C91.951 110.987 92.3764 111.209 92.8382 111.28C93.3001 111.351 93.7723 111.266 94.1809 111.039C94.5894 110.812 94.9114 110.455 95.0962 110.026L96.2235 110.522C95.9485 111.115 95.5113 111.617 94.9627 111.971C94.4141 112.325 93.7766 112.515 93.1243 112.52Z"
          fill="#363D47"
        />
        <path
          d="M104.508 98.4167C104.508 98.8155 104.39 99.2053 104.169 99.5369C103.948 99.8685 103.633 100.127 103.266 100.28C102.898 100.432 102.493 100.472 102.103 100.394C101.713 100.316 101.354 100.124 101.073 99.8425C100.791 99.5605 100.6 99.2012 100.522 98.8101C100.444 98.419 100.484 98.0135 100.636 97.6451C100.789 97.2767 101.047 96.9618 101.378 96.7402C101.708 96.5187 102.098 96.4004 102.496 96.4004C103.029 96.4014 103.54 96.6141 103.917 96.992C104.295 97.37 104.507 97.8823 104.508 98.4167Z"
          fill="#363D47"
        />
        <path
          d="M87.0764 105.309C88.1878 105.309 89.0887 104.406 89.0887 103.293C89.0887 102.179 88.1878 101.276 87.0764 101.276C85.965 101.276 85.0641 102.179 85.0641 103.293C85.0641 104.406 85.965 105.309 87.0764 105.309Z"
          fill="#363D47"
        />
        <path
          d="M265.236 246.087L156.909 235.015V19.1182L265.236 50.7869V246.087ZM158.01 234.018L264.134 244.865V51.6148L158.01 20.5899V234.018Z"
          fill="#363D47"
        />
        <path
          d="M96.4696 120.593C95.8522 120.595 95.2418 120.462 94.6813 120.203C94.3833 120.091 94.1194 119.904 93.9153 119.659C93.7113 119.415 93.5741 119.122 93.5173 118.808C93.4475 118.157 93.8294 117.535 94.5968 117.064C95.7829 116.328 97.6484 115.831 98.9299 116.45C99.2167 116.567 99.4656 116.761 99.6496 117.011C99.8335 117.261 99.9453 117.557 99.9728 117.866C99.9739 118.272 99.8709 118.67 99.6734 119.024C99.4759 119.378 99.1907 119.675 98.8454 119.886C98.1399 120.35 97.3136 120.596 96.4696 120.593ZM97.5932 117.347C96.7489 117.383 95.9281 117.637 95.2101 118.083C94.8796 118.286 94.6666 118.532 94.685 118.698C94.7033 118.863 94.8942 119.018 95.1806 119.15C95.6602 119.371 96.1877 119.465 96.7138 119.425C97.2398 119.385 97.7472 119.212 98.1882 118.922C98.3591 118.823 98.5039 118.685 98.6106 118.518C98.7173 118.352 98.7827 118.162 98.8014 117.965C98.8014 117.899 98.783 117.697 98.4342 117.524C98.1717 117.398 97.8843 117.333 97.5932 117.333V117.347Z"
          fill="#363D47"
        />
        <path
          d="M275.374 149.782V108.87C277.319 108.875 279.182 109.652 280.555 111.032C281.929 112.411 282.7 114.28 282.7 116.229V142.46C282.69 144.402 281.915 146.261 280.542 147.633C279.17 149.004 277.312 149.777 275.374 149.782Z"
          fill="#F9A041"
        />
        <path
          d="M275.374 448.058V407.143C277.316 407.147 279.176 407.922 280.549 409.298C281.922 410.673 282.695 412.538 282.7 414.483V440.717C282.695 442.663 281.922 444.527 280.549 445.902C279.176 447.278 277.316 448.053 275.374 448.058Z"
          fill="#F9A041"
        />
        <path
          d="M96.6092 95.8161L95.559 95.1685C96.132 94.2341 96.8948 93.4308 97.7977 92.8109C98.7007 92.1909 99.7236 91.7682 100.8 91.5701C101.877 91.372 102.983 91.4029 104.047 91.6609C105.111 91.9189 106.109 92.3981 106.976 93.0675L106.223 94.0463C105.493 93.4825 104.652 93.0788 103.756 92.8615C102.861 92.6443 101.929 92.6183 101.022 92.7852C100.116 92.9521 99.2544 93.3081 98.4941 93.8303C97.7338 94.3525 97.0916 95.0291 96.6092 95.8161Z"
          fill="#363D47"
        />
        <path
          d="M83.2427 99.672L82.108 99.19C82.8632 97.4347 84.2704 96.0426 86.0318 95.3085C87.7932 94.5744 89.7705 94.5558 91.5453 95.2567L91.0936 96.4047C89.6133 95.8184 87.9629 95.834 86.4939 96.4484C85.0249 97.0627 83.8532 98.2272 83.228 99.6941L83.2427 99.672Z"
          fill="#363D47"
        />
        <path
          d="M120.684 101.309L119.582 100.75C120.915 98.1184 121.52 95.177 121.333 92.2319C121.308 91.7757 121.234 91.2201 120.933 90.9772C120.632 90.7344 120.019 90.8705 119.674 91.1833C119.234 91.6718 118.909 92.2526 118.723 92.8832L117.57 92.4416C117.838 91.7462 118.168 90.8779 118.847 90.2671C119.527 89.6563 120.819 89.2994 121.704 90.0132C122.384 90.5578 122.52 91.4445 122.567 92.151C122.768 95.3175 122.117 98.48 120.684 101.309Z"
          fill="#363D47"
        />
        <path
          d="M121.183 95.4917C121.165 95.2694 121.07 95.0604 120.914 94.9011C120.758 94.7419 120.551 94.6425 120.33 94.6201C120.109 94.5978 119.886 94.654 119.702 94.779C119.518 94.9039 119.383 95.0897 119.321 95.304L118.131 94.9802C118.266 94.4809 118.574 94.0458 119 93.7525C119.425 93.4591 119.94 93.3265 120.454 93.3781C120.968 93.4298 121.447 93.6624 121.805 94.0346C122.164 94.4068 122.379 94.8944 122.413 95.4107L121.183 95.4917Z"
          fill="#363D47"
        />
        <path
          d="M101.882 225.725C96.5467 213.583 94.4427 199.167 94.4207 199.023L95.5223 198.865C95.5443 199.009 97.63 213.278 102.903 225.28L101.882 225.725Z"
          fill="#363D47"
        />
        <path
          d="M125.424 322.148C124.018 322.082 122.638 321.74 121.363 321.144C119.527 320.198 118.245 311.257 117.896 308.538L117.878 308.394L121.892 298.46L122.912 298.876L119.005 308.531C119.692 313.788 120.97 319.69 121.866 320.15C122.996 320.671 124.215 320.971 125.457 321.033L125.424 322.148Z"
          fill="#363D47"
        />
        <path
          d="M127.297 318.925C126.445 316.827 125.828 312.909 125.354 309.759C125.182 308.633 125.031 307.658 124.899 306.959C124.363 304.152 127.657 298.629 127.796 298.394L128.74 298.964C127.844 300.458 125.6 304.766 125.982 306.753C126.118 307.489 126.269 308.453 126.445 309.59C126.897 312.534 127.517 316.533 128.318 318.509L127.297 318.925Z"
          fill="#363D47"
        />
        <path
          d="M275.925 511.009H274.823V40.4075L195.374 16.9659L195.686 15.9062L275.925 39.5796V511.009Z"
          fill="#363D47"
        />
        <mask
          id="mask0_29_602"
          maskUnits="userSpaceOnUse"
          x="43"
          y="169"
          width="36"
          height="37"
        >
          <path
            d="M72.9021 186.175C71.7564 184.42 69.23 180.545 69.23 180.457C68.4656 179.251 67.5942 178.117 66.6265 177.068C65.6644 176.008 65.1577 175.328 63.5787 174.235C60.4647 172.064 59.8625 171.236 59.0987 172.929C58.9811 173.186 59.0766 173.569 59.2933 174.014L58.0925 172.859C58.0925 172.859 52.4816 167.509 51.402 169.485C50.4949 171.144 52.9406 173.186 53.451 173.576L49.195 171.862C49.195 171.862 43.2058 168.065 43.8558 171.862C43.977 172.572 43.8558 172.929 45.6368 174.526C47.0359 175.784 50.7447 177.819 52.2466 178.625C51.3669 178.835 50.5347 179.209 49.7936 179.728C48.3399 180.746 46.9651 181.873 45.6809 183.099C43.4152 185.365 42.8607 186.042 45.8424 186.565C47.785 186.896 50.539 184.699 51.0237 184.431C51.5084 184.162 56.5319 183.765 56.5319 183.765L59.9322 186.785C61.6542 188.266 62.8255 190.286 63.2555 192.518L63.3216 192.908C63.5786 194.361 62.22 195.664 60.4243 195.664L55.8746 192.823C55.8746 192.823 50.6162 188.68 48.4313 190.027C46.3419 191.315 49.5622 194.291 49.5622 194.291L54.8133 199.546C56.0011 200.708 57.449 201.568 59.0363 202.055L71.1065 205.874C71.1065 205.874 78.1348 193.659 78.1348 193.261C78.1495 193.044 75.2743 189.258 72.9021 186.175Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_29_602)">
          <path
            d="M62.8772 181.436C61.8159 180.06 59.9395 178.098 59.0986 178.058C53.1975 177.771 51.1926 178.901 49.9955 179.578C49.6564 179.798 49.2846 179.963 48.8939 180.067L48.6882 178.963C48.964 178.878 49.2261 178.754 49.4667 178.595C50.6785 177.907 52.9405 176.627 59.1611 176.932C60.8098 177.013 63.2812 180.118 63.7585 180.74L62.8772 181.436Z"
            fill="#363D47"
          />
          <path
            d="M63.7989 180.822C62.5761 179.089 61.0485 177.076 60.707 176.848C60.2958 176.634 52.577 173.146 48.5524 171.329L49.0077 170.324C49.4814 170.538 60.6666 175.586 61.2284 175.873C61.7903 176.16 63.6557 178.702 64.6986 180.185L63.7989 180.822Z"
            fill="#363D47"
          />
          <path
            d="M65.433 180.251C64.8675 178.952 64.1552 178.345 63.0793 177.421L62.8699 177.245C59.8159 174.539 56.943 171.634 54.2699 168.55L55.1035 167.814C57.7435 170.852 60.577 173.716 63.586 176.387L63.7953 176.564C64.8969 177.513 65.7745 178.26 66.4429 179.791L65.433 180.251Z"
            fill="#363D47"
          />
        </g>
        <path
          d="M153.317 257.052V353.449L180.884 348.941V261.011L153.317 257.052Z"
          fill="#363D47"
        />
        <path
          d="M169.93 305.249C173.39 305.249 176.194 299.346 176.194 292.065C176.194 284.784 173.39 278.882 169.93 278.882C166.47 278.882 163.665 284.784 163.665 292.065C163.665 299.346 166.47 305.249 169.93 305.249Z"
          fill="#FAE6C9"
        />
        <path
          d="M198.227 283.838L178.438 285.428L169.93 286.112V278.882H198.227V283.838Z"
          fill="#FAE6C9"
        />
        <path
          d="M178.79 285.536L169.902 286.082L169.97 287.184L178.858 286.638L178.79 285.536Z"
          fill="#363D47"
        />
        <path
          d="M213.646 142.066C210.007 142.066 207.036 137.614 207.036 132.132C207.036 126.649 209.996 122.197 213.646 122.197C217.296 122.197 220.256 126.649 220.256 132.132C220.256 137.614 217.285 142.066 213.646 142.066ZM213.646 123.323C210.613 123.323 208.138 127.279 208.138 132.154C208.138 137.029 210.602 140.984 213.646 140.984C216.69 140.984 219.154 137.029 219.154 132.154C219.154 127.279 216.679 123.323 213.646 123.323Z"
          fill="#363D47"
        />
        <path
          d="M213.646 137.283C211.678 137.283 210.136 135.024 210.136 132.132C210.136 129.24 211.678 126.98 213.646 126.98C215.614 126.98 217.16 129.24 217.16 132.132C217.16 135.024 215.618 137.283 213.646 137.283ZM213.646 128.106C212.339 128.106 211.237 129.946 211.237 132.154C211.237 134.361 212.339 136.201 213.646 136.201C214.953 136.201 216.059 134.361 216.059 132.154C216.059 129.946 214.953 128.106 213.646 128.106Z"
          fill="#363D47"
        />
        <path
          d="M156.909 522.113V389.242L265.236 374.237V496.887L156.909 522.113ZM158.01 390.191V520.726L264.134 496.011V375.503L158.01 390.191Z"
          fill="#363D47"
        />
      </g>
      <defs>
        <clipPath id="clip0_29_602">
          <rect width="324" height="551" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
