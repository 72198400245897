import { ForwardedRef, forwardRef } from 'react'
import { IconProps } from './types'

const ServiceConfigWithRef = (
  { size = 24, ...rest }: IconProps,
  ref: ForwardedRef<SVGSVGElement>
) => {
  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      {...rest}
    >
      <path
        fill="currentColor"
        d="M29 25h-2v-2h1v-4h-4v1h-2v-2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1Z"
      />
      <path
        fill="currentColor"
        d="M24 30h-6a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1zm-5-2h4v-4h-4zm-4-8.142A3.993 3.993 0 1 1 20 16h2a6 6 0 1 0-7 5.91z"
      />
      <path
        fill="currentColor"
        d="m28.89 13.55l-2.31 2.03l-1.42-1.42l2.41-2.12l-2.36-4.08l-3.44 1.16a9.368 9.368 0 0 0-2.7-1.57L18.36 4h-4.72l-.71 3.55a8.86 8.86 0 0 0-2.71 1.57L6.79 7.96l-2.36 4.08l2.72 2.39a8.895 8.895 0 0 0 0 3.13l-2.72 2.4l2.36 4.08l3.44-1.16a9.368 9.368 0 0 0 2.7 1.57l.71 3.55H15v2h-1.36a2 2 0 0 1-1.96-1.61l-.51-2.52a11.412 11.412 0 0 1-1.31-.75l-2.43.82a2.038 2.038 0 0 1-.64.1a1.973 1.973 0 0 1-1.73-1L2.7 20.96a2 2 0 0 1 .41-2.51l1.92-1.68C5.01 16.51 5 16.26 5 16s.02-.51.04-.76l-1.93-1.69a2 2 0 0 1-.41-2.51l2.36-4.08a1.973 1.973 0 0 1 1.73-1a2.038 2.038 0 0 1 .64.1l2.42.82a11.542 11.542 0 0 1 1.32-.75l.51-2.52A2 2 0 0 1 13.64 2h4.72a2 2 0 0 1 1.96 1.61l.51 2.52a11.412 11.412 0 0 1 1.31.75l2.43-.82a2.038 2.038 0 0 1 .64-.1a1.973 1.973 0 0 1 1.73 1l2.36 4.08a2 2 0 0 1-.41 2.51Z"
      />
    </svg>
  )
}

export const ServiceConfigIcon = forwardRef(ServiceConfigWithRef)
