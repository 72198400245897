import { IconProps } from './types'

const NotificationIcon = ({ size = 20, ...rest }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...rest}
  >
    <path
      d="M11.639 2.008C7.597 2.198 4.5 5.671 4.5 9.69v3.634L3.175 15.99a.966.966 0 00-.007.015c-.533 1.133.33 2.495 1.583 2.495h4.25c0 1.648 1.351 3 3 3 1.647 0 3-1.352 3-3h4.247c1.253 0 2.118-1.361 1.584-2.495l-.006-.015-1.326-2.666V9.5c0-4.253-3.568-7.694-7.861-7.492zm.07 1.499A5.987 5.987 0 0118 9.5v4c0 .116.027.23.078.334l1.397 2.81c.09.193-.014.356-.227.356H4.751c-.212 0-.315-.163-.225-.356l1.396-2.81A.75.75 0 006 13.5V9.69c0-3.25 2.49-6.032 5.71-6.183zM10.5 18.5h3c0 .837-.663 1.5-1.5 1.5s-1.5-.663-1.5-1.5z"
      fill="#363D47"
    />
  </svg>
)

export { NotificationIcon }
