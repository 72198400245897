import { IconProps } from '../icons'

export const LoginIllustration = ({ size = 20, ...rest }: IconProps) => {
  return (
    <svg
      width={483}
      height={589}
      viewBox="0 0 571 660"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_29_11081)">
        <path
          d="M75.16 586.032a.5.5 0 01-.547-.498V1a.5.5 0 01.5-.5h303.565a.5.5 0 01.5.5v581.83a.5.5 0 01-.5.5h-24.872a.5.5 0 01-.5-.5V26.27a1.5 1.5 0 00-1.5-1.5H104.784a1.5 1.5 0 00-1.5 1.5v556.65a.5.5 0 01-.453.498l-27.67 2.614z"
          fill="#9BDAD6"
          stroke="#363D47"
        />
        <path
          d="M333.547 583.83V72.345L103.783 25.27v558.56h229.764z"
          fill="#9BDAD6"
        />
        <path
          d="M322.06 271.145l-.68-.066-197.021-18.592V51.411L322.06 89.035v182.11zm-196.457-19.771l195.213 18.421V90.065L125.603 52.918v198.456zM322.06 557.556l-.68-.066-197.021-18.592V337.905l197.701 19.127v200.524zm-196.457-19.792l195.213 18.422V358.154l-195.213-18.891v198.501z"
          fill="#349898"
        />
        <path d="M571 583.256h-708.135v1.181H571v-1.181z" fill="#363D47" />
        <path d="M104.404 25.27h-1.244v558.56h1.244V25.27z" fill="#363D47" />
        <path
          d="M98.865 169.22a4.295 4.295 0 01-3.041-1.261 4.31 4.31 0 01-1.26-3.045v-62.623c0-1.143.453-2.239 1.26-3.047a4.301 4.301 0 013.04-1.264 4.31 4.31 0 014.304 4.311v62.623a4.3 4.3 0 01-1.26 3.046 4.301 4.301 0 01-3.043 1.26zM98.865 493.442a4.302 4.302 0 01-3.041-1.264 4.313 4.313 0 01-1.26-3.047v-62.636a4.312 4.312 0 014.3-4.306c1.142 0 2.236.454 3.043 1.261a4.315 4.315 0 011.262 3.045v62.636a4.31 4.31 0 01-4.304 4.311z"
          fill="#F1B52E"
        />
        <path
          d="M233.118 150.371c-3.778 0-6.85-3.904-6.85-8.7 0-4.796 3.072-8.721 6.85-8.721 3.778 0 6.847 3.904 6.847 8.721s-3.073 8.7-6.847 8.7zm0-16.154c-3.093 0-5.606 3.322-5.606 7.475 0 4.152 2.513 7.454 5.606 7.454 3.094 0 5.603-3.322 5.603-7.454s-2.513-7.475-5.603-7.475z"
          fill="#363D47"
        />
        <path
          d="M233.118 145.932c1.814 0 3.285-1.908 3.285-4.261s-1.471-4.261-3.285-4.261-3.284 1.908-3.284 4.261 1.47 4.261 3.284 4.261z"
          fill="#363D47"
        />
        <path
          d="M369.708 583.208a.621.621 0 11-1.244 0V16.893a1 1 0 00-1-1H88.652a1 1 0 00-1 1v566.315a.622.622 0 11-1.244 0V15.647a1 1 0 011-1h281.3a1 1 0 011 1v567.561z"
          fill="#363D47"
        />
        <path
          d="M98.969 428.028h-1.244v58.528h1.244v-58.528zM98.969 104.329h-1.244v58.529h1.244v-58.529z"
          fill="#F5F6F9"
        />
        <path
          d="M243.817 565.288s3.981-1.27 5.449.142c1.468 1.411 1.891 10.46 4.562 14.119 2.67 3.658-10.023 1.412-10.023 1.412s-2.509-13.825.012-15.673z"
          fill="#ADAFA6"
        />
        <path
          d="M220.454 581.687s-.634-24.173-1.273-28.201c-.639-4.028 26.469-6.574 26.469-6.574s.212 25.021 3.388 34.775c3.177 9.755-28.252 10.827-28.584 0z"
          fill="#F4937F"
        />
        <path
          d="M295.413 601.197s-23.01 13.363-34.373 12.936c-11.362-.428-23.981 10.348-45.88 2.757-1.875-.648-2.008-5.324-1.369-11.212.896-8.248 3.318-18.87 4.562-24.132a2.217 2.217 0 013.172-1.441c2.372 1.213 6.664 2.176 13.527-.324 11.648-4.24 14.203-7.28 14.203-7.28s9.733 10.677 18.209 11.508c7.646.768 27.896.319 28.48 12.711a14.774 14.774 0 01-.531 4.477z"
          fill="#ADAFA6"
        />
        <path
          d="M243.817 565.288s3.981-1.27 5.449.142c1.468 1.411 1.891 10.46 4.562 14.119 2.67 3.658-10.023 1.412-10.023 1.412s-2.509-13.825.012-15.673z"
          fill="#ADAFA6"
        />
        <path
          d="M220.454 581.687s-.634-24.173-1.273-28.201c-.639-4.028 26.469-6.574 26.469-6.574s.212 25.021 3.388 34.775c3.177 9.755-28.252 10.827-28.584 0z"
          fill="#F4937F"
        />
        <path
          d="M295.413 601.197s-23.01 13.363-34.373 12.936c-11.362-.428-23.981 10.348-45.88 2.757-1.875-.648-2.008-5.324-1.369-11.212.896-8.248 3.318-18.87 4.562-24.132a2.217 2.217 0 013.172-1.441c2.372 1.213 6.664 2.176 13.527-.324 11.648-4.24 14.203-7.28 14.203-7.28s9.733 10.677 18.209 11.508c7.646.768 27.896.319 28.48 12.711a14.774 14.774 0 01-.531 4.477z"
          fill="#ADAFA6"
        />
        <path
          d="M196.486 309.483l-12.855 57.495-11.549 51.573-2.048 9.136-39.395 130.812-58.508-35.045c3.176-9.377 36.272-105.293 36.272-105.293s9.849-44.227 10.164-47.562c1.273-13.396 5.719-28.803 6.353-34.16.635-5.357 7.626-39.522 7.626-39.522l63.94 12.566z"
          fill="#349898"
        />
        <path
          d="M183.614 366.978l-11.549 51.573c-3.906-28.716-2.782-72.773-2.782-72.773l14.331 21.2zM129.149 562.456c-6.453 3.775-14.456 1.94-23.496-2.608a134.715 134.715 0 01-12.209-7.155c-1.87-1.2-3.77-2.462-5.693-3.737-6.73-4.514-11.744-9.722-14.514-14.385-1.534-2.588-2.385-5.005-2.451-7.06a5.522 5.522 0 011.34-4.049c.16-.174.335-.335.522-.482 1.078-.855 2.778-1.52 4.976-1.835a24.14 24.14 0 016.722.041c5.126.69 11.37 2.849 17.964 7.313a63.474 63.474 0 019.273 7.728l1.994 1.998c10.728 10.727 20.942 21.088 15.572 24.231z"
          fill="#363D47"
        />
        <path
          d="M113.565 538.212l-7.912 21.636-.871 2.38-1.037 2.836-33.308-23.708 2.796-6.802 2.073-5.075 2.289-8.343a24.179 24.179 0 016.722.042c5.126.689 11.37 2.849 17.964 7.313a63.413 63.413 0 019.272 7.728l2.012 1.993z"
          fill="#F4937F"
        />
        <path
          d="M58.127 556.962c.61-1.337 4.238-10.382 11.026-17.354 6.789-6.973 21.473-8.086 28.995 1.104 7.523 9.19 8.995 35.299 11.748 43.372 2.754 8.073 24.956 49.758 1.559 70.297-25.78 22.624-53.328-97.419-53.328-97.419z"
          fill="#ADAFA6"
        />
        <path
          d="M69.828 545.131a8.962 8.962 0 00-5.946 3.031c-4.205 4.772-11.45 15.88-6.22 31.773 7.136 21.623 8.007 40.003 10.387 49.085 2.38 9.083 16.21 33.297 34.241 30.801 18.03-2.495 22.55-30.394 16.608-43.259-5.942-12.865-16.637-27.022-17.939-36.32-1.211-8.949-3.795-37.433-31.13-35.111z"
          fill="#363D47"
        />
        <path
          d="M223.764 103.121s0 31.976-5.088 38.106c-5.901 7.059-36.107 2.196-38.288-8.061-2.181-10.257-12.855-30.531 0-42.744 12.855-12.213 40.71-11.478 43.376 12.699z"
          fill="#F4937F"
        />
        <path
          d="M213.103 140.981s-4.848 12.704-3.637 20.274c1.211 7.571-32.955-7.329-32.955-7.329s6.548-13.705.365-32.62c-6.183-18.916 35.016-6.217 36.227 19.675z"
          fill="#F4937F"
        />
        <path
          d="M210.194 140.981s-21.488 6.35-31.827-7.001c0 0-8.708-28.006-8.239-35.008.468-7.001 4.847-6.353 4.847-6.353s-1.339-4.568 1.269-8.306c2.609-3.737 19.246-13.027 33.95-6.516 14.705 6.512 19.076 16.773 18.902 21.005-.174 4.232-2.215 13.239-5.632 15.494-3.417 2.255-7.132 16.748-9.231 20.984-2.098 4.236-4.039 5.701-4.039 5.701z"
          fill="#010101"
        />
        <path
          d="M217.787 128.606s-3.877-11.278-.808-15.003c3.068-3.725 7.319 1.802 5.39 8.492-1.928 6.69-1.837 7.001-4.582 6.511z"
          fill="#F4937F"
        />
        <path
          d="M108.519 336.459l.24.561c.859 2.002 3.712 8.721 3.824 9.709.128 1.163-4.002 8.816-4.064 10.797-.063 1.981-3.16 3.925-4.977 1.213-1.816-2.712-.02-12.255-.02-12.255l-1.485-7.475 6.482-2.55z"
          fill="#F27864"
        />
        <path
          d="M84.52 321.921l.776 10.274s-1.634 3.654-3.097 10.851c-1.278 6.279-.875 13.459 1.638 16.752 2.513 3.293 19.842-3.962 20.232-7.641.39-3.679-1.746-7.682 0-9.551 1.746-1.869 4.45-6.138 4.45-6.138s-4.45-10.079-5.42-15.506c-.971-5.428-16.837-4.273-18.578.959z"
          fill="#F4937F"
        />
        <path
          d="M93.06 359.914c.037-.415 2.8-8.662 5.184-10.448 1.787-1.337 4.561-.507 3.317 3.713-1.406 4.813-2.073 9.617-3.019 11.158-.838 1.358-5.955 1.325-5.482-4.423z"
          fill="#F27864"
        />
        <path
          d="M100.577 356.812c.033-.415 2.795-8.658 5.179-10.444 1.787-1.341 4.562-.511 3.318 3.713-1.406 4.813-2.074 9.618-3.019 11.158-.842 1.354-5.959 1.321-5.478-4.427z"
          fill="#F4937F"
        />
        <path
          d="M91.393 356.551s5.61-.416 8.679.066c0 0 3.293 4.809 1.373 7.143-1.92 2.334-9.65-2.87-10.052-7.209z"
          fill="#F27864"
        />
        <path
          d="M97.6 359.914c.032-.415 2.794-8.662 5.183-10.448 1.787-1.337 4.561-.507 3.317 3.713-1.406 4.813-2.073 9.617-3.019 11.158-.846 1.358-5.955 1.325-5.482-4.423z"
          fill="#F27864"
        />
        <path
          d="M82.488 357.132s5.275-.39 8.161.062c0 0 3.098 4.523 1.29 6.719-1.808 2.197-9.073-2.699-9.45-6.781z"
          fill="#F4937F"
        />
        <path
          d="M88.328 360.296c.033-.39 2.63-8.143 4.873-9.821 1.68-1.246 4.283-.477 3.126 3.489-1.322 4.526-1.936 9.044-2.84 10.494-.788 1.274-5.607 1.241-5.159-4.162z"
          fill="#F4937F"
        />
        <path
          d="M141.217 211.885a89.064 89.064 0 01-.647 3.431c-3.028 14.439-10.475 31.976-19.515 44.804-4.247 6.025-8.85 11.004-13.511 14.119-14.568 9.726-23.616-13.704-24.022-23.953-.146-3.638 1.75-9.377 4.743-16.196 4.86-11.083 12.619-25.053 19.242-37.595.788-1.499 1.563-2.973 2.314-4.422 10.923-21.067 18.611-27.957 18.611-27.957 13.415-1.171 16.301 26.769 12.785 47.769z"
          fill="#FAE6C9"
        />
        <path
          d="M84.92 240.12a111.977 111.977 0 00-6.772 23.625c-1.394 8.376.12 51.079.074 54.467-.041 2.637.896 13.526 3.272 14.664 4.184 2.01 9.832 3.816 19.047-.872 1.592-.831 7.257-4.984 6.776-6.686-.888-3.156 5.27-57.391 13.468-72.74 7.73-14.468-26.191-25.788-35.866-12.458z"
          fill="#FAE6C9"
        />
        <path
          d="M141.217 211.885a89.064 89.064 0 01-.647 3.431c-3.027 14.439-10.475 31.976-19.515 44.804-4.483-7.853-16.513-25.843-32.79-26.046 4.86-11.084 12.619-25.054 19.242-37.595 9.658-.058 24.259 2.338 33.71 15.406z"
          fill="#F1B52E"
        />
        <path
          d="M269.117 540.742a37.05 37.05 0 01-4.781 7.707c-3.682 4.655-8.543 8.987-13.838 12.583-5.677 3.854-11.848 6.864-17.591 8.513-13.975 4.02-20.962-2.006-20.962-4.016 0-2.01-15.46-131.019-15.46-131.019s-14.149-35.19-22.393-60.63c-8.244-25.44-4.064-60.215-4.064-60.215l49.194-7.753 10.231-1.615 8.546-1.346s15.937 119.118 17.985 131.173c2.049 12.055 13.133 106.618 13.133 106.618z"
          fill="#349898"
        />
        <mask
          id="a"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x={168}
          y={302}
          width={102}
          height={269}
        >
          <path
            d="M269.117 540.742a37.05 37.05 0 01-4.781 7.707c-3.682 4.655-8.543 8.987-13.838 12.583-5.677 3.854-11.848 6.864-17.591 8.513-13.975 4.02-20.962-2.006-20.962-4.016 0-2.01-15.46-131.019-15.46-131.019s-14.149-35.19-22.393-60.63c-8.244-25.44-4.064-60.215-4.064-60.215l49.194-7.753 10.231-1.615 8.546-1.346s15.937 119.118 17.985 131.173c2.049 12.055 13.133 106.618 13.133 106.618z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#a)" fill="#363D47">
          <path d="M220.856 372.024l-.883-.876c22.339-22.579 17.076-53.326 17.002-53.637l1.244-.22c0 .078 1.41 7.998-.033 18.637-1.332 9.821-5.416 24.057-17.33 36.096zM219.961 468.878c-9.637-10.29-17.351-23.164-22.982-32.566-4.047-6.748-7.539-12.578-9.086-12.736-.074 0-.153 0-.273.129l-.917-.831a1.52 1.52 0 011.315-.523c2.073.208 4.719 4.477 10.027 13.334 5.602 9.352 13.27 22.159 22.807 32.354l-.891.839z" />
        </g>
        <path
          d="M131.207 303.47s1.899 12.936 32.71 18.663c30.811 5.726 75.224-10.594 75.224-10.594l-.303-3.538-104.533-13.372-3.098 8.841z"
          fill="#363D47"
        />
        <path
          d="M245.054 249.298a576.756 576.756 0 00-1.522-30.191c-2.302-30.066-6.602-55.468-10.305-56.211-2.857-.574-9.409-3.069-19.283-5.179-.991-2.703-3.375-8.675-5.391-9.609-2.629-1.217-33.174-3.97-37.218-2.147-2.981 1.341-6.415 6.956-9.123 10.062-8.293 1.499-24.342 4.912-33.788 8.089v28.364s.191 9.991.709 21.955c.551 12.716 1.472 27.657 2.932 35.174a78.079 78.079 0 011.244 17.246c-.232 8.14-1.464 16.956-3.38 26.806-.862 4.423-2.973 8.252 1.244 9.826 16.865 6.295 64.853 19.704 107.632 4.53a3.983 3.983 0 002.525-2.836.115.115 0 000-.037c1.169-4.647 2.04-10.316 2.65-16.69.464-4.896.784-10.208.966-15.781.282-7.45.29-15.357.108-23.371z"
          fill="#FAE6C9"
        />
        <path
          d="M244.988 272.649c-13.76-.777-44.309 5.145-67.805 3.899-20.855-1.088-35.025-9.418-43.845-9.709a78.074 78.074 0 00-1.244-17.246c-1.46-7.517-2.38-22.458-2.932-35.174 4.371-.05 21.526 5.137 48.041 5.888 21.075.598 50.559-3.592 66.35-1.212a577.81 577.81 0 011.522 30.19c.161 8.028.153 15.934-.087 23.364z"
          fill="#F1B52E"
        />
        <path
          d="M244.021 288.437c-19.589-33.446-18.855-64.434-19.589-66.888l20.622 27.749c.319 14.044.058 27.719-1.033 39.139zM317.013 315.974c6.896 0 12.487-5.598 12.487-12.504 0-6.905-5.591-12.504-12.487-12.504-6.895 0-12.486 5.599-12.486 12.504 0 6.906 5.591 12.504 12.486 12.504z"
          fill="#363D47"
        />
        <path
          d="M300.766 278.57l11.196 4.934 10.782-4.016s13.046 6.375 14.327 9.107c1.282 2.733.324 18.862-1.737 21.233-1.298 1.499-6.249 1.246-7.97-2.492-1.763-3.858-5.221-3.554-5.221-3.554s-4.209 6.644-9.774 6.042c-5.565-.602-10.442-8.002-11.417-9.182-.974-1.179-7.879-3.957-7.879-3.957s-1.774-17.002 7.693-18.115z"
          fill="#F4937F"
        />
        <path
          d="M326.884 305.895c.266.673.47 1.368.609 2.077.116.656.116 1.279-.199 1.536-4.466 3.642-5.187 5.266-5.163 5.947.059 1.516 1.68 2.932 5.068.93l5.602-2.304a4.03 4.03 0 002.028-3.248c.149-2.492-.377-6.943-1.82-8.305-1.613-1.566-6.125 3.367-6.125 3.367z"
          fill="#F4937F"
        />
        <path
          d="M335.38 295.393s1.571 9.51 1.683 11.25c.112 1.74-1.09 5.22-3.002 4.863-1.912-.357-4.147-14.074-4.147-14.074l5.466-2.039z"
          fill="#F4937F"
        />
        <path
          d="M337.06 288.595s4.026 11.337 3.682 15.207c-.344 3.871-4.976 5.278-4.976 5.278s-1.41-17.699 1.294-20.485z"
          fill="#F4937F"
        />
        <path
          d="M257.246 283.089c-2.903.186-7.771-5.561-13.154-14.12-8.708-13.824-18.681-34.987-23.678-50.343a73.826 73.826 0 01-2.903-11.425c-3.118-20.905 6.349-47.187 6.349-47.187 21.182 3.156 10.048 8.471 14.195 16.922 1.741 3.563 21.178 17.267 25.005 27.491 5.254 14.045 11.292 30.809 14.85 40.477a67.002 67.002 0 011.916 5.992c7.51 28.156-14.253 31.649-22.58 32.193z"
          fill="#FAE6C9"
        />
        <path
          d="M267.24 239.904a15.804 15.804 0 0110.039 7.085c5.018 7.89 18.184 26.519 35.763 35.36 5.017 2.525 2.33 18.347-9.663 23.671-11.992 5.324-43.069-16.416-49.521-24.535-5.756-7.23-5.943-45.622 13.382-41.581z"
          fill="#FAE6C9"
        />
        <path
          d="M279.826 250.892c-4.147 7.126-13.685 16.096-35.733 18.077-8.709-13.824-18.682-34.987-23.679-50.344 0 0 25.242 3.065 42.646-14.202 5.254 14.045 11.292 30.809 14.85 40.477a64.236 64.236 0 011.916 5.992z"
          fill="#F1B52E"
        />
        <path
          d="M130.469 242.836c-3.222-16.848-2.804-34.127-2.667-39.808.025-.947.042-1.765.025-1.919a.617.617 0 00.324.415l.564-1.108c.389.199.389.199.331 2.628-.136 5.644-.555 22.84 2.642 39.543l-1.219.249z"
          fill="#363D47"
        />
        <mask
          id="b"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x={128}
          y={145}
          width={118}
          height={171}
        >
          <path
            d="M245.054 249.298a576.756 576.756 0 00-1.522-30.191c-2.302-30.066-6.602-55.468-10.305-56.211-2.857-.574-9.409-3.069-19.283-5.179-.991-2.703-3.375-8.675-5.391-9.609-2.629-1.217-33.174-3.97-37.218-2.147-2.981 1.341-6.415 6.956-9.123 10.062-8.293 1.499-24.342 4.912-33.788 8.089v28.364s.191 9.991.709 21.955c.551 12.716 1.472 27.657 2.932 35.174a78.079 78.079 0 011.244 17.246c-.232 8.14-1.464 16.956-3.38 26.806a9.216 9.216 0 001.002 6.257 9.191 9.191 0 004.804 4.125c16.865 6.296 60.303 19.128 103.082 3.953a3.976 3.976 0 002.525-2.836.115.115 0 000-.037c1.17-4.647 2.041-10.316 2.65-16.69.465-4.896.784-10.208.966-15.781.27-7.429.278-15.336.096-23.35z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#b)">
          <path
            d="M219.008 158.186c-26.913-5.647-62.447-1.598-62.812-1.557l-.141-1.246c.356-.045 36.077-4.103 63.206 1.578l-.253 1.225z"
            fill="#363D47"
          />
        </g>
        <path
          d="M261.098 278.23c-.796 0-1.591-.064-2.377-.191-4.221-.677-7.978-3.119-8.293-4.685l1.244-.232c.12.627 2.965 2.998 7.248 3.688 4.218.676 10.169-.187 14.568-8.144l1.087.602c-4.085 7.384-9.554 8.962-13.477 8.962zM109.024 245.037c-10.985-7.762-20.473-4.875-20.568-4.838l-.382-1.184c.415-.133 10.148-3.135 21.667 4.984l-.717 1.038zM149.582 440.324c-19.267-13.704-39.935-12.043-40.142-12.01l-.112-1.246c.212 0 21.319-1.731 40.971 12.234l-.717 1.022z"
          fill="#363D47"
        />
        <mask
          id="c"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x={250}
          y={239}
          width={66}
          height={68}
        >
          <path
            d="M267.24 239.904a15.804 15.804 0 0110.039 7.085c5.018 7.89 18.184 26.519 35.763 35.36 5.017 2.525 2.33 18.347-9.663 23.671-11.992 5.324-43.069-16.416-49.521-24.535-5.756-7.23-5.943-45.622 13.382-41.581z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#c)">
          <path
            d="M283.571 266.577h-1.244c0-11.827-4.085-22.936-6.801-30.286-1.825-4.946-2.609-7.18-2.074-8.272l1.112.565c-.336.656.908 4.019 2.11 7.275 2.766 7.429 6.913 18.663 6.897 30.718z"
            fill="#363D47"
          />
        </g>
        <mask
          id="d"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x={77}
          y={234}
          width={45}
          height={101}
        >
          <path
            d="M84.92 240.12a111.977 111.977 0 00-6.772 23.625c-1.394 8.376.12 51.079.074 54.467-.041 2.637.896 13.526 3.272 14.664 4.184 2.01 9.832 3.816 19.047-.872 1.592-.831 7.257-4.984 6.776-6.686-.888-3.156 5.27-57.391 13.468-72.74 7.73-14.468-26.191-25.788-35.866-12.458z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#d)">
          <path
            d="M115.362 283.151l-1.244-.121c3.272-33.222-2.023-43.018-2.073-43.113l1.082-.623c.228.398 5.557 10.095 2.235 43.857z"
            fill="#363D47"
          />
        </g>
        <mask
          id="e"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x={213}
          y={572}
          width={83}
          height={48}
        >
          <path
            d="M295.413 601.197s-23.01 13.364-34.373 12.936c-11.362-.428-23.981 10.349-45.88 2.757-1.875-.647-2.008-5.323-1.369-11.212.896-8.247 3.318-18.87 4.562-24.132a2.217 2.217 0 013.172-1.441c2.372 1.213 6.664 2.176 13.527-.324 11.648-4.24 14.203-7.279 14.203-7.279s9.733 10.676 18.209 11.507c7.646.768 27.896.32 28.48 12.712.053 1.51-.126 3.02-.531 4.476z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#e)">
          <path
            d="M295.413 601.197s-23.01 13.364-34.373 12.936c-11.362-.428-23.981 10.349-45.88 2.757-1.875-.647-2.008-5.323-1.369-11.212 2.169.83 14.186 5.033 25.404 2.732 12.386-2.545 23.185 1.632 33.033-1.092 6.955-1.927 17.417-6.777 23.716-10.585a14.756 14.756 0 01-.531 4.464zM276.525 589.989h-.204c-2.156-.112-6.062-.415-12.44-1.316-12.026-1.662-18.794-13.783-19.524-15.158l1.099-.586c.278.524 6.963 12.874 18.595 14.51 6.332.881 10.201 1.196 12.328 1.304a2.682 2.682 0 002.775-2.118 11.464 11.464 0 00-.83-7.213l1.124-.527a12.697 12.697 0 01.916 7.989 3.936 3.936 0 01-3.839 3.115zM224.813 590.417c-5.142 0-10.23-.719-11.889-3.323-3.67-5.714.958-11.793 1.003-11.852l.983.765c-.17.22-4.147 5.448-.941 10.415 2.567 3.995 16.774 2.691 22.861 1.74l-3.213-12.38 1.202-.315 3.558 13.704-.668.112a87.482 87.482 0 01-12.896 1.134z"
            fill="#363D47"
          />
          <path
            d="M251.792 590.421a3.963 3.963 0 01-3.879-4.742 3.965 3.965 0 013.112-3.114 3.957 3.957 0 014.731 3.89 3.971 3.971 0 01-3.964 3.966zm0-6.686a2.718 2.718 0 00-2.659 3.253 2.722 2.722 0 002.135 2.135 2.71 2.71 0 002.787-1.157 2.726 2.726 0 00-.341-3.435 2.717 2.717 0 00-1.922-.796z"
            fill="#363D47"
          />
          <path
            d="M253.114 586.455a1.321 1.321 0 01-2.62.258 1.326 1.326 0 011.298-1.583 1.324 1.324 0 011.322 1.325zM261.476 594.258a2.946 2.946 0 01-2.728-1.825 2.955 2.955 0 013.858-3.863 2.96 2.96 0 01.957 4.821 2.957 2.957 0 01-2.087.867zm0-4.668a1.707 1.707 0 00-1.578 1.057 1.705 1.705 0 00.37 1.864 1.708 1.708 0 102.415-2.418 1.71 1.71 0 00-1.207-.503zM271.449 595.778a2.952 2.952 0 01-2.728-1.825 2.958 2.958 0 012.152-4.032 2.957 2.957 0 013.031 1.257 2.965 2.965 0 01-.368 3.734 2.95 2.95 0 01-2.087.866zm0-4.668a1.709 1.709 0 00-1.208 2.921 1.705 1.705 0 002.628-.259 1.715 1.715 0 00-.213-2.16 1.716 1.716 0 00-1.207-.502z"
            fill="#363D47"
          />
          <path
            d="M252.024 586.991l-.917-.864a19.612 19.612 0 004.977-9.635l1.219.241a20.917 20.917 0 01-5.279 10.258zM262.229 591.359l-1.016-.714a17.458 17.458 0 003.135-10.016h1.244a18.7 18.7 0 01-3.363 10.73zM272.024 593.22l-1.094-.598a17.179 17.179 0 001.522-12.492l1.202-.319a18.454 18.454 0 01-1.63 13.409z"
            fill="#FAE6C9"
          />
          <path
            d="M231.851 607.331c-8.879 0-19.868-5.037-20.283-5.847l1.103-.569a.608.608 0 00-.112-.154c.909.727 13.046 6.229 21.585 5.191 8.663-1.067 23.16-2.857 30.898-1.981 7.112.806 26.502-6.607 31.516-13.052l.983.764c-4.923 6.342-23.281 13.588-31.421 13.588-.403.002-.806-.019-1.207-.062-7.593-.86-21.978.918-30.603 1.981-.816.096-1.638.143-2.459.141z"
            fill="#363D47"
          />
        </g>
        <path
          d="M238.053 176.932c-41.638-10.714-103.095-3.767-103.671-3.684l-.187-1.245c.564-.088 62.298-7.06 104.152 3.704l-.294 1.225zM172.459 294.729c-5.391-33.588 10.367-117.673 10.545-118.52l1.22.233c-.158.83-15.908 84.716-10.538 118.087l-1.227.2z"
          fill="#363D47"
        />
      </g>
      <defs>
        <clipPath id="clip0_29_11081">
          <path fill="#fff" d="M0 0H571V660H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
