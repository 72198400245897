import { IconProps } from './types'

const MobileCheck = ({ size = 20, ...restProps }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      d="M11.5 21H8a2 2 0 01-2-2V5a2 2 0 012-2h8a2 2 0 012 2v9.5M11 4h2m-1 13v.01M15 19l2 2 4-4"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export { MobileCheck }
