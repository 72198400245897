import React, { PropsWithChildren } from 'react'
import { cn } from 'utils-tailwindcss'

type PropType = PropsWithChildren<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>
export const DotButton: React.FC<PropType & { isActive: boolean }> = (
  props
) => {
  const { children, className, isActive, ...restProps } = props

  return (
    <button
      type="button"
      className={cn(
        'w-2 h-2 rounded-full',
        {
          'bg-white': isActive,
          'bg-white/40': !isActive,
        },
        className
      )}
      aria-label="carousel dot button"
      {...restProps}
    >
      {children}
    </button>
  )
}
