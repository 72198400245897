'use client'

import { ChevronLeft, ChevronRight } from 'lucide-react'
import { DayPicker } from 'react-day-picker'

import { cn } from 'utils-tailwindcss'
import { CalendarStyles } from './calendar.styles'
import { CalendarProps } from './types'

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  showBorder = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      captionLayout="dropdown-buttons"
      className={cn(`p-3 ${showBorder && 'border'} rounded-md`, className)}
      classNames={{ ...CalendarStyles, caption: 'flex', ...classNames }}
      components={{
        IconLeft: (props) => <ChevronLeft className="w-4 h-4" {...props} />,
        IconRight: (props) => <ChevronRight className="w-4 h-4" {...props} />,
      }}
      {...props}
    />
  )
}
Calendar.displayName = 'Calendar'

export { Calendar }
