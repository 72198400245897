import { SERVICE_TYPE } from 'libs'
import {
  BookOpen,
  HelpCircleIcon,
  HotelIcon,
  MessageSquareMore,
  PhoneIcon,
  PlaneIcon,
  StoreIcon,
} from 'lucide-react'
import { ElementType } from 'react'
import {
  BaggageIcon,
  CarRentalIcon,
  ConsulateIcon,
  FlightsIcon,
  MobileRechargeIcon,
  MoneyBagIcon,
  PackagesIcon,
  PassportIcon,
  RemittanceIcon,
} from '../icons'

export function SERVICE_ICONS(service: SERVICE_TYPE): ElementType {
  switch (service) {
    case SERVICE_TYPE.REMITTANCE.value:
      return RemittanceIcon
    case SERVICE_TYPE.TOPUP.value:
      return MobileRechargeIcon
    case SERVICE_TYPE.STORE.value:
      return StoreIcon
    case SERVICE_TYPE.CONSULAR_SERVICES.value:
      return ConsulateIcon
    case SERVICE_TYPE.CAR.value:
      return CarRentalIcon
    case SERVICE_TYPE.BALANCE.value:
      return MoneyBagIcon
    case SERVICE_TYPE.CALL.value:
      return PhoneIcon
    case SERVICE_TYPE.SMS.value:
      return MessageSquareMore
    case SERVICE_TYPE.SHOPPING.value:
      return StoreIcon
    case SERVICE_TYPE.LOCKER.value:
      return PackagesIcon
    case SERVICE_TYPE.HOTEL.value:
      return HotelIcon
    case SERVICE_TYPE.FLIGHTS.value:
      return PlaneIcon
    case SERVICE_TYPE.PASSPORT.value:
      return PassportIcon
    case SERVICE_TYPE.SHIPMENT.value:
      return FlightsIcon
    case SERVICE_TYPE.PACKAGES.value:
      return BaggageIcon
    case 'OTHER':
      return BookOpen // @note This icon is added for notifications inbox
    default:
      return HelpCircleIcon
  }
}
