import { cva } from 'class-variance-authority'

export const TabsStyles = {
  root: cva(
    'inline-flex h-10 items-center justify-center rounded-md p-1 text-grey'
  ),
  trigger: cva(
    'gap-2 inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-transparent data-[state=active]:text-primary data-[state=active]:font-bold'
  ),
  content: cva(
    'mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2'
  ),
}
