'use client'

import type { ComponentPropsWithoutRef, FC } from 'react'
import { cn } from 'utils-tailwindcss'
import { DotLottieReact } from '@lottiefiles/dotlottie-react'

type LoaderProps = ComponentPropsWithoutRef<'div'>

export const Loader: FC<LoaderProps> = ({ className, ...props }) => {
  return (
    <div
      {...props}
      className={cn('grid w-full min-h-[100dvh] place-items-center', className)}
    >
      <DotLottieReact
        src="/loader-balls.json"
        loop
        autoplay
        style={{ height: '250px', width: '250px' }}
      />
    </div>
  )
}
