import { IconProps } from './types'

const HotelIcon = ({ size = 20, ...rest }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...rest}
  >
    <path
      d="M6.24967 3.5C4.73978 3.5 3.49967 4.74011 3.49967 6.25V10.3418C2.62222 10.8032 1.99967 11.6968 1.99967 12.75V18.127C1.98644 18.2075 1.98644 18.2896 1.99967 18.3701V19.75C1.99826 19.8494 2.01662 19.9481 2.05368 20.0403C2.09074 20.1325 2.14576 20.2164 2.21555 20.2872C2.28533 20.358 2.36848 20.4142 2.46018 20.4526C2.55187 20.4909 2.65027 20.5107 2.74967 20.5107C2.84906 20.5107 2.94746 20.4909 3.03916 20.4526C3.13085 20.4142 3.214 20.358 3.28379 20.2872C3.35357 20.2164 3.40859 20.1325 3.44565 20.0403C3.48271 19.9481 3.50107 19.8494 3.49967 19.75V19H20.4997V19.75C20.4983 19.8494 20.5166 19.9481 20.5537 20.0403C20.5907 20.1325 20.6458 20.2164 20.7155 20.2872C20.7853 20.358 20.8685 20.4142 20.9602 20.4526C21.0519 20.4909 21.1503 20.5107 21.2497 20.5107C21.3491 20.5107 21.4475 20.4909 21.5392 20.4526C21.6308 20.4142 21.714 20.358 21.7838 20.2872C21.8536 20.2164 21.9086 20.1325 21.9457 20.0403C21.9827 19.9481 22.0011 19.8494 21.9997 19.75V18.373C22.0129 18.2925 22.0129 18.2104 21.9997 18.1299V12.75C21.9997 11.6968 21.3771 10.8032 20.4997 10.3418V6.25C20.4997 4.74011 19.2596 3.5 17.7497 3.5H6.24967ZM6.24967 5H17.7497C18.4488 5 18.9997 5.55089 18.9997 6.25V10H17.9997V9.75C17.9997 8.24011 16.7596 7 15.2497 7H13.9997C13.2072 7 12.5032 7.35263 11.9997 7.89551C11.4961 7.35263 10.7921 7 9.99967 7H8.74967C7.23978 7 5.99967 8.24011 5.99967 9.75V10H4.99967V6.25C4.99967 5.55089 5.55055 5 6.24967 5ZM8.74967 8.5H9.99967C10.6988 8.5 11.2497 9.05089 11.2497 9.75V10H7.49967V9.75C7.49967 9.05089 8.05055 8.5 8.74967 8.5ZM13.9997 8.5H15.2497C15.9488 8.5 16.4997 9.05089 16.4997 9.75V10H12.7497V9.75C12.7497 9.05089 13.3006 8.5 13.9997 8.5ZM4.74967 11.5H6.74967H17.2497H19.2497C19.9488 11.5 20.4997 12.0509 20.4997 12.75V17.5H3.49967V12.75C3.49967 12.0509 4.05055 11.5 4.74967 11.5Z"
      fill="currentColor"
    />
  </svg>
)

export { HotelIcon }
