'use client'

import { forwardRef } from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cn } from 'utils-tailwindcss'
import { ButtonStyles } from './button.styles'
import { ButtonProps } from './types'
import { Loader2Icon } from 'lucide-react'

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      disabled,
      variant,
      size,
      asChild = false,
      isLoading = false,
      isIcon = false,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(ButtonStyles({ variant, size }), className)}
        ref={ref}
        {...props}
        disabled={disabled || isLoading}
      >
        {isLoading ? (
          <>
            <Loader2Icon
              className={cn('w-4 h-4 flex-shrink-0 animate-spin', {
                'mr-1': !isIcon,
              })}
            />
            {!isIcon && props.children}
          </>
        ) : (
          props.children
        )}
      </Comp>
    )
  }
)
Button.displayName = 'Button'

export { Button, Slot }
