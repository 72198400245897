import { ForwardedRef, forwardRef } from 'react'
import { IconProps } from './types'

const RefundWithRef = (
  { size = 24, ...rest }: IconProps,
  ref: ForwardedRef<SVGSVGElement>
) => {
  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        fill="currentColor"
        d="M13.91 2.91L11.83 5H14a8 8 0 018 8h-2a6 6 0 00-6-6h-2.17l2.09 2.09-1.42 1.41L8 6l1.41-1.41L12.5 1.5l1.41 1.41zM2 12v10h16V12H2zm2 6.56v-3.11A4 4 0 005.45 14h9.1A4 4 0 0016 15.45v3.11A3.996 3.996 0 0014.57 20H5.45A3.996 3.996 0 004 18.56zm6 .44c.828 0 1.5-.895 1.5-2s-.672-2-1.5-2-1.5.895-1.5 2 .672 2 1.5 2z"
      />
    </svg>
  )
}

export const RefundIcon = forwardRef(RefundWithRef)
