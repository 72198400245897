import { Children, ReactNode, type FC, type PropsWithChildren } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from '../card'
import { cn } from 'utils-tailwindcss'

type BasicInfoContentProps = PropsWithChildren<{
  classNames?: string
  title?: string
}>

export const LeftBasicInfoContent: FC<BasicInfoContentProps> = ({
  children,
  classNames,
  title,
}) => {
  return (
    <div className={cn('flex flex-col gap-4', classNames)}>
      {!!title && <h5>{title}</h5>}
      {children}
    </div>
  )
}

export const RightBasicInfoContent: FC<BasicInfoContentProps> = ({
  children,
  classNames,
  title,
}) => {
  return (
    <div className={cn('flex flex-col gap-4', classNames)}>
      {!!title && <h5>{title}</h5>}
      {children}
    </div>
  )
}

export const CenterBasicInfoContent: FC<BasicInfoContentProps> = ({
  children,
  classNames,
  title,
}) => {
  return (
    <div className={cn('flex flex-col gap-4', classNames)}>
      {!!title && <h5>{title}</h5>}
      {children}
    </div>
  )
}

type BasicInfoProps = PropsWithChildren<{
  headerTitle?: ReactNode
  headerClassnames?: string
}>

export const BasicInfo: FC<BasicInfoProps> = ({
  children,
  headerTitle,
  headerClassnames = '',
}) => {
  const childrenLength = Children.toArray(children).length
  const gridColumns = `md:grid-cols-${childrenLength}`

  return (
    <Card>
      {Boolean(headerTitle) && (
        <CardHeader
          className={cn(
            'p-3 text-white bg-primary rounded-ss-lg rounded-se-lg',
            headerClassnames
          )}
        >
          <CardTitle className="text-base">{headerTitle}</CardTitle>
        </CardHeader>
      )}
      <CardContent>
        <div className={cn('flex flex-col md:grid gap-8', gridColumns)}>
          {children}
        </div>
      </CardContent>
    </Card>
  )
}
