import { isEmpty } from 'libs'
import type { FC, ReactNode } from 'react'
import { cn } from 'utils-tailwindcss'

type InfoBlockProps = {
  label: string
  content: ReactNode | null
  component?: keyof JSX.IntrinsicElements
  orientation?: 'vertical' | 'horizontal'
  className?: string
}

export const InfoBlock: FC<InfoBlockProps> = ({
  content,
  label,
  component = 'p',
  orientation = 'vertical',
  className,
}) => {
  const Slot = component

  return (
    <Slot
      className={cn(
        'flex flex-col md:items-center gap-2 text-sm',
        {
          'md:flex-row': orientation === 'horizontal',
        },
        className
      )}
    >
      <span className="font-bold uppercase">{label}:</span>{' '}
      {!isEmpty(content) ? content : 'N/A'}
    </Slot>
  )
}
