import { IconProps } from './types'

function BeneficiariesIcon({ size = 20, ...rest }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 256 256"
      {...rest}
    >
      <g fill="currentColor">
        <path
          d="M112 168a32 32 0 11-32-32 32 32 0 0132 32zM80 32a32 32 0 1032 32 32 32 0 00-32-32zm96 104a32 32 0 1032 32 32 32 0 00-32-32zm0-40a32 32 0 10-32-32 32 32 0 0032 32z"
          opacity={0.2}
        />
        <path d="M27.2 126.4a8 8 0 0011.2-1.6 52 52 0 0183.2 0 8 8 0 0011.2 1.59 7.73 7.73 0 001.59-1.59 52 52 0 0183.2 0 8 8 0 0012.8-9.61A67.85 67.85 0 00203 93.51a40 40 0 10-53.94 0 67.27 67.27 0 00-21 14.31 67.27 67.27 0 00-21-14.31 40 40 0 10-53.94 0A67.88 67.88 0 0025.6 115.2a8 8 0 001.6 11.2zM176 40a24 24 0 11-24 24 24 24 0 0124-24zm-96 0a24 24 0 11-24 24 24 24 0 0124-24zm123 157.51a40 40 0 10-53.94 0 67.27 67.27 0 00-21 14.31 67.27 67.27 0 00-21-14.31 40 40 0 10-53.94 0A67.88 67.88 0 0025.6 219.2a8 8 0 1012.8 9.6 52 52 0 0183.2 0 8 8 0 0011.2 1.59 7.73 7.73 0 001.59-1.59 52 52 0 0183.2 0 8 8 0 0012.8-9.61A67.85 67.85 0 00203 197.51zM80 144a24 24 0 11-24 24 24 24 0 0124-24zm96 0a24 24 0 11-24 24 24 24 0 0124-24z" />
      </g>
    </svg>
  )
}

export default BeneficiariesIcon
