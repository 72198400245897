'use client'

import { FC } from 'react'
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastPosition,
  ToastProvider,
  ToastTitle,
  ToastViewport,
  useToast,
} from '.'

export const Toaster: FC<ToastPosition> = ({ position }) => {
  const { toasts } = useToast()

  // TODO: Handle custom toast position and default position

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        return (
          <Toast key={id} {...props} className="grid grid-flow-row gap-4 my-1">
            <div className="grid gap-1">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
            </div>
            {action}
            <ToastClose />
          </Toast>
        )
      })}
      <ToastViewport position={position} />
    </ToastProvider>
  )
}
