import { cn } from 'utils-tailwindcss'

export const SkeletonStyles = 'animate-pulse rounded-md bg-muted bg-slate-300'

function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return <div className={cn(SkeletonStyles, className)} {...props} />
}

export { Skeleton }
