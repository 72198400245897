import { ForwardedRef, forwardRef } from 'react'
import { IconProps } from './types'

const WhatsappIconWithRef = (
  { size = 24, ...rest }: IconProps,
  ref: ForwardedRef<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      ref={ref}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        d="M20.925 6.358c-3.135-4.86-9.555-6.3-14.52-3.285C1.56 6.088 0 12.658 3.135 17.503l.255.39-1.05 3.93 3.93-1.05.39.255c1.695.915 3.54 1.44 5.37 1.44 1.965 0 3.93-.525 5.625-1.575 4.845-3.15 6.285-9.585 3.27-14.565v.03zm-2.745 10.11c-.525.78-1.185 1.305-2.1 1.44-.525 0-1.185.255-3.795-.78-2.22-1.05-4.065-2.76-5.37-4.725-.78-.915-1.185-2.1-1.305-3.285 0-1.05.39-1.965 1.05-2.625.255-.255.525-.39.78-.39h.66c.255 0 .525 0 .66.525.255.66.915 2.235.915 2.37.135.135.075 1.14-.525 1.71-.33.375-.39.39-.255.66.525.78 1.185 1.575 1.83 2.235.78.66 1.575 1.185 2.49 1.575.255.135.525.135.66-.135.135-.255.78-.915 1.05-1.185.255-.255.39-.255.66-.135l2.1 1.05c.255.135.525.255.66.39.135.39.135.915-.135 1.305h-.03z"
        fill="#363D47"
      />
    </svg>
  )
}

const OutlineWhatsappWithRef = (
  { size = 24, ...rest }: IconProps,
  ref: ForwardedRef<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      ref={ref}
      {...rest}
    >
      <path
        fill="currentColor"
        d="M12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01A9.816 9.816 0 0 0 12.04 2m.01 1.67c2.2 0 4.26.86 5.82 2.42a8.225 8.225 0 0 1 2.41 5.83c0 4.54-3.7 8.23-8.24 8.23c-1.48 0-2.93-.39-4.19-1.15l-.3-.17l-3.12.82l.83-3.04l-.2-.32a8.188 8.188 0 0 1-1.26-4.38c.01-4.54 3.7-8.24 8.25-8.24M8.53 7.33c-.16 0-.43.06-.66.31c-.22.25-.87.86-.87 2.07c0 1.22.89 2.39 1 2.56c.14.17 1.76 2.67 4.25 3.73c.59.27 1.05.42 1.41.53c.59.19 1.13.16 1.56.1c.48-.07 1.46-.6 1.67-1.18c.21-.58.21-1.07.15-1.18c-.07-.1-.23-.16-.48-.27c-.25-.14-1.47-.74-1.69-.82c-.23-.08-.37-.12-.56.12c-.16.25-.64.81-.78.97c-.15.17-.29.19-.53.07c-.26-.13-1.06-.39-2-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.12-.24-.01-.39.11-.5c.11-.11.27-.29.37-.44c.13-.14.17-.25.25-.41c.08-.17.04-.31-.02-.43c-.06-.11-.56-1.35-.77-1.84c-.2-.48-.4-.42-.56-.43c-.14 0-.3-.01-.47-.01"
      />
    </svg>
  )
}

export const FillWhatsappIcon = forwardRef(WhatsappIconWithRef)
export const OutlineWhatsappIcon = forwardRef(OutlineWhatsappWithRef)
