'use client'

import { ForwardedRef, InputHTMLAttributes, forwardRef } from 'react'
import PhoneInputLibTwo, { PhoneInputProps } from 'react-phone-input-2'
import es from 'react-phone-input-2/lang/es.json'
import { cn } from 'utils-tailwindcss'
import { InputStyles } from '../input/input.style'

// CSS
import 'react-phone-input-2/lib/style.css'

type Props = Omit<PhoneInputProps, 'inputProps' | 'localization'> & {
  inputProps?: InputHTMLAttributes<HTMLInputElement>
  name?: string
  locale?: 'es' | 'en'
}
const ForwardPhoneInput = (
  {
    inputProps,
    name,
    onChange,
    locale = 'en',
    showDropdown = false,
    disableDropdown = true,
    ...props
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  return (
    <PhoneInputLibTwo
      {...props}
      localization={locale === 'es' ? es : undefined}
      countryCodeEditable={false}
      showDropdown={showDropdown}
      buttonClass="!rounded-l-md"
      masks={{ cu: '........' }}
      onChange={(value, data, e, formatted) =>
        onChange?.(`+${value}`, data, e, formatted)
      }
      inputProps={
        {
          ...inputProps,
          name,
          className: cn(InputStyles(), 'pl-[50px]', inputProps?.['className']),
          ref: ref ?? undefined,
        } as unknown as InputHTMLAttributes<HTMLInputElement>
      }
      disableDropdown={disableDropdown}
    />
  )
}

export const PhoneInput = forwardRef<HTMLInputElement, Props>(ForwardPhoneInput)
