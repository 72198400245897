import { type VariantProps } from 'class-variance-authority'
import * as React from 'react'
import { cn } from 'utils-tailwindcss'
import { badgeVariants } from './badge.styles'

export type BadgeVariants = VariantProps<typeof badgeVariants>
export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    BadgeVariants {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <span className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, badgeVariants }
