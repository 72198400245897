import { ForwardedRef, forwardRef } from 'react'
import { IconProps } from './types'

const MoneyPlusWithRef = (
  { size = 20, ...rest }: IconProps,
  ref: ForwardedRef<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      ref={ref}
      height={size}
      viewBox="0 0 24 24"
      {...rest}
    >
      <g
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      >
        <path d="M3 10V8a2 2 0 0 1 2-2h2m-4 4c1.333 0 4-.8 4-4m-4 4v4m18-4V8a2 2 0 0 0-2-2h-2m4 4c-1.333 0-4-.8-4-4m4 4v2M7 6h10M3 14v2a2 2 0 0 0 2 2h2m-4-4c1.333 0 4 .8 4 4m0 0h4" />
        <circle cx="12" cy="12" r="2" />
        <path d="M18 15v3m0 3v-3m0 0h-3m3 0h3" />
      </g>
    </svg>
  )
}

export const MoneyPlus = forwardRef(MoneyPlusWithRef)
