import { ForwardedRef, InputHTMLAttributes, forwardRef } from 'react'
import { Input } from '../input'

const EmailInputWithRef = (
  { onChange, ...props }: InputHTMLAttributes<HTMLInputElement>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  return (
    <Input
      {...props}
      ref={ref}
      type="email"
      onChange={(e) => {
        if (e.target.value.split('@')[0].length > 64) {
          return
        }
        onChange?.(e)
      }}
    />
  )
}

export const EmailInput = forwardRef(EmailInputWithRef)
