import { cva } from 'class-variance-authority'

export const notificationBadgeVariants = cva(
  'absolute inline-flex items-center justify-center w-5 h-5 text-[0.6rem] font-bold border-2 rounded-full -top-2 -end-2',
  {
    variants: {
      variant: {
        default: 'border-white bg-primary text-primary-foreground',
        secondary: 'border-white bg-secondary text-white',
        destructive: 'border-white bg-destructive text-destructive-foreground',
        outline: 'text-foreground',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)
