import { cva } from 'class-variance-authority'

export const alertVariants = cva(
  'relative w-full rounded-lg border p-4 [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground',
  {
    variants: {
      variant: {
        default: 'bg-background text-foreground',

        warning:
          'border-orange-400/50 text-orange-400 dark:border-orange-400 [&>svg]:text-orange-400',
        neutral: 'border-grey/50 text-grey dark:border-grey [&>svg]:text-grey',
        destructive:
          'border-destructive/50 text-destructive dark:border-destructive [&>svg]:text-destructive',
        secondary:
          'border-secondary/50 text-secondary dark:border-secondary [&>svg]:text-secondary',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)
