'use client'

import { Children, Fragment, PropsWithChildren } from 'react'
import { cn } from 'utils-tailwindcss'

const Stepper = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <ol className={cn('flex items-center justify-center w-full', className)}>
      {Children.map(children, (child, index) => (
        <Fragment key={`step-child-${index}`}>{child}</Fragment>
      ))}
    </ol>
  )
}

export { Stepper }
