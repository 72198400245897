import { IconProps } from './types'

const ServicesIcon = ({ size = 20, ...rest }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...rest}
  >
    <path
      d="M19 14h2V7.523a1.947 1.947 0 00-.85-1.625l-5-3.575A1.936 1.936 0 0014 1.95c-.417 0-.8.125-1.15.375l-5 3.575A1.94 1.94 0 007 7.524V9h2V7.5L14 4l5 3.5V14zm-4-6a.48.48 0 01-.35-.15.48.48 0 010-.7.48.48 0 01.7 0 .48.48 0 010 .7A.48.48 0 0115 8zm-2 0a.48.48 0 01-.35-.15.48.48 0 010-.7.48.48 0 01.7 0 .48.48 0 010 .7A.48.48 0 0113 8zm2 2a.48.48 0 01-.35-.15.48.48 0 010-.7.48.48 0 01.7 0 .48.48 0 010 .7.48.48 0 01-.35.15zm-2 0a.48.48 0 01-.35-.15.48.48 0 010-.7.48.48 0 01.7 0 .48.48 0 010 .7.48.48 0 01-.35.15zm.95 10.4l5.95-1.85a1.143 1.143 0 00-.362-.388.93.93 0 00-.538-.163h-5.05c-.45 0-.808-.016-1.075-.05a3.952 3.952 0 01-.825-.2l-1.425-.475a.905.905 0 01-.575-.5A1.04 1.04 0 0110 16a.955.955 0 01.488-.587.97.97 0 01.762-.063l1.05.35c.283.084.604.15.962.2.359.05.846.084 1.463.1H15a.949.949 0 00-.162-.525.753.753 0 00-.388-.325L8.6 13H7v5.5l6.95 1.9zm-.525 1.95L7 20.55a1.93 1.93 0 01-.787 1.05c-.392.266-.796.4-1.213.4H3c-.55 0-1.02-.197-1.413-.588A1.926 1.926 0 011 19.999v-7c0-.55.196-1.02.587-1.412A1.926 1.926 0 013 10.999h5.6c.117 0 .233.013.35.038.117.025.225.054.325.087L15.15 13.3c.55.2.996.55 1.338 1.05.341.5.512 1.05.512 1.65h2c.833 0 1.542.275 2.125.825.583.55.875 1.275.875 2.175 0 .367-.096.654-.288.863-.191.208-.487.379-.887.512l-6.25 1.95c-.183.067-.375.1-.575.1-.2 0-.392-.025-.575-.075zM3 20h2v-7H3v7z"
      fill="#363D47"
    />
  </svg>
)

export { ServicesIcon }
