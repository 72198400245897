import { ForwardedRef, forwardRef } from 'react'
import { IconProps } from './types'

const RemittanceConfigIconWithRef = (
  { size, ...rest }: IconProps,
  ref: ForwardedRef<SVGSVGElement>
) => {
  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 14 14"
      {...rest}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M1 4.5A.5.5 0 0 1 .5 4V1A.5.5 0 0 1 1 .5h12a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5" />
        <rect width="7" height="8" x="3.5" y="3" rx=".5" />
        <circle cx="7" cy="7" r="1.5" />
        <path d="M3.5 13.5h7" />
      </g>
    </svg>
  )
}

export const RemittanceConfigIcon = forwardRef(RemittanceConfigIconWithRef)
