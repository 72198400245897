import { IconProps } from './types'

const RemittanceIcon = ({ size = 20, ...rest }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    {...rest}
  >
    <path
      d="M8.02665 2.5C7.03497 2.5 6.21415 3.29252 6.21415 4.25V6.94434L3.21118 8.66895L3.21017 8.66992C2.50558 9.07478 2.07129 9.80983 2.07129 10.6025V18.25C2.07129 20.0361 3.58743 21.5 5.43736 21.5H19.4195C21.2694 21.5 22.7856 20.0361 22.7856 18.25V10.6025C22.7856 9.80983 22.3513 9.07481 21.6467 8.66992L21.6457 8.66895L18.6427 6.94434V4.25C18.6427 3.29252 17.8219 2.5 16.8302 2.5H8.02665ZM9.32129 4H15.5356C15.5356 4.83 16.2295 5.5 17.0891 5.5V7.35156C17.0889 7.36263 17.0889 7.3737 17.0891 7.38477V11.7266L16.0514 12.2139C16.0293 10.4051 14.3703 9 12.4284 9C10.4866 9 8.82757 10.4051 8.80545 12.2139L7.76772 11.7266V7.4873C7.78142 7.40679 7.78142 7.32466 7.76772 7.24414V5.5C8.62736 5.5 9.32129 4.83 9.32129 4ZM12.4284 5C12.1537 5 11.8903 5.10536 11.6961 5.29289C11.5018 5.48043 11.3927 5.73478 11.3927 6C11.3927 6.26522 11.5018 6.51957 11.6961 6.70711C11.8903 6.89464 12.1537 7 12.4284 7C12.7031 7 12.9666 6.89464 13.1608 6.70711C13.355 6.51957 13.4641 6.26522 13.4641 6C13.4641 5.73478 13.355 5.48043 13.1608 5.29289C12.9666 5.10536 12.7031 5 12.4284 5ZM6.21415 8.69043V10.9971L4.00516 9.95898L6.21415 8.69043ZM18.6427 8.69043L20.8517 9.95898L18.6427 10.9971V8.69043ZM12.4284 10.5C13.62 10.5 14.4999 11.3154 14.4999 12.25C14.4999 12.542 14.4141 12.8106 14.2622 13.0547L12.4284 13.916L10.5947 13.0547C10.4427 12.8106 10.357 12.542 10.357 12.25C10.357 11.3154 11.2368 10.5 12.4284 10.5ZM3.62486 11.4492L6.54893 12.8223C6.61512 12.8671 6.68814 12.9017 6.76538 12.9248L12.0886 15.4248C12.1945 15.4745 12.3107 15.5004 12.4284 15.5004C12.5462 15.5004 12.6624 15.4745 12.7683 15.4248L18.0935 12.9229C18.1722 12.8998 18.2466 12.8649 18.314 12.8193L21.232 11.4492V18.25C21.232 19.2259 20.4302 20 19.4195 20H5.43736C4.42665 20 3.62486 19.2259 3.62486 18.25V11.4492Z"
      fill="currentColor"
    />
  </svg>
)

export { RemittanceIcon }
