import { IconProps } from './types'

function CubaMaxIcon({
  fill = '#F5F6F9',
  ...rest
}: IconProps & { fill?: string }) {
  return (
    <svg
      fill="none"
      viewBox="0 0 82 27"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        clipRule="evenodd"
        d="M10.8556 24.0215V25.9479C9.58961 26.6488 8.12828 26.9983 6.4716 26.9983C5.12514 26.9983 3.98324 26.7245 3.04674 26.1759C2.10774 25.6273 1.36659 24.872 0.819955 23.9084C0.273318 22.9456 0 21.9045 0 20.7836C0 19.0034 0.624608 17.5065 1.87634 16.2928C3.12723 15.0791 4.67156 14.4727 6.50598 14.4727C7.77112 14.4727 9.17796 14.8069 10.7265 15.478V17.361C9.31629 16.5437 7.93797 16.1354 6.59233 16.1354C5.21233 16.1354 4.0654 16.5769 3.15322 17.4614C2.24272 18.3459 1.78663 19.4533 1.78663 20.7836C1.78663 22.1257 2.23517 23.2288 3.13226 24.0921C4.02935 24.9562 5.17712 25.3883 6.57557 25.3883C8.03606 25.3883 9.46218 24.9324 10.8556 24.0215Z"
        fill={fill}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M27.874 21.5586H26.2402V25.4417H27.9606C28.9154 25.4417 29.5638 25.3668 29.9041 25.2186C30.2453 25.0687 30.5194 24.837 30.7257 24.521C30.9328 24.2058 31.0363 23.8668 31.0363 23.5048C31.0363 23.1301 30.9268 22.7894 30.7087 22.4793C30.4897 22.1693 30.1799 21.9376 29.7785 21.7869C29.3762 21.6344 28.7405 21.5586 27.874 21.5586ZM27.7425 16.3125H26.2402V19.9938H27.4013C28.334 19.9938 28.996 19.8158 29.3898 19.4597C29.7828 19.1028 29.9788 18.6412 29.9788 18.0748C29.9788 16.8994 29.2336 16.3125 27.7425 16.3125ZM28.6257 27.0012H24.4941V14.7539H27.6912C28.6987 14.7539 29.4846 14.8902 30.0498 15.161C30.6142 15.4328 31.0462 15.8075 31.3424 16.2837C31.6394 16.7598 31.7871 17.3117 31.7871 17.9361C31.7871 19.1396 31.1675 20.0024 29.9276 20.5228C30.8179 20.6906 31.5274 21.0594 32.0545 21.6258C32.5815 22.1931 32.8446 22.8711 32.8446 23.6598C32.8446 24.2859 32.6842 24.8446 32.3634 25.3352C32.0451 25.8258 31.5741 26.2262 30.9537 26.5362C30.3333 26.8454 29.5575 27.0012 28.6257 27.0012Z"
        fill={fill}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M40.9484 21.8396L38.9764 17.4297L37.1465 21.8396H40.9484ZM38.3763 14.7539H39.6073L45.092 27.0012H43.3004L41.6695 23.3917H36.4503L34.9224 27.0012H33.123L38.3763 14.7539Z"
        fill={fill}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M25.0245 6.32177C26.427 3.45003 30.8967 5.75436 30.8967 5.75436C30.8967 5.75436 28.8928 1.05269 24.6371 2.98748C25.2791 0.681459 27.1181 0.179158 27.1181 0.179158C26.685 0.0523146 22.9148 -0.938757 20.1564 3.5718C19.1853 1.35796 17.1272 0.640869 16.0385 0.585903L13.2513 0.443839C13.2513 0.443839 16.2602 1.28946 16.9631 3.06189C15.4168 2.84287 13.9052 4.08002 13.9052 4.08002C13.9052 4.08002 15.855 3.95656 16.6645 5.23092C9.57336 3.61916 9.74254 10.7097 9.74254 10.7097C9.74254 10.7097 11.2195 7.3602 14.2224 7.72974C11.0791 9.0117 12.891 13.6195 12.891 13.6195C12.891 13.6195 13.6548 9.05229 16.0842 9.67129C14.2638 12.7426 17.3167 16.339 17.3167 16.339C17.3167 16.339 17.0807 10.9025 18.9594 9.99855C20.6605 9.63662 20.9287 13.129 20.965 14.7011V21.603C20.965 22.5603 20.8897 23.2359 20.7392 23.6309C20.5878 24.0258 20.3416 24.3682 19.999 24.6574C19.3485 25.1978 18.5069 25.4667 17.4749 25.4667C16.7482 25.4667 16.118 25.3331 15.5851 25.0625C15.0514 24.7927 14.653 24.435 14.3916 23.9886C14.131 23.5412 14.0008 22.7463 14.0008 21.603V14.8245H12.2506V21.5861C12.2506 22.5662 12.3166 23.2858 12.446 23.7442C12.5771 24.2033 12.7421 24.5906 12.9426 24.9069C13.143 25.2232 13.3875 25.5081 13.6785 25.7635C14.6352 26.5872 15.9066 26.9998 17.4909 26.9998C19.0465 26.9998 20.3061 26.5855 21.2704 25.7551C21.5597 25.5048 21.8058 25.2232 22.0055 24.9069C22.206 24.5906 22.3743 24.194 22.5105 23.718C22.6467 23.2427 22.7143 22.5375 22.7143 21.603V14.7011C22.7727 11.1697 22.2762 9.75585 22.2762 9.75585C23.2176 9.47172 25.4305 10.9008 25.9837 13.4817C26.0861 13.2466 27.4945 10.5338 25.6614 8.91868C27.9665 8.91868 29.6261 10.7621 29.6261 10.7621C29.6261 10.7621 29.8105 7.02956 25.0245 6.32177Z"
        fill={fill}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M55.9361 14.7539L51.3642 20.2647L46.8 14.7963L46.765 14.7539H45.6504V27.0012H46.8281V16.5589L51.3599 22.0299L55.8773 16.5598V27.0012H57.0627V14.7539H55.9361Z"
        fill={fill}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M65.2439 21.8769H60.5586L62.8971 16.6562L65.2439 21.8769ZM63.2592 14.8215L63.2281 14.7539H62.5836L57.0605 27.0012H58.2638L60.1107 22.8768H65.696L67.5471 27.0012H68.7512L63.2592 14.8215Z"
        fill={fill}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M75.7694 20.7253L80.978 14.7539H79.4859L75.0105 19.8606L70.5283 14.7539H69.0345L74.2466 20.7321L68.752 27.0012H70.2466L75.0114 21.5646L79.7821 27.0012H81.2776L75.7694 20.7253Z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  )
}

export { CubaMaxIcon }
