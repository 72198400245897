import { IconProps } from './types'

const UserRemittance = ({ size = 20, ...rest }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...rest}
  >
    <path
      d="M16.5 7.5C16.5 8.69347 16.0259 9.83807 15.182 10.682C14.3381 11.5259 13.1935 12 12 12C10.8065 12 9.66193 11.5259 8.81802 10.682C7.97411 9.83807 7.5 8.69347 7.5 7.5C7.5 6.30653 7.97411 5.16193 8.81802 4.31802C9.66193 3.47411 10.8065 3 12 3C13.1935 3 14.3381 3.47411 15.182 4.31802C16.0259 5.16193 16.5 6.30653 16.5 7.5ZM12 10.5C12.7956 10.5 13.5587 10.1839 14.1213 9.62132C14.6839 9.05871 15 8.29565 15 7.5C15 6.70435 14.6839 5.94129 14.1213 5.37868C13.5587 4.81607 12.7956 4.5 12 4.5C11.2044 4.5 10.4413 4.81607 9.87868 5.37868C9.31607 5.94129 9 6.70435 9 7.5C9 8.29565 9.31607 9.05871 9.87868 9.62132C10.4413 10.1839 11.2044 10.5 12 10.5Z"
      fill="#F5F6F9"
    />
    <path
      d="M12.384 21C12.2119 20.5129 12.0967 20.0075 12.0405 19.494H4.5C4.5015 19.125 4.731 18.015 5.748 16.998C6.726 16.02 8.5665 15 12 15C12.39 15 12.7605 15.0135 13.11 15.0375C13.449 14.526 13.854 14.0625 14.316 13.6605C13.6155 13.557 12.846 13.5 12 13.5C4.5 13.5 3 18 3 19.5C3 21 4.5 21 4.5 21H12.384Z"
      fill="#F5F6F9"
    />
    <path
      d="M13.5 18.75C13.5 17.3576 14.0531 16.0223 15.0377 15.0377C16.0223 14.0531 17.3576 13.5 18.75 13.5C20.1424 13.5 21.4777 14.0531 22.4623 15.0377C23.4469 16.0223 24 17.3576 24 18.75C24 20.1424 23.4469 21.4777 22.4623 22.4623C21.4777 23.4469 20.1424 24 18.75 24C17.3576 24 16.0223 23.4469 15.0377 22.4623C14.0531 21.4777 13.5 20.1424 13.5 18.75ZM22.281 18.219L20.031 15.969C19.8902 15.8282 19.6992 15.7491 19.5 15.7491C19.3008 15.7491 19.1098 15.8282 18.969 15.969C18.8282 16.1098 18.7491 16.3008 18.7491 16.5C18.7491 16.6992 18.8282 16.8902 18.969 17.031L19.9395 18L15.75 18C15.5511 18 15.3603 18.079 15.2197 18.2197C15.079 18.3603 15 18.5511 15 18.75C15 18.9489 15.079 19.1397 15.2197 19.2803C15.3603 19.421 15.5511 19.5 15.75 19.5L19.9395 19.5L18.969 20.469C18.8282 20.6098 18.7491 20.8008 18.7491 21C18.7491 21.1992 18.8282 21.3902 18.969 21.531C19.1098 21.6718 19.3008 21.7509 19.5 21.7509C19.6992 21.7509 19.8902 21.6718 20.031 21.531L22.281 19.281C22.3508 19.2113 22.4063 19.1286 22.4441 19.0374C22.4819 18.9463 22.5013 18.8487 22.5013 18.75C22.5013 18.6513 22.4819 18.5537 22.4441 18.4625C22.4063 18.3714 22.3508 18.2887 22.281 18.219Z"
      fill="#F5F6F9"
    />
  </svg>
)

export { UserRemittance }
