import { ForwardedRef, forwardRef } from 'react'
import { IconProps } from './types'

const RelationOneToManyWithRef = (
  { size = 24, ...props }: IconProps,
  ref: ForwardedRef<SVGSVGElement>
) => {
  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M22 13v6h-1l-2-2h-8V9H7v2H5V9H2V7h3V5h2v2h6v8h6l2-2Z"
      />
    </svg>
  )
}

export const RelationOneToMany = forwardRef(RelationOneToManyWithRef)
