import { IconProps } from './types'

const PassportIcon = ({ size = 20, ...rest }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...rest}
  >
    <path
      d="M4 6v13a3 3 0 003 3h10a3 3 0 003-3V9a3 3 0 00-3-3H4zm0 0V5"
      stroke="#363D47"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      opacity={0.5}
      d="M12 17a3 3 0 100-6 3 3 0 000 6z"
      stroke="#363D47"
      strokeWidth={2}
    />
    <path
      d="M18 6v.75h.75V6H18zm-12.93.75H18v-1.5H5.071l-.001 1.5zM18.75 6V4.306h-1.5V6h1.5zm-3.139-4.416L4.814 3.126l.212 1.485 10.797-1.54-.212-1.486zM4.814 3.126A1.821 1.821 0 003.25 4.93h1.5a.32.32 0 01.276-.319l-.212-1.484zm13.936 1.18a2.75 2.75 0 00-3.139-2.722l.212 1.486a1.25 1.25 0 011.427 1.236h1.5zM5.071 5.25a.321.321 0 01-.321-.32h-1.5a1.815 1.815 0 001.124 1.682c.221.091.457.138.696.138l.001-1.5z"
      fill="#363D47"
    />
  </svg>
)

export { PassportIcon }
