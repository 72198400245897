import { ForwardedRef, forwardRef } from 'react'
import { Button, ButtonProps } from '../button'
import { cn } from 'utils-tailwindcss'

const RoundedButtonWithRef = (
  { className, ...rest }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  return (
    <Button
      ref={ref}
      className={cn(
        'w-10 h-10 p-0 text-black rounded-full shadow-md bg-[#FFF]',
        className
      )}
      variant="ghost"
      {...rest}
    />
  )
}

export const RoundedButton = forwardRef(RoundedButtonWithRef)
