import { IconProps } from './types'

const BaggageIcon = ({ size = 20, ...rest }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...rest}
  >
    <path
      d="M20.97 6.202l-8.25-4.514a1.489 1.489 0 00-1.44 0L3.03 6.204a1.5 1.5 0 00-.78 1.312v8.966a1.5 1.5 0 00.78 1.313l8.25 4.516a1.489 1.489 0 001.44 0l8.25-4.516a1.5 1.5 0 00.78-1.313V7.517a1.5 1.5 0 00-.78-1.315zM12 3l7.533 4.125-2.792 1.527-7.533-4.125L12 3zm0 8.25L4.467 7.125l3.18-1.74 7.532 4.124L12 11.25zm8.25 5.236l-7.5 4.105v-8.044l3-1.642v3.345a.75.75 0 101.5 0v-4.166l3-1.642V16.486z"
      fill="#363D47"
    />
  </svg>
)

export { BaggageIcon }
