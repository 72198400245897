import { ForwardedRef, forwardRef } from 'react'
import { IconProps } from './types'

const CategoriesIconWithRef = (
  { size = 24, ...restProps }: IconProps,
  ref: ForwardedRef<SVGSVGElement>
) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    {...restProps}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M14 4h6v6h-6zM4 14h6v6H4zm10 3a3 3 0 1 0 6 0a3 3 0 1 0-6 0M4 7a3 3 0 1 0 6 0a3 3 0 1 0-6 0"
    ></path>
  </svg>
)

export const CategoriesIcon = forwardRef(CategoriesIconWithRef)
