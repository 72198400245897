import { forwardRef } from 'react'
import { ButtonProps } from 'react-day-picker'
import { Button } from './button'
import { cn } from 'utils-tailwindcss'

export const IconLabelButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, ...restProps }, ref) => {
    return (
      <Button
        {...restProps}
        ref={ref}
        className={cn(
          'text-base font-normal text-black hover:bg-transparent group',
          className
        )}
        variant="ghost"
      >
        <div className="flex items-center gap-2">{children}</div>
      </Button>
    )
  }
)
