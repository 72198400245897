import { IconProps } from './types'

const LanguageIcon = ({ size = 20, ...rest }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...rest}
  >
    <path
      d="M12 22a9.671 9.671 0 01-3.875-.788 10.16 10.16 0 01-3.188-2.15 10.148 10.148 0 01-2.15-3.187A9.659 9.659 0 012 12c0-1.383.263-2.68.788-3.888a10.174 10.174 0 012.15-3.174 10.129 10.129 0 013.186-2.15A9.691 9.691 0 0112 2c1.383 0 2.68.263 3.888.788a10.174 10.174 0 013.174 2.15 10.164 10.164 0 012.15 3.175A9.667 9.667 0 0122 12a9.671 9.671 0 01-.788 3.875 10.16 10.16 0 01-2.15 3.188 10.186 10.186 0 01-3.175 2.15A9.633 9.633 0 0112 22zm0-2.05c.433-.6.808-1.225 1.125-1.875.317-.65.575-1.342.775-2.075h-3.8c.2.733.458 1.425.775 2.075.317.65.692 1.275 1.125 1.875zm-2.6-.4c-.3-.55-.563-1.121-.788-1.713A14.56 14.56 0 018.05 16H5.1a8.295 8.295 0 001.813 2.175A7.202 7.202 0 009.4 19.55zm5.2 0a7.2 7.2 0 002.488-1.375A8.28 8.28 0 0018.9 16h-2.95a14.76 14.76 0 01-.562 1.838 13.642 13.642 0 01-.788 1.712zM4.25 14h3.4a12.728 12.728 0 01-.15-2 13.018 13.018 0 01.15-2h-3.4a8.628 8.628 0 00-.188.988A7.784 7.784 0 004 12c0 .35.021.687.063 1.012.042.325.104.654.187.988zm5.4 0h4.7a12.728 12.728 0 00.15-2 13.033 13.033 0 00-.15-2h-4.7a12.728 12.728 0 00-.15 2 13.018 13.018 0 00.15 2zm6.7 0h3.4c.083-.333.146-.663.188-.988.042-.325.063-.663.062-1.012 0-.35-.021-.687-.063-1.012A8.815 8.815 0 0019.75 10h-3.4a12.728 12.728 0 01.15 2 13.033 13.033 0 01-.15 2zm-.4-6h2.95a8.302 8.302 0 00-1.812-2.175A7.189 7.189 0 0014.6 4.45c.3.55.563 1.121.788 1.713.225.592.413 1.204.562 1.837zM10.1 8h3.8c-.2-.733-.458-1.425-.775-2.075A12.701 12.701 0 0012 4.05c-.433.6-.808 1.225-1.125 1.875-.317.65-.575 1.342-.775 2.075zm-5 0h2.95c.15-.633.338-1.246.563-1.838A13.93 13.93 0 019.4 4.45a7.2 7.2 0 00-2.488 1.375A8.277 8.277 0 005.1 8z"
      fill="#363D47"
    />
  </svg>
)

export { LanguageIcon }
