import { ForwardedRef, forwardRef } from 'react'
import { IconProps } from './types'

const TableRefreshWithRef = (
  { size = 24, ...props }: IconProps,
  ref: ForwardedRef<SVGSVGElement>
) => {
  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M18 14.5c1.11 0 2.11.45 2.83 1.17L22 14.5v4h-4l1.77-1.77A2.5 2.5 0 1 0 20 20h1.71A3.991 3.991 0 0 1 18 22.5c-2.21 0-4-1.79-4-4s1.79-4 4-4M4 3h14a2 2 0 0 1 2 2v7.17c-.5-.11-1-.17-1.5-.17c-1.27 0-2.46.37-3.46 1H12v4h.18c-.12.5-.18 1-.18 1.5v.5H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2m0 4v4h6V7zm8 0v4h6V7zm-8 6v4h6v-4z"
      />
    </svg>
  )
}

export const TableRefresh = forwardRef(TableRefreshWithRef)
