'use client'

import { clearPhonePrefix } from 'libs'
import type {
  ChangeEventHandler,
  ForwardedRef,
  InputHTMLAttributes,
} from 'react'
import { forwardRef } from 'react'
import { cn } from 'utils-tailwindcss'
import { handleAcceptOnlyNumbers } from '../../libs'
import { CustomInputStyles } from '../input/input.style'
import { UsIcon } from '../icons'

type USAPhoneInputProps = InputHTMLAttributes<HTMLInputElement> & {
  containerClassName?: string
  className?: string
  value?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
}

export const maskPhone = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/^(?<temp2>\d{3})(?<temp1>\d)/g, '($1) $2')
    .replace(/(?<temp2>\d{3})(?<temp1>\d{4})$/, '$1-$2')
}

const USAPhoneInputWithRef = (
  {
    value,
    onChange,
    containerClassName: containerClassnames,
    className: inputClassnames,
    ...restOfProps
  }: USAPhoneInputProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  return (
    <div
      className={cn(
        CustomInputStyles(),
        'flex gap-2 items-center w-full  ',
        containerClassnames
      )}
    >
      <span className="flex items-center gap-2 text-sm ">
        <UsIcon />
        +1{' '}
      </span>
      <input
        {...restOfProps}
        maxLength={14}
        className={cn(
          'w-full font-normal border-none outline-none',
          inputClassnames
        )}
        onKeyDown={handleAcceptOnlyNumbers}
        onChange={onChange}
        ref={ref}
        value={maskPhone(clearPhonePrefix({ value, countryCode: 'us' }))}
      />
    </div>
  )
}

export const USAPhoneInput = forwardRef(USAPhoneInputWithRef)
