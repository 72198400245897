import { IconProps } from './types'

const MoneyBagIcon = ({ size = 20, ...rest }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    {...rest}
  >
    <path
      d="M12.38 14.5089H12.4325C13.5575 14.5389 14.4575 15.4539 14.4575 16.5789C14.4575 17.5389 13.805 18.3414 12.92 18.5814V19.4214C12.92 19.7214 12.68 19.9614 12.38 19.9614C12.08 19.9614 11.84 19.7214 11.84 19.4214V18.5814C11.3993 18.4629 11.0099 18.2026 10.732 17.8406C10.4541 17.4787 10.3032 17.0352 10.3025 16.5789C10.3025 16.2789 10.5425 16.0389 10.8425 16.0389C11.1425 16.0389 11.3825 16.2789 11.3825 16.5789C11.3825 17.1339 11.825 17.5764 12.3725 17.5764C12.92 17.5764 13.37 17.1264 13.37 16.5789C13.37 16.0314 12.92 15.5814 12.3725 15.5814H12.32C11.7805 15.5668 11.2679 15.3423 10.8912 14.9558C10.5144 14.5693 10.3033 14.0511 10.3025 13.5114C10.3025 12.5514 10.955 11.7489 11.84 11.5089V10.6689C11.84 10.3689 12.08 10.1289 12.38 10.1289C12.68 10.1289 12.92 10.3689 12.92 10.6689V11.5089C13.805 11.7489 14.4575 12.5514 14.4575 13.5114C14.4575 13.8114 14.2175 14.0514 13.9175 14.0514C13.6175 14.0514 13.3775 13.8114 13.3775 13.5114C13.3775 12.9639 12.9275 12.5139 12.38 12.5139C11.8325 12.5139 11.3825 12.9639 11.3825 13.5114C11.3825 14.0589 11.8325 14.5089 12.38 14.5089Z"
      fill="currentColor"
    />
    <path
      d="M8.399 3.82514L10.4885 6.27014L10.2628 6.52214C5.96225 7.48664 2.75 11.3274 2.75 15.9189C2.75 19.9554 6.026 23.2314 10.0625 23.2314H14.69C18.7265 23.2314 22.0025 19.9561 22.0025 15.9196C22.01 11.3274 18.7978 7.48664 14.4973 6.52214C14.5147 6.36365 14.4861 6.20349 14.4148 6.06089L16.1923 4.02614L16.307 3.84914C16.441 3.55689 16.4753 3.22871 16.4046 2.91507C16.3338 2.60144 16.1621 2.31972 15.9156 2.11324C15.6692 1.90677 15.3617 1.78697 15.0406 1.77227C14.7194 1.75757 14.4023 1.84879 14.138 2.03189L13.5058 1.28189C13.3639 1.12318 13.1902 0.996127 12.996 0.909026C12.8018 0.821924 12.5914 0.776723 12.3786 0.776369C12.1657 0.776015 11.9552 0.820516 11.7607 0.906972C11.5662 0.993428 11.3921 1.1199 11.2498 1.27814L10.5875 2.01764C10.3239 1.83073 10.0061 1.73587 9.68317 1.74775C9.36025 1.75963 9.05025 1.87757 8.8011 2.08334C8.55196 2.28912 8.37755 2.57125 8.30487 2.8861C8.23218 3.20095 8.26527 3.53098 8.399 3.82514ZM11.2483 4.86389L9.97625 3.39389C10.2718 3.53628 10.6061 3.57672 10.9271 3.50887C11.248 3.44101 11.5374 3.26871 11.75 3.01889L12.35 2.24039L13.016 3.02939C13.2237 3.27087 13.5038 3.4388 13.8146 3.50813C14.1255 3.57746 14.4504 3.54449 14.741 3.41414L13.475 4.86389H11.2483ZM4.25 15.9189C4.25 11.4279 7.889 7.78889 12.38 7.78889C16.871 7.78889 20.51 11.4279 20.5025 15.9174V15.9189C20.5019 17.4603 19.8893 18.9384 18.7994 20.0283C17.7095 21.1182 16.2314 21.7308 14.69 21.7314H10.0625C8.52111 21.7308 7.04303 21.1182 5.9531 20.0283C4.86317 18.9384 4.2506 17.4603 4.25 15.9189Z"
      fill="currentColor"
    />
  </svg>
)

export { MoneyBagIcon }
