import { ForwardedRef, forwardRef } from 'react'
import { IconProps } from './types'

const FlightColorSeatWithRef = (
  { size = 24, ...restProps }: IconProps,
  ref: ForwardedRef<SVGSVGElement>
) => {
  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      {...restProps}
    >
      <g fill="none">
        <path
          fill="#00A6ED"
          d="M14.992 19h9.796c.67 0 1.212.55 1.212 1.228v3.544A1.22 1.22 0 0 1 24.788 25l-5.663 1.36L13.221 25h-.704c-.572 0-1.075-.39-1.223-.958l-1.448-4.853L6.044 5.188a1.207 1.207 0 0 1 .876-1.49l2.477-.654a1.228 1.228 0 0 1 1.51.863l.136.499l1.653 2.57l-.157 2.94l2.417 8.9c.017.062.029.123.036.184"
        />
        <path
          fill="#000"
          d="m12.4 28l.253 1.05c.157.56.24.95.847.95H25c.5 0 1-.5 1-1v-1z"
        />
        <path
          fill="#B4ACBC"
          d="m13.979 8.801l-1.065-4.277c-.09-.38-.452-.604-.804-.497l-.234.068l-.849.24l1.52 5.622l.728-.21l.234-.069c.353-.107.56-.497.47-.877M21.5 17h-10c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h10c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5M26 28H12.406l-.362-1.38c-.215-.82.383-1.62 1.216-1.62h11.534c.667 0 1.206.55 1.206 1.23z"
        />
      </g>
    </svg>
  )
}

export const FlightSeatWithRef = (
  { size = 24, ...restProps }: IconProps,
  ref: ForwardedRef<SVGSVGElement>
) => {
  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      {...restProps}
    >
      <g fill="none">
        <g fill="currentColor" clip-path="url(#IconifyId1910e9cbef764d2cf3)">
          <path d="M13.178 27H25v1.78c0 .13-.105.22-.206.22H13.898a.265.265 0 0 1-.249-.204z" />
          <path d="m16.264 16l.271 1h8.253C26.587 17 28 18.469 28 20.228v3.544c0 .433-.086.848-.241 1.229c.155.381.241.797.241 1.229v2.55c0 1.748-1.409 3.22-3.206 3.22H13.898a3.264 3.264 0 0 1-3.15-2.442l-.638-2.43a3.303 3.303 0 0 1-.08-1.285a3.308 3.308 0 0 1-.658-1.247l-1.435-4.687a2.251 2.251 0 0 1-.089-.444L4.116 5.718c-.484-1.743.58-3.51 2.3-3.955l2.464-.651a3.243 3.243 0 0 1 3.188.894c1.348-.124 2.494.833 2.788 2.041l1.066 4.282l.003.01c.21.888-.011 1.763-.59 2.415a2.82 2.82 0 0 1-.396.367l.51 1.879H21.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5zm-5.289 7h.008l.31 1.039l.001.003a1.287 1.287 0 0 0 .308.56c.233.249.56.398.915.398h12.27A1.22 1.22 0 0 0 26 23.772v-3.544A1.22 1.22 0 0 0 24.788 19h-9.796a1.18 1.18 0 0 0-.036-.184L14.19 16H11.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h1.877l-.827-3.043h-.003L11.028 4.34l-.002.001l-.118-.434a1.231 1.231 0 0 0-1.51-.864L6.92 3.7a1.207 1.207 0 0 0-.876 1.489l3.802 14a.248.248 0 0 0 .003.135zm1.068 2.95a1.3 1.3 0 0 0 .001.67l.638 2.431c.148.56.647.949 1.216.949h10.896C25.46 30 26 29.45 26 28.78v-2.55c0-.187-.04-.364-.114-.523a2.189 2.189 0 0 1-1.098.293H12.517a2.25 2.25 0 0 1-.474-.05" />
        </g>
        <defs>
          <clipPath id="IconifyId1910e9cbef764d2cf3">
            <path fill="#fff" d="M0 0h32v32H0z" />
          </clipPath>
        </defs>
      </g>
    </svg>
  )
}

export const FlightColorSeat = forwardRef(FlightColorSeatWithRef)
export const FlightSeat = forwardRef(FlightSeatWithRef)
