import { cva } from 'class-variance-authority'

export const EllipseStepStyles = cva(
  'flex items-center text-white justify-center w-9 h-9 rounded-full md:h-10 md:w-10 shrink-0 outline outline-white -outline-offset-[6px]',
  {
    variants: {
      isActive: {
        true: 'bg-primary',
        false: 'bg-[#ADAFA6]',
      },
      isDone: {
        true: 'bg-primary',
        false: 'bg-[#ADAFA6]',
      },
    },
    defaultVariants: {
      isActive: false,
      isDone: false,
    },
  }
)

export const DividerBehaviorStyle = cva('transition-all', {
  variants: {
    isDone: {
      true: 'bg-primary',
      false: 'bg-[#ADAFA6]',
    },
    isLastStep: {
      true: 'animate-progress-up',
      false: 'w-full h-[6px] inline-block animate-progress-down',
    },
  },
  defaultVariants: {
    isDone: false,
    isLastStep: false,
  },
})
