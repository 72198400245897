import { IconProps } from './types'

const AmericanExpressIcon = ({ size = 20, ...rest }: IconProps) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 37.6 23.6"
    xmlSpace="preserve"
    {...rest}
  >
    <style>{'.st1{fill:#fff}'}</style>
    <path
      id="XMLID_41_"
      d="M35.6 0H2C.9 0 0 .9 0 2v19.6c0 1.1.9 2 2 2h33.5c1.1 0 2-.9 2-2V2c.1-1.1-.8-2-1.9-2z"
      fill="#2557d6"
    />
    <g id="XMLID_40_">
      <path id="XMLID_42_" className="st1" d="M2.7 6.6L2 8.3 3.4 8.3z" />
      <path
        id="XMLID_45_"
        className="st1"
        d="M32.9 5v.7l-.4-.7h-3v.7l-.4-.7H25c-.7 0-1.3.1-1.8.4V5h-2.8v.4c-.2-.4-.7-.4-1.1-.4H9l-.7 1.5L7.6 5H4.4v.7L4.1 5H1.3L0 7.9v2.3l1.9-4.4h1.6L5.3 10V5.8H7l1.4 3 1.3-3h1.8v4.4h-1.1V6.8l-1.5 3.4h-1L6.3 6.8v3.4H4.2l-.4-1H1.6l-.4 1H0v.9h1.8l.4-1h.9l.4 1H7v-.7l.3.7h1.8l.3-.8v.8h8.8V9.5h.2c.1 0 .2 0 .2.2v1.4h4.6v-.4c.4.2.9.4 1.7.4h1.9l.4-1h.9l.4 1h3.7v-.9l.6.9h3V5h-2.9zM15.7 6.7h-2.5v.8h2.4v.9h-2.4v.9h2.5v.9h-3.5V5.8h3.5v.9zm4.8 1.8c.1.2.2.4.2.8v.9h-1.1v-.6c0-.3 0-.6-.2-.9-.2-.2-.4-.2-.8-.2h-1.1v1.6h-1.1V5.8h2.4c.5 0 .9 0 1.3.2.3.2.5.5.5 1 0 .7-.5 1.1-.7 1.2.3 0 .5.2.6.3zm1.9 1.7h-1.1V5.8h1.1v4.4zm12.5 0h-1.5l-2-3.3v3.3h-2.1l-.4-1h-2.2l-.4 1H25c-.5 0-1.2-.1-1.5-.5-.4-.4-.6-.9-.6-1.7 0-.7.1-1.2.6-1.7.3-.4.9-.5 1.6-.5h1v.9h-1c-.4 0-.6.1-.8.3-.2.2-.3.5-.3 1s.1.8.3 1c.2.2.5.2.7.2h.5L27 5.8h1.6l1.8 4.2V5.8H32l1.9 3.1V5.8H35v4.4z"
      />
      <path
        id="XMLID_49_"
        className="st1"
        d="M19.3 6.8c-.1-.1-.3-.1-.5-.1h-1.3v1h1.3c.2 0 .4 0 .5-.1.1-.1.2-.2.2-.4.1-.2 0-.3-.2-.4z"
      />
      <path id="XMLID_50_" className="st1" d="M27 8.3L28.5 8.3 27.7 6.6z" />
    </g>
    <g id="XMLID_51_">
      <path
        id="XMLID_52_"
        className="st1"
        d="M16.3 17.4L16.3 13.9 14.6 15.6z"
      />
      <path
        id="XMLID_53_"
        className="st1"
        d="M18.7 14.3h-1.4v1.1h1.4c.4 0 .7-.2.7-.6-.1-.3-.4-.5-.7-.5z"
      />
      <path
        id="XMLID_54_"
        className="st1"
        d="M9.5 14.3L9.5 15.1 11.8 15.1 11.8 16 9.5 16 9.5 16.9 12.1 16.9 13.3 15.6 12.1 14.3z"
      />
      <path
        id="XMLID_55_"
        className="st1"
        d="M35.6 14.3c-.2 0-.3 0-.4.1-.1.1-.2.2-.2.3 0 .2.1.3.2.3s.2.1.4.1h.6c.6 0 1 .1 1.2.4l.1.1v-1.3h-1.9z"
      />
      <path
        id="XMLID_56_"
        className="st1"
        d="M23.8 14.4c-.1-.1-.3-.1-.5-.1H22v1h1.3c.2 0 .4 0 .5-.1.1-.1.2-.2.2-.4s0-.3-.2-.4z"
      />
      <path
        id="XMLID_59_"
        className="st1"
        d="M36.1 17.8h-2v-.9h2c.2 0 .3 0 .4-.1.1-.1.1-.2.1-.3 0-.1-.1-.2-.1-.3-.1-.1-.2-.1-.4-.1-1 0-2.2 0-2.2-1.4 0-.6.4-1.3 1.5-1.3h2.1v-.9h-2c-.6 0-1 .1-1.3.4v-.4h-2.9c-.5 0-1 .1-1.3.4v-.4h-5v.4c-.4-.3-1.1-.4-1.4-.4h-3.4v.4c-.3-.3-1-.4-1.5-.4h-3.8l-.9.9-.8-.9H7.5v6.1h5.6l.9-1 .8 1h3.4v-1.4h.3c.5 0 1 0 1.5-.2v1.7h2.8V17h.2c.2 0 .2 0 .2.2v1.4h8.6c.5 0 1.1-.1 1.4-.4v.4H36c.6 0 1.1-.1 1.5-.3v-1.1c-.2.4-.7.6-1.4.6zm-17.5-1.5h-1.3v1.5h-2.1L14 16.4l-1.4 1.5H8.4v-4.4h4.2l1.3 1.4 1.3-1.4h3.4c.8 0 1.8.2 1.8 1.4.1 1.2-.9 1.4-1.8 1.4zm6.4-.2c.1.2.2.4.2.8v.9h-1.1v-.6c0-.3 0-.7-.2-.9-.2-.2-.4-.2-.8-.2H22v1.6h-1.1v-4.4h2.4c.5 0 .9 0 1.3.2.3.2.5.5.5 1 0 .7-.5 1.1-.7 1.2.3.2.5.3.6.4zm4.3-1.8h-2.5v.8h2.4v.9h-2.4v.9h2.5v.9h-3.5v-4.4h3.5v.9zm2.7 3.5h-2v-.9h2c.2 0 .3 0 .4-.1.1-.1.1-.2.1-.3 0-.1-.1-.2-.1-.3-.1-.1-.2-.1-.4-.1-1 0-2.2 0-2.2-1.4 0-.6.4-1.3 1.5-1.3h2.1v.9h-1.9c-.2 0-.3 0-.4.1-.1.1-.1.2-.1.3 0 .2.1.3.2.3h1c.6 0 1 .1 1.2.4.2.2.3.5.3.9 0 1.1-.6 1.5-1.7 1.5z"
      />
    </g>
  </svg>
)

export { AmericanExpressIcon }
