'use client'

import { forwardRef, useState } from 'react'
import { cn } from 'utils-tailwindcss'
import { Button } from '../button'
import { Popover, PopoverContent, PopoverTrigger } from '../popover'
import { Check, ChevronDown } from 'lucide-react'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '../command'
import { ScrollArea } from '../scroll-area'

export interface ComboboxOption {
  value: string
  label: React.ReactNode
}

type ComboboxPropsSingle = {
  options: ComboboxOption[]
  emptyText?: string
  clearable?: boolean
  selectPlaceholder?: string
  searchPlaceholder?: string
  multiple?: false
  value?: string
  onValueChange?: (value: string) => void
}

type ComboboxPropsMultiple = {
  options: ComboboxOption[]
  emptyText?: string
  clearable?: boolean
  selectPlaceholder?: string
  searchPlaceholder?: string
  multipleValueSelectedText?: string
  multiple: true
  value?: string[]
  onValueChange?: (value: string[]) => void
}

export type ComboboxProps = ComboboxPropsSingle | ComboboxPropsMultiple

const handleSingleSelect = (
  props: ComboboxPropsSingle,
  option: ComboboxOption
) => {
  if (props.clearable) {
    props.onValueChange?.(option.value === props.value ? '' : option.value)
  } else {
    props.onValueChange?.(option.value)
  }
}

const handleMultipleSelect = (
  props: ComboboxPropsMultiple,
  option: ComboboxOption
) => {
  if (props.value?.includes(option.value)) {
    if (!props.clearable && props.value.length === 1) return false
    props.onValueChange?.(props.value.filter((value) => value !== option.value))
  } else {
    props.onValueChange?.([...(props.value ?? []), option.value])
  }
}

export const CustomSelectWithRef = (
  props: ComboboxProps,
  ref: React.ForwardedRef<HTMLInputElement>
) => {
  const [open, setOpen] = useState(false)
  const [contentWidth, setContentWidth] = useState(0)

  return (
    <Popover onOpenChange={setOpen} open={open}>
      <PopoverTrigger asChild>
        <Button
          aria-expanded={open}
          ref={(element) => {
            element && setContentWidth(element.getBoundingClientRect().width)
          }}
          className="justify-between w-full hover:bg-grey/20 active:scale-100"
          role="combobox"
          variant="outline"
        >
          <span className="font-normal text-left line-clamp-1">
            {props.multiple && props.value && props.value.length < 3 && (
              <span className="mr-2">{props.value.join(', ')}</span>
            )}

            {props.multiple && props.value && props.value.length >= 3 && (
              <span className="mr-2">
                {props.value.length}{' '}
                {props.multipleValueSelectedText ?? 'options selected'}
              </span>
            )}

            {!props.multiple &&
              props.value &&
              props.value !== '' &&
              props.options.find((option) => option.value === props.value)
                ?.label}

            {!props.value ||
              (props.value.length === 0 &&
                (props.selectPlaceholder ?? 'Select an option'))}
          </span>
          <ChevronDown
            className={cn(
              'ml-2 h-4 w-4 shrink-0 rotate-0 opacity-50 transition-transform',
              open && 'rotate-180'
            )}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="p-0"
        style={{
          width: contentWidth,
        }}
      >
        <Command>
          <CommandInput
            placeholder={props.searchPlaceholder ?? 'Search for an option'}
            ref={ref}
          />
          <CommandEmpty>{props.emptyText ?? 'No results found'}</CommandEmpty>
          <CommandGroup>
            <ScrollArea>
              <div className="max-h-60">
                {props.options.map((option) => (
                  <CommandItem
                    key={option.value}
                    onSelect={(selectedValue) => {
                      const currentOption = props.options.find(
                        (_option) =>
                          _option.value.toLowerCase().trim() === selectedValue
                      )

                      if (!currentOption) {
                        return null
                      }

                      if (props.multiple) {
                        handleMultipleSelect(props, currentOption)
                      } else {
                        handleSingleSelect(props, currentOption)

                        setOpen(false)
                      }
                    }}
                    value={option.value.toLowerCase().trim()}
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4 opacity-0',
                        !props.multiple && props.value === option.value
                          ? 'opacity-100'
                          : 'opacity-0',
                        props.multiple && props.value?.includes(option.value)
                          ? 'opacity-100'
                          : 'opacity-0'
                      )}
                    />
                    {option.label}
                  </CommandItem>
                ))}
              </div>
            </ScrollArea>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export const CustomSelect = forwardRef(CustomSelectWithRef)
