import { FC } from 'react'

type ErrorDescriptionProps = {
  errors: string[]
}

export const ErrorDescription: FC<ErrorDescriptionProps> = ({ errors }) => {
  return (
    <ul>
      {errors.map((error, index) => (
        <li key={index} className="list-disc">
          {error}
        </li>
      ))}
    </ul>
  )
}
