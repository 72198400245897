'use client'

import { PopoverAnchor } from '@radix-ui/react-popover'
import { Pipette } from 'lucide-react'
import {
  forwardRef,
  memo,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { HexAlphaColorPicker } from 'react-colorful'
import { EyeDropper } from 'react-eyedrop'
import { cn } from 'utils-tailwindcss'
import { InputProps } from '../input/input'
import { CustomInputStyles } from '../input/input.style'
import { Popover, PopoverContent, PopoverTrigger } from '../popover'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../tooltip'

export function isWhiteColor(hex: string): boolean {
  const whiteHexPattern =
    /^#?(?:FFF{1,2}|FFFFFF|FDFDFD|FCFCFC|FAFAFA|F5F5F5|F0F0F0|EDEDED|E8E8E8)(?:[0-9A-Fa-f]{2})?$/i

  return whiteHexPattern.test(hex)
}

export type InputColorPickerProps = Omit<InputProps, 'onChange' | 'value'> & {
  onChange?: (value: string) => void
  value?: string
  containerIdToDisablePointerEvents?: string
  children?: ReactNode
  showFallbackEyeDropper?: boolean
}

export const setPointerEventTo = ({
  value,
  containerId,
}: {
  containerId?: string
  value: 'none' | 'auto'
}) => {
  if (!containerId) return

  const buttonsQuerySelector = `#${containerId} button`
  const inputQuerySelector = `#${containerId} input`

  const elements = (
    [
      Array.from(document.querySelectorAll(buttonsQuerySelector)),
      Array.from(document.querySelectorAll(inputQuerySelector)),
    ] as HTMLElement[][]
  ).flat()

  elements.forEach((el) => {
    el.style.pointerEvents = value
  })
}

const MemoInputColorPicker = forwardRef<
  HTMLInputElement,
  InputColorPickerProps
>(
  (
    {
      disabled,
      value,
      className,
      onChange,
      children,
      showFallbackEyeDropper,
      containerIdToDisablePointerEvents,
      ...props
    },
    forwardedRef
  ) => {
    const [open, setOpen] = useState(false)
    const parsedValue = useMemo(() => value, [value])
    const color = useMemo(() => parsedValue || '#000', [parsedValue])

    const handleExtractColor = useCallback(() => {
      setPointerEventTo({
        value: 'none',
        containerId: containerIdToDisablePointerEvents,
      })
    }, [])

    const handlePickEnd = useCallback(() => {
      setPointerEventTo({
        value: 'auto',
        containerId: containerIdToDisablePointerEvents,
      })
    }, [])

    return (
      <div className={cn(CustomInputStyles(), 'flex items-center', className)}>
        <Popover onOpenChange={setOpen} open={open}>
          <PopoverTrigger disabled={disabled} asChild>
            <input
              {...props}
              ref={(el) => {
                if (el && open) {
                  el.focus()
                  forwardedRef &&
                    (forwardedRef as (el: HTMLElement) => void)(el)
                }
              }}
              autoComplete="off"
              autoCapitalize="on"
              className="w-full border-none outline-none"
              maxLength={9}
              value={parsedValue}
              type="text"
              placeholder="#000000"
              onChange={(e) => {
                if (!open) {
                  setOpen(true)
                }
                const color = e.target.value

                if (
                  !!color &&
                  (!color.startsWith('#') ||
                    color.split('').filter((c) => c === '#').length > 1)
                )
                  return
                onChange?.(color)
              }}
            />
          </PopoverTrigger>
          <PopoverContent
            sideOffset={15}
            alignOffset={-15}
            align="start"
            className="w-full"
          >
            <HexAlphaColorPicker color={color} onChange={onChange} />
          </PopoverContent>
        </Popover>
        <div className="flex items-center gap-2 shrink-0">
          <TooltipProvider delayDuration={50}>
            <Tooltip>
              <TooltipTrigger asChild>
                <button
                  type="button"
                  disabled={disabled}
                  onClick={() => {
                    setOpen(true)
                  }}
                  className="border rounded-full shadow shrink-0 size-6 aspect-square"
                  style={{
                    backgroundColor: color,
                  }}
                />
              </TooltipTrigger>
              <TooltipContent className="bg-[#353c46] text-white">
                <p>Abrir selector de color</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          {showFallbackEyeDropper ? (
            <EyeDropper
              cursorActive="crosshair"
              onPickStart={handleExtractColor}
              onExtractColor={handleExtractColor}
              onPickEnd={handlePickEnd}
              wrapperClasses="border rounded-full shrink-0 size-6 aspect-square"
              customComponent={({ onClick }) => {
                return (
                  <TooltipProvider delayDuration={50}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <button
                          className="flex items-center justify-center size-full"
                          onClick={onClick}
                          type="button"
                        >
                          <Pipette className="size-4" />
                        </button>
                      </TooltipTrigger>
                      <TooltipContent className="bg-[#353c46] text-white">
                        <p>Gotero</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                )
              }}
              onChange={(value) => onChange?.(value.hex)}
            />
          ) : (
            <>{children}</>
          )}
        </div>
      </div>
    )
  }
)

MemoInputColorPicker.displayName = 'InputColorPicker'

export const InputColorPicker = memo(MemoInputColorPicker)

export type MemoTextInputColorPickerProps = Omit<InputProps, 'onChange'> & {
  onChange?: (value: string) => void
  onChangeColor?: (value: string) => void
  textColor?: string
  containerIdToDisablePointerEvents?: string
  children?: ReactNode
  showFallbackEyeDropper?: boolean
}

const MemoTextInputColorPicker = forwardRef<
  HTMLInputElement,
  MemoTextInputColorPickerProps
>(
  (
    {
      disabled,
      className,
      onChange,
      onChangeColor,
      children,
      textColor,
      showFallbackEyeDropper,
      containerIdToDisablePointerEvents,
      ...props
    },
    forwardedRef
  ) => {
    const [open, setOpen] = useState(false)
    const color = useMemo(() => textColor || '#000', [textColor])

    const handleExtractColor = useCallback(() => {
      setPointerEventTo({
        value: 'none',
        containerId: containerIdToDisablePointerEvents,
      })
    }, [])

    const handlePickEnd = useCallback(() => {
      setPointerEventTo({
        value: 'auto',
        containerId: containerIdToDisablePointerEvents,
      })
    }, [])

    return (
      <div className={cn(CustomInputStyles(), 'flex items-center', className)}>
        <Popover open={open}>
          <input
            type="text"
            style={{
              color: color,
              backgroundColor: isWhiteColor(color) ? '#CCC' : undefined,
            }}
            {...props}
            autoComplete="off"
            autoCapitalize="on"
            className="w-full border-none outline-none"
            ref={forwardedRef}
            onChange={(e) => {
              onChange?.(e.target.value)
            }}
          />
          <div className="flex items-center gap-2 shrink-0">
            <PopoverAnchor>
              <TooltipProvider delayDuration={50}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <button
                      type="button"
                      disabled={disabled}
                      onClick={() => {
                        setOpen(!open)
                      }}
                      className="border rounded-full shadow shrink-0 size-6 aspect-square"
                      style={{
                        backgroundColor: color,
                      }}
                    />
                  </TooltipTrigger>
                  <TooltipContent className="bg-[#353c46] text-white">
                    <p>Abrir selector de color</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </PopoverAnchor>

            {showFallbackEyeDropper ? (
              <EyeDropper
                cursorActive="crosshair"
                onPickStart={handleExtractColor}
                onExtractColor={handleExtractColor}
                onPickEnd={handlePickEnd}
                wrapperClasses="border rounded-full shrink-0 size-6 aspect-square"
                customComponent={({ onClick }) => {
                  return (
                    <TooltipProvider delayDuration={50}>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <button
                            className="flex items-center justify-center size-full"
                            onClick={onClick}
                            type="button"
                          >
                            <Pipette className="size-4" />
                          </button>
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#353c46] text-white">
                          <p>Gotero</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )
                }}
                onChange={(value) => onChangeColor?.(value.hex)}
              />
            ) : (
              <>{children}</>
            )}
          </div>
          <PopoverContent
            onInteractOutside={() => setOpen(false)}
            sideOffset={15}
            alignOffset={-15}
            align="start"
            className="w-full"
          >
            <HexAlphaColorPicker color={color} onChange={onChangeColor} />
          </PopoverContent>
        </Popover>
      </div>
    )
  }
)

MemoTextInputColorPicker.displayName = 'TextInputColorPicker'

export const TextInputColorPicker = memo(MemoTextInputColorPicker)
