import { IconProps } from './types'

const PrivacyIcon = ({ size = 20, ...rest }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...rest}
  >
    <path
      d="M12.0244 1.00037C11.831 0.99433 11.6428 1.06326 11.499 1.19275C11.499 1.19275 7.74508 4.50037 3.25 4.50037C3.05109 4.50039 2.86034 4.57941 2.71969 4.72006C2.57904 4.8607 2.50002 5.05146 2.5 5.25037V11.3802C2.5 14.7193 4.04471 20.2136 11.709 23.4418C11.8011 23.4806 11.9 23.5005 12 23.5005C12.1 23.5005 12.1989 23.4806 12.291 23.4418C19.9553 20.2136 21.5 14.7193 21.5 11.3802V5.25037C21.5 5.05146 21.421 4.8607 21.2803 4.72006C21.1397 4.57941 20.9489 4.50039 20.75 4.50037C16.2549 4.50037 12.501 1.19275 12.501 1.19275C12.3697 1.0745 12.201 1.00637 12.0244 1.00037ZM12 2.71423C13.0315 3.54355 16.0556 5.66896 20 5.93787V11.3802C20 14.2514 18.8967 18.8832 12 21.9135C5.10328 18.8832 4 14.2514 4 11.3802V5.93787C7.94437 5.66896 10.9685 3.54355 12 2.71423ZM12 6.00037C10.3521 6.00037 9 7.35243 9 9.00037V10.14C8.13178 10.4529 7.5 11.2803 7.5 12.2504V14.7504C7.5 15.9841 8.51625 17.0004 9.75 17.0004H14.25C15.4838 17.0004 16.5 15.9841 16.5 14.7504V12.2504C16.5 11.2803 15.8682 10.4529 15 10.14V9.00037C15 7.35243 13.6479 6.00037 12 6.00037ZM12 7.50037C12.8371 7.50037 13.5 8.1633 13.5 9.00037V10.0004H10.5V9.00037C10.5 8.1633 11.1629 7.50037 12 7.50037ZM9.75 11.5004H14.25C14.6732 11.5004 15 11.8271 15 12.2504V14.7504C15 15.1736 14.6732 15.5004 14.25 15.5004H9.75C9.32675 15.5004 9 15.1736 9 14.7504V12.2504C9 11.8271 9.32675 11.5004 9.75 11.5004ZM12 12.5004C11.7348 12.5004 11.4804 12.6057 11.2929 12.7933C11.1054 12.9808 11 13.2351 11 13.5004C11 13.7656 11.1054 14.0199 11.2929 14.2075C11.4804 14.395 11.7348 14.5004 12 14.5004C12.2652 14.5004 12.5196 14.395 12.7071 14.2075C12.8946 14.0199 13 13.7656 13 13.5004C13 13.2351 12.8946 12.9808 12.7071 12.7933C12.5196 12.6057 12.2652 12.5004 12 12.5004Z"
      fill="currentColor"
    />
  </svg>
)

export { PrivacyIcon }
