'use client'

import * as PopoverPrimitive from '@radix-ui/react-popover'
import { PopoverStyles } from './popover.styles'
import { cn } from 'utils-tailwindcss'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'

const Popover = PopoverPrimitive.Root

const PopoverTrigger = PopoverPrimitive.Trigger
const PopoverAnchor = PopoverPrimitive.Anchor

const PopoverContent = forwardRef<
  ElementRef<typeof PopoverPrimitive.Content>,
  ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {
    portal?: boolean
  }
>(
  (
    { className, align = 'center', sideOffset = 4, portal = true, ...props },
    ref
  ) =>
    portal ? (
      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
          ref={ref}
          align={align}
          sideOffset={sideOffset}
          className={cn(PopoverStyles(), className)}
          {...props}
        />
      </PopoverPrimitive.Portal>
    ) : (
      <PopoverPrimitive.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={cn(PopoverStyles(), className)}
        {...props}
      />
    )
)
PopoverContent.displayName = PopoverPrimitive.Content.displayName

export { Popover, PopoverTrigger, PopoverContent, PopoverAnchor }
