'use client'

import { AlertCircle, X as CloseIcon } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { Alert, AlertDescription, AlertTitle } from './alert'
import { AlertDestructiveProps } from './types'

export const AlertDestructive: FC<AlertDestructiveProps> = ({
  errors,
  onClose,
  translate,
}) => {
  const isOpen = !!errors.length
  const [openAlert, setOpenAlert] = useState(isOpen)

  useEffect(() => {
    setOpenAlert(isOpen)
  }, [isOpen])

  const handleCloseAlert = () => {
    setOpenAlert(false)
    onClose && onClose()
  }

  if (!openAlert) {
    return null
  }

  return (
    <Alert variant="destructive">
      <AlertCircle className="w-4 h-4" />
      <AlertTitle>Error</AlertTitle>
      <div className="absolute top-[5px] w-max right-2">
        <button
          className="p-1 rounded-full hover:bg-red-200"
          onClick={handleCloseAlert}
        >
          <CloseIcon className="w-5 h-5 " />
        </button>
      </div>
      {errors.map((error, index) => (
        <AlertDescription key={error + index}>
          {translate ? translate(error) : error}
        </AlertDescription>
      ))}
    </Alert>
  )
}
