import { IconProps } from './types'

const CartIcon = ({
  size = 20,
  fill = '#363D47',
  color,
  ...rest
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...rest}
  >
    <path
      d="M1.74994 3.00008C1.65056 2.99867 1.55188 3.01703 1.45966 3.05409C1.36743 3.09115 1.28349 3.14617 1.21271 3.21596C1.14194 3.28574 1.08573 3.36889 1.04737 3.46059C1.00901 3.55228 0.989258 3.65068 0.989258 3.75008C0.989258 3.84947 1.00901 3.94787 1.04737 4.03956C1.08573 4.13126 1.14194 4.21441 1.21271 4.2842C1.28349 4.35398 1.36743 4.409 1.45966 4.44606C1.55188 4.48312 1.65056 4.50148 1.74994 4.50008H3.12787C3.49183 4.50008 3.79523 4.75154 3.8642 5.10945L4.01361 5.8907L5.62591 14.3585C5.91747 15.8862 7.2635 17.0001 8.81927 17.0001H18.1806C19.7364 17.0001 21.083 15.8867 21.374 14.3585L22.9863 5.8907C23.0069 5.78228 23.0034 5.67064 22.976 5.56373C22.9486 5.45682 22.8979 5.35728 22.8276 5.27221C22.7572 5.18715 22.669 5.11865 22.5691 5.07161C22.4693 5.02457 22.3603 5.00014 22.2499 5.00008H5.37005L5.33783 4.82918C5.13627 3.77284 4.20342 3.00008 3.12787 3.00008H1.74994ZM5.65619 6.50008H21.3437L19.9003 14.0782C19.7423 14.908 19.0258 15.5001 18.1806 15.5001H8.81927C7.97434 15.5001 7.25833 14.908 7.09955 14.0782V14.0772L5.65619 6.50008ZM9.99994 18.0001C9.60211 18.0001 9.22058 18.1581 8.93928 18.4394C8.65797 18.7207 8.49994 19.1023 8.49994 19.5001C8.49994 19.8979 8.65797 20.2794 8.93928 20.5607C9.22058 20.842 9.60211 21.0001 9.99994 21.0001C10.3978 21.0001 10.7793 20.842 11.0606 20.5607C11.3419 20.2794 11.4999 19.8979 11.4999 19.5001C11.4999 19.1023 11.3419 18.7207 11.0606 18.4394C10.7793 18.1581 10.3978 18.0001 9.99994 18.0001ZM16.9999 18.0001C16.6021 18.0001 16.2206 18.1581 15.9393 18.4394C15.658 18.7207 15.4999 19.1023 15.4999 19.5001C15.4999 19.8979 15.658 20.2794 15.9393 20.5607C16.2206 20.842 16.6021 21.0001 16.9999 21.0001C17.3978 21.0001 17.7793 20.842 18.0606 20.5607C18.3419 20.2794 18.4999 19.8979 18.4999 19.5001C18.4999 19.1023 18.3419 18.7207 18.0606 18.4394C17.7793 18.1581 17.3978 18.0001 16.9999 18.0001Z"
      fill="currentColor"
    />
  </svg>
)

export { CartIcon }
