'use client'

import * as LabelPrimitive from '@radix-ui/react-label'
import { type VariantProps } from 'class-variance-authority'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { cn } from 'utils-tailwindcss'
import { LabelStyles } from './label.styles'

const Label = forwardRef<
  ElementRef<typeof LabelPrimitive.Root>,
  ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof LabelStyles> & {
      required?: boolean
    }
>(({ className, required, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    aria-required={required}
    className={cn(
      LabelStyles(),
      {
        "aria-required:after:content-['*'] after:ml-0.5 after:text aria-required:after:text-red-500":
          required,
      },
      className
    )}
    {...props}
  />
))
Label.displayName = LabelPrimitive.Root.displayName

export { Label }
