import * as React from 'react'
import { IconProps } from './types'

export const NotFoundIcon = (props: IconProps) => {
  return (
    <svg
      xmlSpace="preserve"
      width={400}
      height={400}
      viewBox="0 0 666.66669 666.66669"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse" id="a">
          <path d="M0 500h500V0H0z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="b">
          <path d="M0 500h500V0H0z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="c">
          <path d="M0 500h500V0H0z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="d">
          <path d="M0 500h500V0H0z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="e">
          <path d="M416.413 157.94h43.489V95.535h-43.489z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="f">
          <path d="M0 500h500V0H0z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="g">
          <path d="M148.376 69.538h19.95v-25.35h-19.95z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="h">
          <path d="M156.141 56.744h17.68v-18.09h-17.68z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="i">
          <path d="M171.441 46.854h2.38v-8.19h-2.38z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="j">
          <path d="M171.441 46.854h2.38v-8.19h-2.38z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="k">
          <path d="M166.331 46.883h3.99v-5.248h-3.99z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="l">
          <path d="M166.331 46.883h3.99v-5.248h-3.99z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="m">
          <path d="M156.135 55.914h16.25V38.488h-16.25z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="n">
          <path d="M166.892 62.95h17.68V44.86h-17.68z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="o">
          <path d="M182.192 53.06h2.38v-8.19h-2.38z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="p">
          <path d="M182.192 53.06h2.38v-8.19h-2.38z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="q">
          <path d="M177.082 53.088h3.99V47.84h-3.99z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="r">
          <path d="M177.082 53.088h3.99V47.84h-3.99z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="s">
          <path d="M166.887 62.12h16.249V44.694h-16.249z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="t">
          <path d="M0 500h500V0H0z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="u">
          <path d="M162.038 217.792h108.448V104.505H162.038z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="v">
          <path d="M162.032 187.053h15.777v-67h-15.777z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="w">
          <path d="M166.569 210.266h103.917V109.733H166.569z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="x">
          <path d="M166.569 210.266h77.054v-84.995h-77.054z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="y">
          <path d="M213.652 142.938h56.826v-38.396h-56.826z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="z">
          <path d="M65.495 480.555h263.381V90.585H65.495z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="A">
          <path d="M56.712 108.72h12.773V85.06H56.712z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="B">
          <path d="M65.495 255.742h263.38V85.597H65.495z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="C">
          <path d="M56.712 334.915H66.87V103.641H56.712z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="D">
          <path d="M58.092 486.223h270.735V329.854H58.092z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="E">
          <path d="M0 500h500V0H0z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="F">
          <path d="M92.383 465.666h247.419V322.763H92.383z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="G">
          <path d="M91.694 325.292h5.06v-32.431h-5.06z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="H">
          <path d="M102.029 308.207h5.031v-6.311h-5.031z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="I">
          <path d="M109.527 312.809h5.031v-6.311h-5.031z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="J">
          <path d="M117.741 319.373h4.302v-9.046h-4.302z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="K">
          <path d="M158.55 353.451h4.045v-8.182h-4.045z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="L">
          <path d="M328.651 443.239h8.173v-12.977h-8.173z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="M">
          <path d="M328.651 443.239h8.173v-5.401h-8.173z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="N">
          <path d="M328.651 439.452h8.173v-5.402h-8.173z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="O">
          <path d="M328.651 435.664h8.173v-5.402h-8.173z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="P">
          <path d="M102.459 294.019h8.173v-12.978h-8.173z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="Q">
          <path d="M102.459 294.019h8.173v-5.402h-8.173z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="R">
          <path d="M102.459 290.232h8.173v-5.403h-8.173z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="S">
          <path d="M102.459 286.444h8.173v-5.403h-8.173z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="T">
          <path d="M0 500h500V0H0z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="U">
          <path d="M135.407 298.093h32.35v-65.64h-32.35z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="V">
          <path d="M135.147 237.143h8.87v-22.04h-8.87z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="W">
          <path d="M162.357 268.483h6.84v-21.16h-6.84z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="X">
          <path d="M158.287 251.273h10.91v-10.24h-10.91z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="Y">
          <path d="M160.697 228.713h8.86v-13.69h-8.86z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="Z">
          <path d="M182.637 263.263h7.5v-8.17h-7.5z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="aa">
          <path d="M159.697 306.096h22.05v-12.693h-22.05z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="ab">
          <path d="M190.947 308.143h15.71v-66.93h-15.71z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="ac">
          <path d="M195.147 333.404h39.86v-29.961h-39.86z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="ad">
          <path d="M218.147 308.413h11.022v-41.67h-11.022z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="ae">
          <path d="M213.327 271.433h12.94v-11.302h-12.94z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="af">
          <path d="M245.082 361.414h32.35v-65.64h-32.35z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="ag">
          <path d="M244.822 300.464h8.87v-22.04h-8.87z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="ah">
          <path d="M272.032 331.804h6.84v-21.16h-6.84z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="ai">
          <path d="M267.962 314.594h10.91v-10.24h-10.91z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="aj">
          <path d="M270.372 292.034h8.86v-13.69h-8.86z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="ak">
          <path d="M292.312 326.584h7.5v-8.17h-7.5z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="al">
          <path d="M269.372 369.416h22.05v-12.692h-22.05z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="am">
          <path d="M0 500h500V0H0z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="an">
          <path d="M369.821 421.371h8.528v-70.642h-8.528z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="ao">
          <path d="M370.227 451.602h59.618v-34.344h-59.618z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="ap">
          <path d="M359.083 400.701h17.973v-16.497h-17.973z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="aq">
          <path d="M0 500h500V0H0z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="ar">
          <path d="M361.371 119.315h31.434V95.448h-31.434z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="as">
          <path d="M372.016 119.315h19.451v-17.742h-19.451z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="at">
          <path d="M411.529 111.056h13.303V79.848h-13.303z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="au">
          <path d="M421.64 111.056h1.835v-22.37h-1.835z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="av">
          <path d="M413.372 111.022h1.962V88.647h-1.962z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="aw">
          <path d="M389.019 262.6h18.178v-9.498h-18.178z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)" transform="matrix(1.33333 0 0 -1.33333 0 666.667)">
        <path
          d="M0 0c-93.849 48.567-93.849 127.311-.001 175.878 93.848 48.568 246.007 48.568 339.855 0 93.849-48.567 93.849-127.311.001-175.878C246.007-48.567 93.849-48.567 0 0"
          fill="#f5f5f5"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(80.628 46.945)"
        />
      </g>
      <g clipPath="url(#b)" transform="matrix(1.33333 0 0 -1.33333 0 666.667)">
        <path
          d="M0 0l-9.178-5.299c.046.41.078.817.078 1.215v2.659c0 4.731-3.322 6.649-7.42 4.283-1.659-.958-3.186-2.476-4.422-4.252-.671 5.95-5.339 8.166-11.008 4.893-6.131-3.54-11.1-12.147-11.1-19.226v-3.978c0-1.833.339-3.377.94-4.607l-7.623-4.402c-2.273-1.312-4.116-4.503-4.116-7.128s1.843-3.688 4.116-2.376L0-9.504C2.273-8.192 4.116-5 4.116-2.376 4.116.248 2.273 1.312 0 0"
          fill="#ececec"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(106.077 454.866)"
        />
        <path
          d="M0 0l-6.926-3.999c.034.31.058.616.058.917v2.007c0 3.571-2.507 5.018-5.6 3.232-1.252-.722-2.404-1.868-3.337-3.209-.507 4.49-4.029 6.163-8.308 3.693-4.626-2.671-8.377-9.167-8.377-14.51v-3.002c0-1.384.256-2.549.709-3.478l-5.753-3.321c-1.716-.991-3.106-3.399-3.106-5.38 0-1.981 1.39-2.783 3.106-1.793L0-7.173c1.715.99 3.106 3.399 3.106 5.38C3.106.187 1.715.991 0 0"
          fill="#ececec"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(150.24 434.983)"
        />
      </g>
      <g clipPath="url(#c)" transform="matrix(1.33333 0 0 -1.33333 0 666.667)">
        <path
          d="M0 0c-19.247-11.112-19.247-29.129.001-40.243 19.248-11.112 50.455-11.112 69.701 0C88.95-29.13 88.95-11.113 69.702 0 50.454 11.113 19.247 11.113 0 0"
          fill="#e1e1e1"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(363.257 116.45)"
        />
        <path
          d="M0 0l-43.745 25.256c-2.466 1.424-2.466 3.732 0 5.157L201.02 171.557c2.467 1.424 6.465 1.424 8.932 0l43.746-25.255c2.467-1.424 2.467-3.733 0-5.156L8.931 0C6.465-1.424 2.466-1.424 0 0"
          fill="#e1e1e1"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(79.49 68.61)"
        />
        <path
          d="M0 0l-5.443 3.143c-12.35 7.131-31.846 7.48-43.461.774L-69.215-7.809a1.5 1.5 0 111.5-2.598L-47.404 1.32C-36.62 7.545-18.468 7.2-6.943.545L-1.5-2.597C3.8-5.658 6.719-9.635 6.719-13.8c0-4.162-2.919-8.14-8.219-11.2l-74.498-43.011c-11.524-6.655-30.277-6.653-41.801 0l-21.691 12.524c-12.351 7.131-32.45 7.131-44.802 0l-5.443-3.143c-6.53-3.77-10.126-8.786-10.126-14.122 0-4.92 3.12-9.431 8.785-12.702l19.427-11.216-7.983-4.707c-3.647-2.151-3.631-5.644.036-7.761l14.591-8.424c.596-.344.594-.904-.003-1.245l-1.217-.695c-.597-.341-.599-.901-.003-1.245l1.897-1.095c.595-.344 1.57-.345 2.166-.002l6.621 3.806c.596.343 1.571.342 2.166-.002l12.052-6.958c.596-.344 1.57-.344 2.165 0l1.898 1.095c.595.344.595.906 0 1.25l-12.052 6.958c-.596.343-.596.906 0 1.25l4.621 2.668c.595.344 1.569.344 2.165 0l12.052-6.958c.595-.343 1.57-.343 2.165 0l1.897 1.095c.596.344.596.907 0 1.25l-12.053 6.959c-.595.344-.595.907 0 1.25l5.96 3.441c.595.343.595.906 0 1.249l-1.897 1.095c-.596.344-1.57.344-2.165 0l-1.897-1.095c-.595-.343-1.57-.343-2.165 0l-18.533 10.7c-1.641.948-4.319.936-5.952-.027l-3.69-2.177a1.49 1.49 0 01-.458.412l-20.311 11.727c-4.698 2.713-7.285 6.301-7.285 10.104 0 4.159 3.144 8.359 8.626 11.524l5.443 3.144c11.524 6.652 30.277 6.652 41.802 0l21.691-12.525c12.351-7.131 32.45-7.131 44.801 0L0-27.597C6.267-23.98 9.719-19.08 9.719-13.8 9.719-8.519 6.268-3.618 0 0"
          fill="#e1e1e1"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(314.394 158.61)"
        />
      </g>
      <g clipPath="url(#d)" transform="matrix(1.33333 0 0 -1.33333 0 666.667)">
        <path
          d="M0 0c-.384 19.289 7.18 36.725 13.659 45.3 6.48 8.575 17.353 16.958 24.914 14.599 7.041-2.197 6.535-11.371-1.765-17.666-8.301-6.295-20.818-14.261-24.255-24.847L4.171-2.102z"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(416.427 97.637)"
        />
        <g clipPath="url(#e)" opacity={0.149994}>
          <path
            d="M0 0c-.384 19.289 7.18 36.725 13.659 45.3 6.48 8.575 17.353 16.958 24.914 14.599 7.041-2.197 6.535-11.371-1.765-17.666-8.301-6.295-20.818-14.261-24.255-24.847L4.171-2.102z"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(416.427 97.637)"
          />
        </g>
        <path
          d="M0 0a.473.473 0 01.415.391c1.796 10.685 5.376 23.021 11.457 32.721 6.543 10.439 16.052 16.856 21.534 19.06a.473.473 0 01-.353.877c-5.637-2.267-15.372-8.917-22.073-19.629C4.871 23.652 1.288 11.288-.517.548A.472.472 0 010 0"
          fill="#fff"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(418.73 99.484)"
        />
        <path
          d="M0 0c.939 3.661 5.214 9.778 12.258 14.926 7.791 5.695 17.771 8.488 19.972 12.815 2.644 5.198-1.806 9.922-9.935 8.688C14.166 35.196-1.064 23.475-4.198 2.44z"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(423.404 93.45)"
        />
        <path
          d="M0 0a.47.47 0 01.398.323C6.651 19.41 20.893 26.437 27.55 27.485a.472.472 0 01.392.54.464.464 0 01-.54.393C20.042 27.259 5.883 20.099-.5.617A.473.473 0 010 0"
          fill="#fff"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(421.722 97.22)"
        />
      </g>
      <g clipPath="url(#f)" transform="matrix(1.33333 0 0 -1.33333 0 666.667)">
        <path
          d="M0 0l20.311 11.726c11.217 6.476 30.004 6.129 41.961-.774l5.443-3.143c11.957-6.904 11.957-18.097 0-25L-6.782-60.202c-11.958-6.904-31.344-6.903-43.301 0l-21.692 12.524c-11.957 6.903-31.344 6.903-43.301 0l-5.444-3.143c-11.957-6.904-12.557-17.75-1.34-24.226l20.31-11.726"
          fill="none"
          stroke="#2b454e"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeDasharray="none"
          strokeOpacity={1}
          transform="translate(245.928 154.167)"
        />
        <path
          d="M0 0v-1.726c0-2.761 1.939-6.119 4.331-7.5l15.981-9.227 16.247 9.382.003 14.069-15.775 7.286c-2.507 1.158-6.477.978-8.869-.403L4.33 7.5C1.939 6.119 0 2.761 0 0"
          fill="#2b454e"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(131.764 62.64)"
        />
        <path
          d="M0 0c-2.507 1.158-6.478.978-8.869-.403l-7.588-4.381c-2.391-1.381-2.298-3.439.209-4.597l15.771-7.284 16.252 9.379z"
          fill="#375a64"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(152.55 74.924)"
        />
        <g clipPath="url(#g)" opacity={0.100006}>
          <path
            d="M0 0c-1.196-.69-2.165-2.369-2.165-3.75v-11.571l3.701-2.137 16.246 9.382.004 14.069-4.114 1.9z"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(150.54 61.645)"
          />
        </g>
        <path
          d="M0 0c-.389.224-.925.192-1.52-.151L-17.5-9.377c-1.196-.691-2.165-2.37-2.165-3.75v-13.762c0-.692.244-1.174.637-1.397.365-.207 1.671-.96 2.032-1.173.39-.229.93-.2 1.529.146l15.98 9.226c1.195.691 2.165 2.369 2.165 3.75v13.762c0 .687-.24 1.175-.63 1.4C1.683-.964.366-.211 0 0"
          fill="#2b454e"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(169.707 70.06)"
        />
        <path
          d="M0 0l15.98 9.227c1.196.69 2.165.13 2.165-1.25V-5.786c0-1.381-.969-3.059-2.165-3.75L0-18.762c-1.196-.69-2.165-.13-2.165 1.25V-3.75C-2.165-2.369-1.196-.69 0 0"
          fill="#375a64"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(154.24 59.51)"
        />
        <path
          d="M0 0c1.122-.647 2.031-2.223 2.031-3.518 0-.208-.031-.388-.075-.555a2.15 2.15 0 00-.519.213C.315-3.212-.594-1.638-.594-.342c0 .208.03.398.074.565C-.354.177-.181.104 0 0m2.429 2.51l-13.217 7.631-1.437-.831-.001-9.381L.991-7.701c.838-.484 1.599-.525 2.15-.204.243.143 1.195.693 1.439.832.545.314.879.987.879 1.952v2.381c.001 1.933-1.356 4.283-3.03 5.25"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(168.362 46.603)"
        />
        <g clipPath="url(#h)" opacity={0.850006}>
          <path
            d="M0 0l-13.22 7.63-1.43-.83L-1.44-.83C.24-1.8 1.59-4.15 1.59-6.08v-2.38c0-1-.36-1.7-.95-2 .02.01.05.03.07.04.25.15 1.2.7 1.44.84.55.31.88.98.88 1.95v2.38C3.03-3.32 1.67-.97 0 0"
            fill="#fff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(170.791 49.114)"
          />
        </g>
        <path
          d="M0 0v-2.38c0-.97-.33-1.64-.88-1.95-.24-.14-1.19-.69-1.44-.84l-.06-.03c.58.3.94.99.94 1.99v2.38c0 .97-.34 2.03-.89 2.99l1.44.83C-.34 2.04 0 .97 0 0"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(173.82 43.864)"
        />
        <g clipPath="url(#i)" opacity={0.600006}>
          <path
            d="M0 0v-2.38c0-.97-.33-1.64-.88-1.95-.24-.14-1.19-.69-1.44-.84l-.06-.03c.58.3.94.99.94 1.99v2.38c0 .97-.34 2.03-.89 2.99l1.44.83C-.34 2.04 0 .97 0 0"
            fill="#fff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(173.82 43.864)"
          />
        </g>
        <g clipPath="url(#j)" opacity={0.0500031}>
          <path
            d="M0 0v-2.38c0-.97-.33-1.64-.88-1.95-.24-.14-1.19-.69-1.44-.84l-.06-.03c.58.3.94.99.94 1.99v2.38c0 .97-.34 2.03-.89 2.99l1.44.83C-.34 2.04 0 .97 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(173.82 43.864)"
          />
        </g>
        <g clipPath="url(#k)" opacity={0.600006}>
          <path
            d="M0 0c0 .21.031.402.071.562C-.797.804-1.44.257-1.44-.83c0-1.3.91-2.87 2.03-3.52.94-.55 1.74-.26 1.96.62-.17.04-.34.1-.52.21C.91-2.87 0-1.3 0 0"
            fill="#fff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(167.771 46.264)"
          />
        </g>
        <g clipPath="url(#l)" opacity={0.100006}>
          <path
            d="M0 0c0 .21.031.402.071.562C-.797.804-1.44.257-1.44-.83c0-1.3.91-2.87 2.03-3.52.94-.55 1.74-.26 1.96.62-.17.04-.34.1-.52.21C.91-2.87 0-1.3 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(167.771 46.264)"
          />
        </g>
        <g clipPath="url(#m)" opacity={0.699997}>
          <path
            d="M0 0c-1.122.648-2.031 2.223-2.031 3.518S-1.122 5.338 0 4.691c1.122-.648 2.031-2.223 2.031-3.518S1.122-.647 0 0m.992 6.371l-13.217 7.63-.001-9.381L.991-3.01c1.674-.967 3.031-.183 3.031 1.75l.001 2.38c0 1.933-1.357 4.284-3.031 5.251"
            fill="#fff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(168.362 41.913)"
          />
        </g>
        <path
          d="M0 0c1.122-.647 2.031-2.223 2.031-3.518 0-.208-.031-.388-.075-.555a2.158 2.158 0 00-.519.213C.315-3.212-.594-1.637-.594-.342c0 .208.03.398.074.565C-.354.178-.181.104 0 0m2.429 2.51l-13.217 7.631-1.437-.83-.002-9.381L.991-7.701c.838-.484 1.599-.525 2.15-.203.243.142 1.195.692 1.439.832.545.313.879.986.879 1.951v2.381c.001 1.933-1.356 4.283-3.03 5.25"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(179.113 52.81)"
        />
        <g clipPath="url(#n)" opacity={0.850006}>
          <path
            d="M0 0l-13.22 7.63-1.43-.83L-1.439-.83C.24-1.8 1.59-4.15 1.59-6.08v-2.38c0-1-.36-1.7-.95-2 .021.01.05.03.07.04.25.15 1.201.7 1.44.84.55.31.88.98.88 1.95v2.38C3.03-3.32 1.67-.97 0 0"
            fill="#fff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(181.542 55.32)"
          />
        </g>
        <path
          d="M0 0v-2.38c0-.97-.33-1.64-.88-1.95-.24-.14-1.19-.69-1.44-.84l-.06-.03c.58.3.94.99.94 1.99v2.38c0 .97-.34 2.03-.89 2.99l1.44.83C-.34 2.04 0 .97 0 0"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(184.572 50.07)"
        />
        <g clipPath="url(#o)" opacity={0.600006}>
          <path
            d="M0 0v-2.38c0-.97-.33-1.64-.88-1.95-.24-.14-1.19-.69-1.44-.84l-.06-.03c.58.3.94.99.94 1.99v2.38c0 .97-.34 2.03-.89 2.99l1.44.83C-.34 2.04 0 .97 0 0"
            fill="#fff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(184.572 50.07)"
          />
        </g>
        <g clipPath="url(#p)" opacity={0.0500031}>
          <path
            d="M0 0v-2.38c0-.97-.33-1.64-.88-1.95-.24-.14-1.19-.69-1.44-.84l-.06-.03c.58.3.94.99.94 1.99v2.38c0 .97-.34 2.03-.89 2.99l1.44.83C-.34 2.04 0 .97 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(184.572 50.07)"
          />
        </g>
        <g clipPath="url(#q)" opacity={0.600006}>
          <path
            d="M0 0c0 .21.031.402.071.562C-.797.804-1.44.257-1.44-.83c0-1.3.91-2.87 2.03-3.52.94-.55 1.741-.26 1.96.621-.17.039-.34.099-.52.209C.91-2.87 0-1.3 0 0"
            fill="#fff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(178.522 52.47)"
          />
        </g>
        <g clipPath="url(#r)" opacity={0.100006}>
          <path
            d="M0 0c0 .21.031.402.071.562C-.797.804-1.44.257-1.44-.83c0-1.3.91-2.87 2.03-3.52.94-.55 1.741-.26 1.96.621-.17.039-.34.099-.52.209C.91-2.87 0-1.3 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(178.522 52.47)"
          />
        </g>
        <g clipPath="url(#s)" opacity={0.699997}>
          <path
            d="M0 0c-1.122.647-2.031 2.223-2.031 3.518S-1.122 5.338 0 4.69c1.122-.647 2.031-2.222 2.031-3.517C2.031-.123 1.122-.647 0 0m.992 6.37l-13.217 7.631-.002-9.381L.991-3.011c1.674-.966 3.031-.183 3.031 1.75V1.12c.001 1.933-1.356 4.283-3.03 5.25"
            fill="#fff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(179.113 48.119)"
          />
        </g>
      </g>
      <g clipPath="url(#t)" transform="matrix(1.33333 0 0 -1.33333 0 666.667)">
        <path
          d="M0 0l37.606 21.744c3.875 2.238 5.814 5.171 5.815 8.104 0 2.934.017 5.231.017 5.231L4.419 57.08C2.352 58.274.676 61.177.676 63.564v46.365l-65.684-37.955-.003-46.365c0-2.387 1.676-5.29 3.743-6.484L-28.076 0C-20.323-4.476-7.753-4.476 0 0"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(227.049 107.862)"
        />
        <g clipPath="url(#u)" opacity={0.600006}>
          <path
            d="M0 0l37.606 21.744c3.875 2.238 5.814 5.171 5.815 8.104 0 2.934.017 5.231.017 5.231L4.419 57.08C2.352 58.274.676 61.177.676 63.564v46.365l-65.684-37.955-.003-46.365c0-2.387 1.676-5.29 3.743-6.484L-28.076 0C-20.323-4.476-7.753-4.476 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(227.049 107.862)"
          />
        </g>
        <g clipPath="url(#v)" opacity={0.300003}>
          <path
            d="M0 0v-5.208l-12.038 6.937c-2.053 1.189-3.739 4.085-3.739 6.484v46.36l12.514 7.219c.259-3.178.54-6.463.799-9.662l-8.774-5.079V10.828c0-2.399 1.664-5.295 3.738-6.484z"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(177.81 125.261)"
          />
        </g>
        <path
          d="M0 0c0-2.388 1.676-5.291 3.743-6.484L32.398-22.99c7.754-4.476 20.325-4.477 28.079-.003L98.101-1.251c7.754 4.474 7.755 11.73.002 16.207L69.43 31.473c-2.068 1.194-3.744 4.097-3.744 6.484v36.228L0 36.229z"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(166.569 136.081)"
        />
        <g clipPath="url(#w)" opacity={0.300003}>
          <path
            d="M0 0c0-2.388 1.676-5.291 3.743-6.484L32.398-22.99c7.754-4.476 20.325-4.477 28.079-.003L98.101-1.251c7.754 4.474 7.755 11.73.002 16.207L69.43 31.473c-2.068 1.194-3.744 4.097-3.744 6.484v36.228L0 36.229z"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(166.569 136.081)"
          />
        </g>
        <g clipPath="url(#x)" opacity={0.5}>
          <path
            d="M0 0v.005l-7.625 4.403c-2.067 1.194-3.743 4.096-3.743 6.484V47.12L-77.055 9.164v-36.229c0-2.388 1.677-5.291 3.744-6.484l7.493-4.326z"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(243.623 163.147)"
          />
        </g>
        <path
          d="M0 0v-5.23c0-2.939-1.945-5.857-5.814-8.105l-37.612-21.732c-3.718-2.161-8.537-3.242-13.4-3.328v5.208c4.863.065 9.682 1.189 13.4 3.329L-5.814-8.105C-1.945-5.878 0-2.939 0 0"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(270.478 142.938)"
        />
        <g clipPath="url(#y)" opacity={0.5}>
          <path
            d="M0 0v-5.23c0-2.939-1.945-5.857-5.814-8.105l-37.612-21.732c-3.718-2.161-8.537-3.242-13.4-3.328v5.208c4.863.065 9.682 1.189 13.4 3.329L-5.814-8.105C-1.945-5.878 0-2.939 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(270.478 142.938)"
          />
        </g>
        <path
          d="M0 0c2.424-1.399 6.485-1.324 9.069.167l254.023 146.658c2.585 1.492 4.679 5.12 4.679 4.104v231.303c0 6.984-1.965 10.538-4.39 11.937-2.423 1.4-6.484 1.325-9.068-.168L.293 247.344c-2.584-1.492-4.679-5.121-4.679-8.105L-4.389 7.937C-4.389 4.953-2.424 1.399 0 0"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(61.103 91.05)"
        />
        <path
          d="M0 0l254.022 146.656c2.584 1.492 4.68 5.121 4.68 4.105v231.303c0 6.984-2.096 8.194-4.68 6.702L.003 242.108c-2.585-1.492-4.679-5.12-4.679-8.105L-4.679 2.702C-4.679-.283-2.584-1.492 0 0"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(70.174 91.224)"
        />
        <g clipPath="url(#z)" opacity={0.5}>
          <path
            d="M0 0l254.022 146.656c2.584 1.492 4.68 5.121 4.68 4.105v231.303c0 6.984-2.096 8.194-4.68 6.702L.003 242.108c-2.585-1.492-4.679-5.12-4.679-8.105L-4.679 2.702C-4.679-.283-2.584-1.492 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(70.174 91.224)"
          />
        </g>
        <path
          d="M0 0c-2.593-1.146-6.181-1.102-8.386.173-2.421 1.405-4.387 4.95-4.387 7.953v14.712l8.775-5.079V3.047C-3.998.324-2.269-.908 0 0"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(69.485 85.882)"
        />
        <g clipPath="url(#A)" opacity={0.5}>
          <path
            d="M0 0c-2.593-1.146-6.181-1.102-8.386.173-2.421 1.405-4.387 4.95-4.387 7.953v14.712l8.775-5.079V3.047C-3.998.324-2.269-.908 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(69.485 85.882)"
          />
        </g>
        <path
          d="M0 0l.008-14.704c0-2.984 2.095-4.193 4.679-2.701l254.022 146.656c2.584 1.492 4.679 5.121 4.679 8.105v14.745z"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(65.487 103.641)"
        />
        <g clipPath="url(#B)" opacity={0.300003}>
          <path
            d="M0 0v-14.743c0-2.984 2.095-4.194 4.679-2.701l254.023 146.656c2.584 1.492 4.678 5.121 4.678 8.105v14.745z"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(65.495 103.68)"
          />
        </g>
        <path
          d="M0 0l.002 200.198c0 1.791 1.257 3.968 2.808 4.863l240.202 138.681c1.55.895 2.807.17 2.807-1.621l.008-200.202c0-1.791-1.257-3.968-2.807-4.863L2.808-1.621C1.257-2.517 0-1.791 0 0"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(74.272 122.116)"
        />
        <path
          d="M0 0l.002 200.198c0 1.791 1.257 3.968 2.808 4.863l240.202 138.681c1.55.895 2.807.17 2.807-1.621l.008-200.202c0-1.791-1.257-3.968-2.807-4.863L2.808-1.621C1.257-2.517 0-1.791 0 0"
          fill="#1a2e35"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(74.272 122.116)"
        />
        <g clipPath="url(#C)" opacity={0.699997}>
          <path
            d="M0 0c-.843-1.47-1.362-3.134-1.383-4.625v-221.591l-8.775 5.079.005 221.57c0 1.491.535 3.155 1.378 4.625z"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(66.87 329.857)"
          />
        </g>
        <g clipPath="url(#D)" opacity={0.300003}>
          <path
            d="M0 0c-.332 2.412-2.279 3.287-4.637 1.937l-254.014-146.666c-1.284-.742-2.446-2.003-3.308-3.475l-8.776 5.068c.852 1.45 2.014 2.734 3.309 3.475L-13.411 6.994c2.589 1.493 6.64 1.571 9.073.166C-2.124 5.887-.299 2.8 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(328.827 478.058)"
          />
        </g>
      </g>
      <g clipPath="url(#E)" transform="matrix(1.33333 0 0 -1.33333 0 666.667)">
        <path
          d="M0 0c-.032-.011-.591-.432-1.831-.555-.959-.095-1.959.051-2.679.471-1.217.699-2.186 2.464-2.186 3.95l-.011 181.604 4.381-2.53V1.347c0-1.055.53-1.664 1.294-1.664.108 0 .525.033 1.032.317"
          fill="#f0f0f0"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(98.4 109.92)"
        />
        <path
          d="M0 0c-.679 0-.752.788-.752 1.126v196.438l6.147 3.547c.75.436 1.359 2.374 2.569 6.693.638 2.276 1.601 5.715 2.1 6.101l55.514 32.052c.474-.248 1.517-2.083 2.149-3.196 1.357-2.388 2.17-3.74 3.089-3.74.158 0 .307.041.443.12l170.663 98.549V141.873c0-1.295-.942-2.931-2.057-3.572L.767.246C.489.084.225 0 0 0"
          fill="#fafafa"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(97.365 110.141)"
        />
        <path
          d="M0 0l-171.471-99.016a.343.343 0 00-.173-.047c-1.302 0-3.947 6.954-5.258 6.954a.353.353 0 01-.175-.047l-55.597-32.101c-1.291-.743-3.38-12.045-4.66-12.787l-6.416-3.703v-196.749c0-1.054.526-1.664 1.29-1.664.312 0 .664.102 1.036.317L-2.326-200.788c1.291.743 2.326 2.554 2.326 4.039v181.593zm-176.541-93.089h.005-.005M-1.076-1.865v-194.884c0-1.107-.835-2.558-1.789-3.106l-239.099-138.057c-.268-.155-.436-.172-.496-.172-.211 0-.213.564-.213.588v196.127l5.879 3.393c.929.539 1.51 2.351 2.816 7.013.582 2.077 1.455 5.192 1.926 5.822l55.097 31.813c.476-.549 1.258-1.925 1.754-2.798 1.499-2.637 2.337-4.013 3.557-4.013.25 0 .497.067.712.191L-1.076-1.865"
          fill="#e1e1e1"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(339.825 448.763)"
        />
        <path
          d="M0 0c-1.207.697-2.186 2.467-2.186 3.953l-.003 18.193c0 1.485 1.046 3.293 2.333 4.036L239.24 164.238c1.286.743 3.309.781 4.516.084 1.208-.697 2.186-2.468 2.181-3.952l.002-18.195c.005-1.487-1.039-3.292-2.326-4.035L4.515.084C3.228-.658 1.207-.697 0 0"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(93.886 300.845)"
        />
        <g clipPath="url(#F)" opacity={0.399994}>
          <path
            d="M0 0c-.161 1.206-1.141 1.647-2.304.969l-239.097-138.056c-.636-.377-1.217-1.012-1.647-1.744l-4.37 2.529c.43.732 1 1.359 1.646 1.735L-6.674 3.487c1.281.743 3.305.786 4.511.086C-1.055 2.928-.14 1.399 0 0"
            fill="#fff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(339.802 461.594)"
          />
        </g>
        <g clipPath="url(#G)" opacity={0.100006}>
          <path
            d="M0 0c-.42-.721-.678-1.55-.678-2.293v-27.609l-4.381 2.529.002 27.601a3.998 3.998 0 00.102.859c.119.484.318.999.585 1.442z"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(96.753 322.763)"
          />
        </g>
        <path
          d="M0 0l.002-12.454-171.471-99.015c-1.281-.743-2.324-.14-2.324 1.345v4.715c0 1.485-1.044 2.088-2.336 1.346l-56.543-32.647c-1.291-.743-2.336-2.552-2.336-4.037v-4.714c0-1.486-1.044-3.294-2.325-4.037l-6.415-3.703v12.454c0 1.485 1.044 3.294 2.325 4.037L-2.325 1.346C-1.044 2.088 0 1.486 0 0"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(339.823 461.217)"
        />
        <path
          d="M0 0c1.619.935 2.933.177 2.933-1.693S1.619-5.837 0-6.772c-1.62-.936-2.933-.177-2.933 1.693S-1.62-.935 0 0"
          fill="#fff"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(328.897 453.407)"
        />
        <path
          d="M0 0c1.619.935 2.933.177 2.933-1.693S1.619-5.837 0-6.772c-1.62-.936-2.933-.178-2.933 1.692C-2.933-3.209-1.62-.935 0 0"
          fill="#fff"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(318.34 447.32)"
        />
        <path
          d="M0 0c1.619.935 2.933.177 2.933-1.693S1.619-5.837 0-6.772c-1.62-.936-2.933-.177-2.933 1.693S-1.62-.935 0 0"
          fill="#fff"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(307.782 441.232)"
        />
        <path
          d="M0 0v-15.156l-243.75-140.746v15.155l4.791 2.775c1.281.743 1.711 1.872 2.325 4.036l1.624 5.643c.513 1.959 1.045 3.293 2.336 4.036l55.597 32.101c1.291.742 2.299.824 3.282-.8l1.993-3.617c.731-1.263 1.097-2.006 2.378-1.263z"
          fill="#e7e7e7"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(339.825 448.763)"
        />
        <path
          d="M0 0v-15.155l-4.381 2.529V2.519z"
          fill="#e1e1e1"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(96.075 308.016)"
        />
        <g clipPath="url(#H)" opacity={0.5}>
          <path
            d="M0 0l-3.658-2.112L-2.264.571c.123.235.136.529.028.655-.107.128-.293.038-.415-.197l-1.985-3.818-.005-.014a.82.82 0 01-.053-.141c-.005-.016-.013-.031-.016-.046a.798.798 0 01-.026-.198c0-.065.009-.121.026-.167.003-.012.011-.018.016-.028a.21.21 0 01.053-.079l.005-.009 1.985-1.526c.056-.043.125-.04.194 0a.59.59 0 01.221.283c.108.251.095.529-.028.624l-1.394 1.072L0-.906c.163.094.294.372.294.623C.294-.033.163.094 0 0"
            fill="#375a64"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(106.765 306.923)"
          />
        </g>
        <g clipPath="url(#I)" opacity={0.5}>
          <path
            d="M0 0l3.658 2.112-1.394 1.072c-.123.094-.136.373-.028.624.107.251.293.376.415.282l1.985-1.526c.002-.001.003-.006.005-.008a.22.22 0 00.053-.079c.005-.011.013-.017.017-.028a.517.517 0 00.025-.168.831.831 0 00-.025-.197c-.004-.016-.012-.031-.017-.046a.863.863 0 00-.053-.142l-.005-.014-1.985-3.818a.555.555 0 00-.193-.224c-.083-.047-.164-.042-.222.028-.108.126-.095.42.028.655l1.394 2.683L0-.906C-.163-1-.294-.874-.294-.623c0 .25.131.529.294.623"
            fill="#375a64"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(109.821 308.688)"
          />
        </g>
        <g clipPath="url(#J)" opacity={0.5}>
          <path
            d="M0 0c-.023.221-.215.192-.312-.047l-.502-1.225c-.854.093-1.89-.742-2.589-2.222-.906-1.919-.906-4.188 0-5.06.453-.436 1.049-.441 1.644-.097.594.343 1.19 1.035 1.643 1.994.115.243.115.531 0 .641-.115.111-.301.003-.417-.24-.676-1.431-1.777-2.069-2.453-1.417-.677.651-.677 2.346 0 3.778.481 1.02 1.174 1.623 1.778 1.66l-.378-.924c-.098-.24-.008-.463.146-.363l1.492.974c.104.069.182.258.167.409z"
            fill="#375a64"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(121.823 319.223)"
          />
        </g>
        <g clipPath="url(#K)" opacity={0.5}>
          <path
            d="M0 0l1.333 2.823c.158.334.158.727 0 .879-.157.152-.413.004-.57-.329L-.57.549l-1.334 1.284c-.158.151-.413.004-.571-.33-.158-.334-.158-.727 0-.878l1.334-1.284-1.334-2.823c-.158-.334-.158-.727 0-.879.079-.076.182-.077.286-.017a.775.775 0 01.285.346L-.57-1.208.763-2.492c.079-.076.182-.077.285-.017.103.059.207.18.285.347.158.333.158.726 0 .878z"
            fill="#375a64"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(161.144 349.69)"
          />
        </g>
        <path
          d="M0 0v4.716c0 1.486 1.043 3.293 2.331 4.036L197.704 121.51c1.287.743 2.33.141 2.33-1.345v-4.716c0-1.486-1.044-3.293-2.33-4.036L2.331-1.346C1.043-2.089 0-1.486 0 0"
          fill="#fafafa"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(126.677 315.752)"
        />
        <path
          d="M0 0l.611-1.3c.039-.082.114-.111.201-.078l1.368.525c.217.083.303-.223.146-.518l-.99-1.859a.61.61 0 01-.076-.357l.234-1.682c.037-.268-.19-.619-.385-.595l-1.224.152c-.077.009-.169-.044-.246-.143l-1.225-1.564c-.194-.249-.421-.16-.385.15l.235 1.952c.014.123-.014.224-.076.269l-.991.715c-.157.114-.07.52.147.688l1.369 1.055c.086.067.16.182.199.309l.612 2.007C-.379.044-.098.207 0 0z"
          fill="none"
          stroke="#e1e1e1"
          strokeWidth={0.807}
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit={10}
          strokeDasharray="none"
          strokeOpacity={1}
          transform="translate(323.022 434.77)"
        />
        <g clipPath="url(#L)" opacity={0.5}>
          <g clipPath="url(#M)">
            <path
              d="M0 0a.804.804 0 00-.699.404.805.805 0 00.295 1.102l6.558 3.787a.807.807 0 10.807-1.398L.403.108A.81.81 0 000 0"
              fill="#375a64"
              fillOpacity={1}
              fillRule="nonzero"
              stroke="none"
              transform="translate(329.459 437.838)"
            />
          </g>
          <g clipPath="url(#N)">
            <path
              d="M0 0a.807.807 0 00-.404 1.507l6.558 3.786a.806.806 0 10.807-1.397L.403.108A.81.81 0 000 0"
              fill="#375a64"
              fillOpacity={1}
              fillRule="nonzero"
              stroke="none"
              transform="translate(329.459 434.05)"
            />
          </g>
          <g clipPath="url(#O)">
            <path
              d="M0 0a.804.804 0 00-.699.404.805.805 0 00.295 1.102l6.558 3.787a.806.806 0 10.807-1.398L.403.108A.81.81 0 000 0"
              fill="#375a64"
              fillOpacity={1}
              fillRule="nonzero"
              stroke="none"
              transform="translate(329.459 430.262)"
            />
          </g>
        </g>
        <g clipPath="url(#P)" opacity={0.5}>
          <g clipPath="url(#Q)">
            <path
              d="M0 0a.807.807 0 00-.404 1.506l6.558 3.787a.807.807 0 00.807-1.398L.403.108A.81.81 0 000 0"
              fill="#375a64"
              fillOpacity={1}
              fillRule="nonzero"
              stroke="none"
              transform="translate(103.267 288.617)"
            />
          </g>
          <g clipPath="url(#R)">
            <path
              d="M0 0a.807.807 0 00-.404 1.506l6.558 3.787a.807.807 0 00.807-1.398L.403.108A.81.81 0 000 0"
              fill="#375a64"
              fillOpacity={1}
              fillRule="nonzero"
              stroke="none"
              transform="translate(103.267 284.83)"
            />
          </g>
          <g clipPath="url(#S)">
            <path
              d="M0 0a.807.807 0 00-.404 1.506l6.558 3.787a.807.807 0 00.807-1.398L.403.108A.81.81 0 000 0"
              fill="#375a64"
              fillOpacity={1}
              fillRule="nonzero"
              stroke="none"
              transform="translate(103.267 281.041)"
            />
          </g>
        </g>
        <path
          d="M0 0l.001-5.81c0-1.188.842-1.673 1.871-1.08l77.926 45.056-.001 10.129L1.87 3.239C.841 2.645 0 1.187 0 0"
          fill="#e7e7e7"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(161.447 322.012)"
        />
        <path
          d="M0 0c1.032.596 1.87.113 1.87-1.08v-5.81c0-1.193-.838-2.643-1.87-3.239l-9.826-5.673-.001 10.129z"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(251.071 375.98)"
        />
        <path
          d="M0 0c-.304-.176-.568-.203-.784-.077-.366.211-.593.837-.593 1.636 0 1.531.816 3.295 1.783 3.855.179.102.344.154.493.154a.58.58 0 00.292-.077c.365-.212.592-.838.592-1.637C1.783 2.323.967.558 0 0m3.517 2.1l-1.096.342c.113.465.176.943.176 1.412 0 1.121-.365 1.974-.999 2.341-.473.272-1.04.245-1.598-.078-1.228-.709-2.19-2.711-2.19-4.558 0-1.121.364-1.974.999-2.34.215-.124.45-.186.695-.186.292 0 .599.088.902.263.739.427 1.376 1.324 1.771 2.37l1.098-.342a.407.407 0 01.242.776"
          fill="#fafafa"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(246.827 365.914)"
        />
        <path
          d="M0 0l-11.255-6.507c-1.029-.594-1.863-2.039-1.863-3.227v-5.788c0-1.188.834-1.67 1.863-1.076l11.254 6.506c1.029.594 1.863 2.039 1.863 3.228l.001 5.788C1.863.113 1.029.595 0 0"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(129.06 305.471)"
        />
        <path
          d="M0 0c-2.422-1.399-4.385-4.799-4.385-7.596 0-2.797 1.964-3.93 4.386-2.531 2.421 1.398 4.387 4.798 4.384 7.595C4.383.265 2.419 1.398 0 0"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(324.713 418.44)"
        />
        <path
          d="M0 0a.772.772 0 00-.39.102c-.272.157-.422.469-.422.877 0 .747.507 1.625 1.155 2 .352.204.696.23.97.073.273-.158.423-.469.423-.878 0-.747-.507-1.626-1.154-2A1.184 1.184 0 000 0"
          fill="#fff"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(324.201 413.98)"
        />
        <path
          d="M0 0c-.431.249-.979.208-1.544-.119l-.637-.366c-1.105-.64-1.972-2.142-1.972-3.419v-.45c.624-.348 1.477-.289 2.421.256.942.542 1.796 1.47 2.42 2.536v.109C.688-.782.439-.253 0 0"
          fill="#fff"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(326.445 413.69)"
        />
        <path
          d="M0 0v1.548c0 1.766-1.074 2.578-2.399 1.814-1.325-.765-2.399-2.817-2.399-4.584v-1.383c0-.871-.303-1.86-.807-2.703l-.003-.005a1.509 1.509 0 01-.197-.708c0-.445.271-.649.605-.457L.402-3.243c.334.193.605.709.605 1.155 0 .222-.069.384-.178.467l-.009.006C.325-1.393 0-.833 0 0"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(309.205 404.877)"
        />
        <path
          d="M0 0c0-.848-.515-1.833-1.151-2.201-.636-.366-1.152.023-1.152.871z"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(307.957 400.203)"
        />
        <path
          d="M0 0a.7.7 0 00-.074-.312.47.47 0 00-.174-.215l-1.162-.671c-.069-.039-.126-.034-.174.013-.05.047-.074.123-.074.227V.475c0 .104.024.209.074.313a.449.449 0 00.174.214l1.162.671c.066.039.126.035.174-.012.051-.048.074-.123.074-.227zm-.802 9.231c.344.199.668.313.973.341.306.03.569-.021.794-.152.223-.131.4-.336.528-.616.13-.281.194-.634.194-1.06 0-.34-.037-.66-.11-.958a6.182 6.182 0 00-.28-.857 7.437 7.437 0 00-.374-.776C.789 4.909.656 4.67.53 4.436a8.494 8.494 0 01-.34-.701 2.911 2.911 0 01-.208-.693 1.455 1.455 0 00-.087-.321.404.404 0 00-.161-.206l-1.125-.65c-.069-.039-.128-.035-.175.012-.05.048-.075.123-.075.227 0 .313.042.616.124.91.085.294.187.577.309.852.124.274.258.539.402.792.144.254.279.494.4.721.124.227.226.445.31.654.082.209.124.408.124.597 0 .274-.068.451-.204.529-.134.078-.342.035-.626-.128-.276-.159-.474-.338-.594-.535a2.37 2.37 0 01-.262-.57c-.056-.184-.104-.35-.144-.502a.505.505 0 00-.234-.326l-1.127-.65c-.054-.031-.102-.031-.142.003-.04.034-.059.097-.059.193.006.334.067.716.183 1.149A6.768 6.768 0 00-1.88 8.311a3.97 3.97 0 001.078.92"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(289.322 388.284)"
        />
      </g>
      <g clipPath="url(#T)" transform="matrix(1.33333 0 0 -1.33333 0 666.667)">
        <path
          d="M0 0l3.427 1.979v6.648zm6.873-34.467c-.92 0-1.771.353-2.461 1.021-.898.863-1.357 2.089-1.357 3.635v7.401l-19.781-11.421c-.63-.364-1.286-.55-1.946-.55-.918 0-1.767.352-2.457 1.016-.902.865-1.362 2.092-1.362 3.64v12.19c0 2.293.383 4.448 1.138 6.406L.99 45.144c.545 1.339 1.057 2.307 1.573 2.978.579.747 1.336 1.393 2.261 1.928l11.281 6.513c.634.365 1.288.548 1.947.548.916 0 1.764-.348 2.453-1.009.91-.881 1.367-2.105 1.367-3.645v-39.83l2.674 1.544c.629.364 1.284.549 1.946.549.913 0 1.76-.347 2.449-1.004.916-.892 1.371-2.114 1.371-3.652V-2.125c0-1.212-.281-2.422-.835-3.597-.618-1.307-1.435-2.261-2.429-2.837l-5.176-2.988v-10.285c0-1.21-.281-2.42-.835-3.597-.622-1.314-1.44-2.268-2.435-2.84l-9.781-5.648c-.633-.365-1.288-.55-1.948-.55"
          fill="#e1e1e1"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(154.685 251.84)"
        />
        <path
          d="M0 0c-.218 0-.86-.079-2.124-.808-1.21-.699-2.227-1.565-3.019-2.571a13.943 13.943 0 01-2.026-3.56 22.87 22.87 0 01-1.208-4.48 37.574 37.574 0 01-.489-5.24 248.298 248.298 0 01-.001-19.16c.046-1.77.195-3.322.444-4.646.249-1.327.617-2.024.883-2.375.245-.326.455-.409.715-.438l.022-.001c.309 0 .951.092 2.179.801 1.21.699 2.226 1.564 3.02 2.574A14.612 14.612 0 01.469-36.3a20.14 20.14 0 011.194 4.425c.256 1.654.409 3.423.457 5.252.196 6.558.196 13.006 0 19.176-.091 1.678-.266 3.239-.517 4.585C1.4-1.794 1.091-.94.709-.399c-.208.294-.385.366-.635.395zm-12.534-68.587c-.202 0-.401.005-.598.015-3.327.164-6.138 1.402-8.353 3.679-2.065 2.12-3.608 5.07-4.583 8.768-.914 3.445-1.458 7.337-1.615 11.562-.099 3.09-.15 6.494-.15 10.126 0 3.633.051 7.169.149 10.512a81.204 81.204 0 001.582 13.211 58.594 58.594 0 004.136 12.776 50.512 50.512 0 007.033 11.2c2.87 3.454 6.338 6.369 10.31 8.662 3.938 2.274 7.345 3.38 10.412 3.38.202 0 .4-.005.598-.015 3.331-.17 6.139-1.407 8.352-3.679 2.066-2.127 3.607-5.076 4.584-8.766.914-3.456 1.457-7.347 1.614-11.565.099-3.312.15-6.792.15-10.358 0-3.57-.051-7.029-.15-10.281a81.102 81.102 0 00-1.581-13.208 58.642 58.642 0 00-4.135-12.777 50.347 50.347 0 00-7.034-11.2c-2.868-3.454-6.336-6.368-10.31-8.662-3.939-2.274-7.345-3.38-10.411-3.38"
          fill="#e1e1e1"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(215.822 310.96)"
        />
        <path
          d="M0 0l3.427 1.979v6.648zm6.873-34.467c-.92 0-1.771.353-2.461 1.021-.898.863-1.357 2.089-1.357 3.635v7.401l-19.781-11.421c-.63-.364-1.286-.55-1.946-.55-.918 0-1.767.352-2.457 1.016-.902.865-1.362 2.092-1.362 3.64v12.19c0 2.293.383 4.448 1.138 6.406L.99 45.144c.545 1.339 1.057 2.307 1.573 2.978.579.747 1.336 1.393 2.261 1.928l11.281 6.513c.634.365 1.288.548 1.947.548.916 0 1.764-.348 2.453-1.009.91-.881 1.367-2.105 1.367-3.645v-39.83l2.674 1.544c.629.364 1.284.549 1.946.549.913 0 1.76-.347 2.449-1.004.916-.892 1.371-2.114 1.371-3.652V-2.125c0-1.212-.281-2.422-.835-3.597-.618-1.307-1.435-2.261-2.429-2.837l-5.176-2.988v-10.285c0-1.21-.281-2.42-.835-3.597-.622-1.314-1.44-2.268-2.435-2.84l-9.781-5.648c-.633-.365-1.288-.55-1.948-.55"
          fill="#e1e1e1"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(264.36 315.16)"
        />
        <path
          d="M0 0c-.081.077-.165.14-.252.189-.342.191-7.592 4.38-7.592 4.38v35.982c0 .842-.199 1.455-.596 1.84a1.29 1.29 0 01-.241.183c-.345.199-8.033 4.636-8.111 4.683-.349.207-.746.182-1.192-.074l-11.279-6.512c-.647-.375-1.158-.805-1.532-1.287-.372-.485-.783-1.278-1.231-2.379l-22.334-56.25c-.648-1.678-.971-3.512-.971-5.506V-36.94c0-.844.197-1.457.597-1.84.081-.079.165-.142.253-.192.341-.192 8.029-4.635 8.112-4.683.345-.2.736-.172 1.178.083l15.406 8.895v-2.349c0-.844.198-1.457.597-1.84.077-.075.158-.137.241-.186l8.121-4.688c.344-.201.739-.174 1.181.082l9.785 5.65c.547.315 1.02.895 1.42 1.738.397.844.596 1.686.596 2.532v11.728l6.424 3.709c.547.317 1.021.895 1.42 1.74.397.842.597 1.686.597 2.53v12.189C.597-.998.397-.387 0 0"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(190.482 258.699)"
        />
        <g clipPath="url(#U)" opacity={0.149994}>
          <path
            d="M0 0l-.04-.14c-.35-.49-.73-1.24-1.14-2.25l-22.33-56.25c-.29-.74-.51-1.51-.66-2.31l-8.18 4.72c.16.79.37 1.55.65 2.28L-9.37 2.3c.45 1.1.86 1.9 1.24 2.38 0 0 0 .01.01.01z"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(167.757 293.403)"
          />
        </g>
        <g clipPath="url(#V)" opacity={0.300003}>
          <path
            d="M0 0c-.92.53-7.69 4.44-8.02 4.62-.08.05-.17.12-.25.2-.4.38-.6.99-.6 1.84v12.18c0 1.12.12 2.18.32 3.2l8.12-4.69c-.21-1.01-.31-2.08-.31-3.2V1.96c0-.84.2-1.45.59-1.83C-.1.08-.05.04 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(144.017 215.103)"
          />
        </g>
        <g clipPath="url(#W)" opacity={0.300003}>
          <path
            d="M0 0v-21.16l-6.84 3.95z"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(169.197 268.483)"
          />
        </g>
        <g clipPath="url(#X)" opacity={0.399994}>
          <path
            d="M0 0l-10.91-6.29 4.07 10.24z"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(169.197 247.323)"
          />
        </g>
        <g clipPath="url(#Y)" opacity={0.300003}>
          <path
            d="M0 0c.05-.04.09-.08.14-.12-.91.52-7.7 4.44-8.02 4.62-.09.05-.17.11-.24.19-.4.38-.6 1-.6 1.84v2.35l8.12 4.69V1.84C-.6 1-.4.38 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(169.417 215.143)"
          />
        </g>
        <g clipPath="url(#Z)" opacity={0.149994}>
          <path
            d="M0 0c-.32.14-.68.1-1.07-.13L-7.5-3.84v8.17S-.89.52 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(190.137 258.933)"
          />
        </g>
        <g clipPath="url(#aa)" opacity={0.399994}>
          <path
            d="M0 0c-.33.17-.71.13-1.13-.11L-12.4-6.62c-.65-.38-1.15-.8-1.53-1.28l-8.12 4.69c.37.48.88.9 1.52 1.28l11.28 6.51c.45.26.84.28 1.19.07C-7.98 4.61-.76.44 0 0"
            fill="#fff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(181.747 301.303)"
          />
        </g>
        <path
          d="M0 0v21.158L-10.905-6.297zm1.645-32.287c-.549-.317-1.023-.283-1.42.101-.399.383-.597.997-.597 1.84v11.731l-23.53-13.586c-.548-.317-1.02-.283-1.419.101-.4.383-.597.996-.597 1.84v12.19c0 1.994.323 3.828.971 5.506l22.334 56.25c.448 1.101.859 1.894 1.231 2.378.374.483.885.913 1.532 1.288l11.279 6.511c.547.315 1.02.282 1.42-.101.397-.385.596-.998.596-1.84V7.762l6.424 3.709c.547.316 1.021.281 1.42-.1.398-.387.597-.998.597-1.842V-2.66c0-.844-.199-1.688-.597-2.529-.399-.846-.873-1.424-1.42-1.741l-6.424-3.709v-11.728c0-.846-.199-1.688-.596-2.531-.4-.844-.873-1.424-1.42-1.739z"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(169.193 247.328)"
        />
        <path
          d="M0 0a34.894 34.894 0 00-.288 3.767c-.25 6.371-.25 12.813 0 19.32.049 1.944.224 3.809.523 5.592.3 1.783.748 3.44 1.345 4.975a16.361 16.361 0 002.39 4.197c.883 1.122 1.973 2.074 3.246 2.88.164-1.136.284-2.376.357-3.725a314.2 314.2 0 000-19.32 44.06 44.06 0 00-.486-5.57 22.54 22.54 0 00-1.344-4.973 17.055 17.055 0 00-2.428-4.219C2.416 1.782 1.304.813 0 0m29.516 40.871c-.15 4.052-.661 7.724-1.532 11.017-.871 3.291-2.192 5.844-3.958 7.662a9.575 9.575 0 01-2.039 1.604c-1.355.793-7.395 4.266-8.137 4.699-1.352.788-2.897 1.225-4.633 1.314-2.689.132-5.902-.877-9.636-3.034-3.735-2.156-6.947-4.855-9.636-8.093a47.807 47.807 0 01-6.685-10.645 55.86 55.86 0 01-3.958-12.232 78.084 78.084 0 01-1.533-12.788c-.1-3.355-.148-6.833-.148-10.437 0-3.604.048-6.947.148-10.033.15-4.053.66-7.727 1.533-11.018.869-3.293 2.191-5.847 3.958-7.662a9.657 9.657 0 012.023-1.597c1.359-.799 7.48-4.329 8.265-4.772 1.326-.748 2.832-1.164 4.521-1.247 2.689-.133 5.901.877 9.636 3.033 3.734 2.156 6.947 4.856 9.636 8.094A47.677 47.677 0 0124.026-4.62a55.925 55.925 0 013.958 12.233A78.261 78.261 0 0129.516 20.4a333.9 333.9 0 01.149 10.205c0 3.603-.051 7.023-.149 10.266"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(213.326 266.228)"
        />
        <g clipPath="url(#ab)" opacity={0.149994}>
          <path
            d="M0 0c-1.35.77-6.78 3.9-8.05 4.64-.73.43-1.4.97-2.02 1.6-1.77 1.82-3.09 4.37-3.96 7.66-.87 3.29-1.38 6.97-1.53 11.02-.1 3.09-.15 6.43-.15 10.03 0 3.61.05 7.09.15 10.44.15 4.23.66 8.49 1.53 12.79.61 3.03 1.45 5.94 2.52 8.75l8.11-4.7c-1.06-2.8-1.89-5.72-2.5-8.74-.88-4.3-1.39-8.56-1.54-12.79-.1-3.35-.15-6.83-.15-10.44 0-3.6.05-6.94.15-10.03.15-4.05.66-7.73 1.54-11.02.86-3.29 2.19-5.85 3.95-7.66C-1.36.94-.7.42 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(206.657 241.213)"
          />
        </g>
        <g clipPath="url(#ac)" opacity={0.399994}>
          <path
            d="M0 0c-1.28.68-2.73 1.06-4.34 1.15-2.69.13-5.9-.88-9.64-3.03-3.73-2.16-6.94-4.86-9.63-8.1a47.694 47.694 0 01-6.69-10.64c-.27-.58-.52-1.17-.77-1.77-.23-.57-.45-1.14-.67-1.72h-.01l-8.11 4.7c.19.51.39 1.02.6 1.52.27.66.54 1.31.84 1.96 1.77 3.86 4 7.4 6.68 10.65 2.69 3.23 5.91 5.93 9.64 8.09 3.73 2.16 6.95 3.16 9.64 3.03 1.73-.09 3.28-.52 4.63-1.31C-7.13 4.12-1.79 1.05 0 0"
            fill="#fff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(235.007 327.553)"
          />
        </g>
        <g clipPath="url(#ad)" opacity={0.300003}>
          <path
            d="M0 0c-.05-1.94-.22-3.8-.49-5.57-.27-1.77-.72-3.42-1.34-4.97-.27-.67-.58-1.32-.93-1.94l-8.12 4.69c.34.62.65 1.27.92 1.94.62 1.55 1.07 3.2 1.35 4.97.27 1.77.43 3.63.48 5.57.2 6.63.2 13.07 0 19.32-.07 1.35-.19 2.59-.35 3.73 0 0 .21.15.48.31 1.49.89 2.74 1.23 3.74 1.12.99-.12 1.79-.6 2.39-1.44.6-.84 1.03-1.99 1.31-3.44.27-1.45.46-3.11.56-4.97C.19 13.07.19 6.63 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(229.027 279.223)"
          />
        </g>
        <g clipPath="url(#ae)" opacity={0.399994}>
          <path
            d="M0 0c-.44-.81-.94-1.57-1.5-2.28-1-1.27-2.24-2.33-3.74-3.19-1.49-.87-2.73-1.24-3.73-1.12-1 .11-1.81.58-2.43 1.41-.62.83-1.07 1.97-1.34 3.42-.08.4-.14.82-.2 1.25 1.3.81 2.42 1.78 3.31 2.92.56.71 1.07 1.47 1.51 2.28z"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(226.267 266.743)"
          />
        </g>
        <path
          d="M0 0a314.2 314.2 0 010 19.32c-.1 1.858-.287 3.514-.56 4.967-.274 1.451-.711 2.598-1.307 3.443-.598.842-1.396 1.323-2.391 1.438-.996.115-2.24-.26-3.734-1.121-1.493-.863-2.738-1.926-3.735-3.192a16.344 16.344 0 01-2.39-4.197 25.234 25.234 0 01-1.345-4.974 39.76 39.76 0 01-.522-5.592c-.25-6.508-.25-12.949 0-19.321.048-1.888.212-3.556.485-5.009.272-1.451.721-2.594 1.345-3.42.623-.83 1.431-1.303 2.427-1.416.997-.115 2.242.258 3.735 1.121 1.494.861 2.738 1.926 3.734 3.191a17.055 17.055 0 012.428 4.219A22.596 22.596 0 01-.485-5.57 44.25 44.25 0 010 0m-7.992 46.447c3.734 2.157 6.947 3.166 9.637 3.033 2.687-.136 4.917-1.111 6.684-2.925 1.766-1.819 3.087-4.371 3.958-7.662.871-3.293 1.382-6.965 1.532-11.018.098-3.242.149-6.662.149-10.266 0-3.603-.051-7.005-.149-10.205a78.275 78.275 0 00-1.532-12.787c-.871-4.299-2.192-8.379-3.958-12.232A47.68 47.68 0 001.645-28.26c-2.69-3.238-5.903-5.937-9.637-8.094-3.735-2.156-6.946-3.166-9.636-3.033-2.688.133-4.917 1.11-6.684 2.926-1.768 1.815-3.089 4.369-3.959 7.662-.873 3.291-1.382 6.965-1.533 11.018a314.295 314.295 0 00-.148 10.033c0 3.603.049 7.082.148 10.437.151 4.227.66 8.489 1.533 12.788a55.862 55.862 0 003.959 12.232 47.803 47.803 0 006.684 10.645c2.69 3.238 5.901 5.937 9.636 8.093"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(229.023 279.224)"
        />
        <path
          d="M0 0c-.081.077-.165.14-.252.189-.343.191-7.592 4.38-7.592 4.38v35.982c0 .842-.199 1.455-.596 1.84a1.328 1.328 0 01-.242.183c-.344.199-8.032 4.636-8.111 4.683-.348.207-.745.182-1.191-.074l-11.279-6.512c-.647-.375-1.158-.805-1.533-1.287-.371-.485-.783-1.278-1.23-2.379l-22.335-56.25c-.647-1.678-.971-3.512-.971-5.506V-36.94c0-.844.198-1.457.597-1.84a1.29 1.29 0 01.253-.192c.342-.192 8.029-4.635 8.113-4.683.344-.2.736-.172 1.178.083l15.406 8.895v-2.349c0-.844.198-1.457.597-1.84.077-.075.158-.137.241-.186l8.12-4.688c.345-.201.739-.174 1.182.082l9.785 5.65c.547.315 1.02.895 1.42 1.738.397.844.596 1.686.596 2.532v11.728l6.424 3.709c.547.317 1.021.895 1.42 1.74.397.842.597 1.686.597 2.53v12.189C.597-.998.397-.387 0 0"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(300.157 322.02)"
        />
        <g clipPath="url(#af)" opacity={0.149994}>
          <path
            d="M0 0l-.04-.14c-.35-.49-.73-1.24-1.14-2.25l-22.33-56.25c-.29-.74-.51-1.51-.66-2.31l-8.18 4.72c.161.79.37 1.55.65 2.28L-9.37 2.3c.45 1.1.86 1.9 1.24 2.38 0 0 0 .01.01.01z"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(277.432 356.724)"
          />
        </g>
        <g clipPath="url(#ag)" opacity={0.300003}>
          <path
            d="M0 0c-.921.53-7.691 4.44-8.021 4.62-.08.05-.17.12-.25.2-.399.38-.6.99-.6 1.84v12.18c0 1.12.121 2.18.321 3.2l8.119-4.69c-.21-1.01-.309-2.08-.309-3.2V1.96c0-.84.2-1.45.59-1.83C-.101.08-.051.04 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(253.692 278.424)"
          />
        </g>
        <g clipPath="url(#ah)" opacity={0.300003}>
          <path
            d="M0 0v-21.16l-6.84 3.95z"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(278.872 331.804)"
          />
        </g>
        <g clipPath="url(#ai)" opacity={0.399994}>
          <path
            d="M0 0l-10.91-6.29 4.07 10.24z"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(278.872 310.644)"
          />
        </g>
        <g clipPath="url(#aj)" opacity={0.300003}>
          <path
            d="M0 0c.05-.04.09-.08.14-.12-.91.52-7.7 4.44-8.02 4.62-.09.05-.17.11-.24.19-.4.38-.6 1-.6 1.84v2.35l8.12 4.69V1.84C-.6 1-.399.38 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(279.092 278.463)"
          />
        </g>
        <g clipPath="url(#ak)" opacity={0.149994}>
          <path
            d="M0 0c-.319.14-.68.1-1.069-.13L-7.5-3.84v8.17S-.89.52 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(299.812 322.253)"
          />
        </g>
        <g clipPath="url(#al)" opacity={0.399994}>
          <path
            d="M0 0c-.33.17-.71.13-1.13-.11L-12.4-6.62c-.65-.38-1.15-.8-1.53-1.28l-8.12 4.69c.37.48.88.9 1.52 1.28l11.28 6.51c.45.26.84.28 1.189.07C-7.979 4.61-.76.44 0 0"
            fill="#fff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(291.422 364.623)"
          />
        </g>
        <path
          d="M0 0v21.158L-10.905-6.297zm1.645-32.287c-.549-.317-1.023-.283-1.42.101-.399.383-.597.997-.597 1.84v11.731l-23.53-13.586c-.548-.317-1.02-.283-1.419.101-.4.383-.597.996-.597 1.84v12.19c0 1.994.323 3.828.971 5.506l22.334 56.25c.448 1.101.859 1.894 1.231 2.378.374.483.885.913 1.532 1.288l11.279 6.511c.547.315 1.02.282 1.42-.101.397-.385.596-.998.596-1.84V7.762l6.424 3.709c.547.316 1.021.281 1.42-.1.398-.387.597-.998.597-1.842V-2.66c0-.844-.199-1.688-.597-2.529-.399-.846-.873-1.424-1.42-1.741l-6.424-3.709v-11.728c0-.846-.199-1.688-.596-2.531-.4-.844-.873-1.424-1.42-1.739z"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(278.868 310.648)"
        />
        <path
          d="M0 0c.125.072.231.064.322-.021.09-.087.135-.227.135-.418v-2.76a1.34 1.34 0 00-.135-.575C.231-3.964.125-4.095 0-4.166l-8.019-4.631c-.124-.071-.231-.063-.321.023-.091.086-.136.225-.136.417V8.469c0 .191.045.382.136.573.09.191.197.322.321.394l7.884 4.552c.124.072.231.064.322-.023.09-.087.135-.227.135-.416v-2.762c0-.191-.045-.382-.135-.572-.091-.192-.198-.322-.322-.395l-5.211-3.008V3.999l4.839 2.793c.124.072.231.064.321-.022.091-.087.136-.228.136-.416V3.592a1.31 1.31 0 00-.136-.572c-.09-.192-.197-.323-.321-.395L-5.346-.168v-2.918z"
          fill="#375a64"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(201.221 207.567)"
        />
        <path
          d="M0 0l1.911 1.104c.474.273.866.638 1.177 1.094.309.457.465 1.051.465 1.78 0 .73-.156 1.144-.465 1.242-.311.099-.703.011-1.177-.262L0 3.854zm1.911 8.995c.744.43 1.41.693 1.997.789.586.095 1.085.017 1.497-.23.412-.249.728-.688.948-1.315.219-.629.33-1.447.33-2.455 0-1.301-.176-2.483-.525-3.545A9.233 9.233 0 004.669-.587l2.098-4.988a.678.678 0 00.05-.256 1.1 1.1 0 00-.109-.469c-.074-.154-.161-.262-.263-.32L4.146-7.949c-.227-.129-.387-.144-.483-.042a1.149 1.149 0 00-.228.336L1.557-3.166 0-4.063v-5.575c0-.191-.046-.383-.135-.573-.091-.19-.198-.322-.322-.393l-2.216-1.28c-.125-.072-.231-.065-.321.022-.091.087-.136.226-.136.417V5.382c0 .189.045.381.136.572.09.192.196.322.321.395z"
          fill="#375a64"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(207.176 217.18)"
        />
        <path
          d="M0 0l1.911 1.104c.474.273.866.638 1.176 1.095.309.457.466 1.051.466 1.78 0 .729-.157 1.143-.466 1.242-.31.099-.702.011-1.176-.262L0 3.855zm1.911 8.996c.744.43 1.409.692 1.996.788.587.095 1.086.018 1.497-.229.412-.248.728-.688.948-1.316.219-.629.33-1.447.33-2.454 0-1.302-.175-2.484-.525-3.545A9.231 9.231 0 004.669-.586l2.098-4.988a.703.703 0 00.05-.257c0-.156-.037-.312-.11-.468-.073-.154-.161-.262-.263-.32L4.145-7.948c-.226-.13-.387-.144-.483-.043-.096.1-.172.213-.228.337L1.556-3.165 0-4.062v-5.575a1.34 1.34 0 00-.136-.574c-.091-.191-.198-.322-.321-.393l-2.217-1.28c-.124-.071-.23-.064-.321.023-.091.086-.136.225-.136.417V5.381c0 .191.045.383.136.574.091.191.197.322.321.393z"
          fill="#375a64"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(219.358 224.212)"
        />
        <path
          d="M0 0l.034 1.062a41.625 41.625 0 010 2.33L0 4.376a4.505 4.505 0 01-.178 1.095c-.096.309-.231.548-.406.716a.942.942 0 01-.651.262c-.259.006-.564-.092-.914-.293a3.49 3.49 0 01-.914-.761 4.665 4.665 0 01-.651-1.014 5.735 5.735 0 01-.405-1.186 7.374 7.374 0 01-.178-1.3A17.759 17.759 0 01-4.34.867a73.753 73.753 0 010-2.33c.006-.381.02-.719.043-1.017.045-.845.239-1.452.583-1.827.345-.374.865-.36 1.565.045.7.404 1.218.99 1.557 1.758C-.254-1.735-.057-.902 0 0m-7.428.218c.034.99.172 1.987.415 2.987.242 1 .587 1.944 1.032 2.835.446.892.987 1.708 1.625 2.448a8.475 8.475 0 002.207 1.836c.824.475 1.557.712 2.2.709.642-.002 1.186-.191 1.632-.568.446-.377.789-.924 1.032-1.644.242-.719.381-1.556.414-2.509a74.39 74.39 0 00.034-2.325c0-.816-.011-1.587-.034-2.312a14.777 14.777 0 00-.414-2.987A13.661 13.661 0 001.683-4.16 11.413 11.413 0 00.051-6.613a8.633 8.633 0 00-2.2-1.817c-.834-.482-1.571-.724-2.207-.728-.638-.002-1.179.187-1.625.573-.445.385-.79.939-1.032 1.657-.243.721-.381 1.556-.415 2.509a81.822 81.822 0 000 4.637"
          fill="#375a64"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(235.446 230.089)"
        />
        <path
          d="M0 0l1.911 1.104c.474.273.866.638 1.177 1.094.309.457.465 1.051.465 1.78 0 .73-.156 1.144-.465 1.242-.311.099-.703.011-1.177-.262L0 3.854zm1.911 8.995c.744.43 1.41.693 1.997.789.586.095 1.085.018 1.497-.23.411-.248.727-.688.947-1.315.22-.629.33-1.447.33-2.455 0-1.301-.175-2.483-.525-3.545A9.21 9.21 0 004.669-.587l2.098-4.988a.698.698 0 00.05-.256c0-.157-.036-.313-.11-.469-.073-.155-.16-.262-.262-.32l-2.3-1.328c-.226-.13-.387-.145-.482-.043a1.149 1.149 0 00-.228.336l-1.879 4.49L0-4.063v-5.575a1.33 1.33 0 00-.136-.573c-.09-.19-.197-.323-.321-.393l-2.217-1.28c-.124-.072-.23-.064-.321.021-.091.088-.135.227-.135.419V5.382c0 .189.044.38.135.572.091.192.197.322.321.395z"
          fill="#375a64"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(244.108 238.502)"
        />
        <path
          d="M0 0l2.01 1.16c.493.284.886.66 1.179 1.129.294.469.441 1.076.441 1.822 0 .744-.147 1.176-.441 1.292-.293.117-.686.033-1.179-.253L0 3.99zm2.075 7.684c1.045.603 1.859.693 2.438.269.578-.424.868-1.367.868-2.832 0-1.463-.29-2.749-.868-3.854C3.934.16 3.12-.694 2.075-1.298L0-2.496v-4.451c0-.147-.034-.293-.104-.439-.068-.147-.15-.247-.246-.301l-1.049-.607c-.096-.056-.178-.05-.247.018-.07.065-.103.172-.103.319V4.937c0 .146.033.291.103.439a.654.654 0 00.247.302z"
          fill="#e1e1e1"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(168.638 171.082)"
        />
        <path
          d="M0 0l-1.426 4.865-1.427-6.511zm-.713 8.351c.138.079.241.083.311.009.069-.072.13-.208.181-.404l3.176-10.78a.553.553 0 00.013-.133 1.01 1.01 0 00-.103-.438c-.069-.148-.151-.248-.246-.302l-.933-.539c-.174-.1-.295-.114-.364-.039a.578.578 0 00-.142.246L.622-2.135-3.475-4.5l-.557-2.538a2.047 2.047 0 00-.143-.411.833.833 0 00-.363-.38l-.934-.54c-.094-.054-.176-.049-.246.019-.069.065-.103.172-.103.319 0 .052.004.101.013.147l3.176 14.448c.052.256.112.461.182.614a.744.744 0 00.31.349z"
          fill="#e1e1e1"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(180.19 175.475)"
        />
        <path
          d="M0 0c.018.808.112 1.591.286 2.351.173.757.423 1.467.752 2.13.328.661.733 1.26 1.212 1.797a6.754 6.754 0 001.679 1.36c.752.433 1.372.64 1.86.625.488-.019.877-.154 1.166-.406a2.01 2.01 0 00.61-.944c.117-.379.184-.717.202-1.012a.862.862 0 00-.098-.446.697.697 0 00-.253-.296l-1.05-.605c-.095-.056-.171-.063-.226-.022a.35.35 0 00-.124.158 3.815 3.815 0 01-.136.271.955.955 0 01-.351.356c-.159.102-.369.153-.628.157-.259.003-.584-.108-.972-.332-.648-.373-1.166-.943-1.556-1.707A5.847 5.847 0 011.751.91a47.271 47.271 0 01-.026-1.641c0-.579.008-1.118.026-1.612.026-1.09.233-1.771.622-2.046.39-.274.908-.225 1.556.15.319.183.614.408.882.669.267.26.499.567.693.919.194.352.346.755.453 1.21.108.455.163.968.163 1.541v.16L4.538-.653c-.096-.055-.178-.05-.247.016-.069.067-.103.173-.103.32v1.418c0 .145.034.292.103.438a.654.654 0 00.247.302L7.52 3.562c.095.055.177.05.247-.016.068-.067.103-.174.103-.32V1.03c0-.799-.082-1.581-.246-2.347a9.434 9.434 0 00-.739-2.163 8.11 8.11 0 00-1.232-1.849 6.823 6.823 0 00-1.724-1.404c-.658-.38-1.228-.577-1.711-.59-.485-.014-.887.127-1.206.422-.32.295-.565.728-.733 1.303C.111-5.022.018-4.341 0-3.554a52.415 52.415 0 00-.025 1.762A57.428 57.428 0 000 0"
          fill="#e1e1e1"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(184.157 181.358)"
        />
        <path
          d="M0 0c.095.055.177.048.246-.019.069-.066.105-.172.105-.319v-1.416a1.02 1.02 0 00-.105-.439A.652.652 0 000-2.496l-5.69-3.285c-.096-.055-.178-.05-.247.017-.07.065-.104.173-.104.32V7.449c0 .147.034.292.104.44a.651.651 0 00.247.301l5.586 3.226c.096.055.178.05.248-.017.068-.067.102-.173.102-.32V9.662c0-.146-.034-.293-.102-.438a.665.665 0 00-.248-.303l-4.187-2.417V3.33l3.902 2.254c.095.055.177.048.246-.019.07-.066.105-.172.105-.319V3.83c0-.148-.035-.294-.105-.44a.654.654 0 00-.246-.302L-4.291.836v-3.315z"
          fill="#e1e1e1"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(200.025 184.213)"
        />
        <path
          d="M0 0c-.147-.085-.26-.09-.337-.015a1.94 1.94 0 00-.169.182l-3.63 6.208v-8.224a1.01 1.01 0 00-.103-.438.652.652 0 00-.246-.303l-1.05-.605c-.096-.056-.178-.051-.247.017-.07.065-.104.172-.104.319v12.894c0 .147.034.291.104.44.069.146.151.246.247.3l.907.525c.147.085.259.09.337.014.078-.074.134-.135.168-.181l3.631-6.207v8.222c0 .147.034.292.103.44.07.146.152.246.246.301l1.05.607c.095.055.177.049.246-.017.07-.068.105-.174.105-.321V1.266c0-.147-.035-.295-.105-.44A.657.657 0 00.907.523z"
          fill="#e1e1e1"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(211.472 188.326)"
        />
        <path
          d="M0 0c-.034.938-.236 1.563-.603 1.878-.367.314-.853.296-1.458-.053C-2.667 1.476-3.152.932-3.52.193c-.367-.737-.568-1.594-.603-2.573a52.008 52.008 0 01-.025-1.701c0-.619.008-1.176.025-1.672.035-.938.236-1.56.603-1.867.368-.308.853-.288 1.459.062.605.35 1.091.891 1.458 1.621.367.731.569 1.586.603 2.564.017.515.025 1.083.025 1.701C.025-1.053.017-.496 0 0m-5.873-3.29c.025.76.119 1.515.278 2.266.16.751.397 1.459.707 2.125a8.374 8.374 0 001.167 1.831 6.197 6.197 0 001.66 1.387c.63.365 1.179.541 1.646.532.467-.01.855-.172 1.167-.485.311-.312.549-.747.713-1.304.164-.558.26-1.208.285-1.952.009-.248.015-.523.02-.827.003-.304.006-.618.006-.944a46.26 46.26 0 00-.026-1.801 12.897 12.897 0 00-.278-2.267 9.913 9.913 0 00-.707-2.125A8.123 8.123 0 00-.408-8.679a6.353 6.353 0 00-1.653-1.373c-.64-.369-1.193-.549-1.66-.539-.466.01-.855.168-1.167.475-.31.304-.547.741-.707 1.308a8.253 8.253 0 00-.278 1.945 42.312 42.312 0 00-.025 1.77 46.26 46.26 0 00.025 1.803"
          fill="#e1e1e1"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(220.638 202.28)"
        />
        <path
          d="M0 0c.095.055.177.049.246-.018.069-.066.105-.172.105-.319v-1.417c0-.146-.036-.293-.105-.439A.654.654 0 000-2.495l-2.333-1.347v-10.937a1.01 1.01 0 00-.105-.44c-.069-.147-.151-.248-.246-.302l-1.049-.606c-.095-.055-.177-.05-.246.018-.07.065-.105.171-.105.318v10.937L-6.417-6.2c-.095-.055-.177-.048-.246.018-.069.067-.105.172-.105.319v1.417c0 .146.036.292.105.44a.65.65 0 00.246.301z"
          fill="#e1e1e1"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(230.283 213.158)"
        />
        <path
          d="M0 0c.095.055.177.05.246-.017s.105-.172.105-.319v-1.418a1.02 1.02 0 00-.105-.438A.663.663 0 000-2.495l-4.136-2.388v-3.871L-.26-6.517c.096.056.178.05.247-.016.07-.069.104-.174.104-.321v-1.417c0-.146-.034-.292-.104-.438-.069-.146-.151-.248-.247-.303l-3.876-2.238v-4.57c0-.147-.034-.293-.103-.439a.654.654 0 00-.246-.302l-1.05-.606c-.096-.056-.178-.05-.247.018-.07.065-.103.172-.103.319v12.893c0 .146.033.292.103.44a.654.654 0 00.247.302z"
          fill="#e1e1e1"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(241.095 219.4)"
        />
        <path
          d="M0 0c-.035.939-.236 1.564-.604 1.877-.367.314-.853.297-1.458-.053-.605-.349-1.091-.892-1.459-1.631-.367-.737-.568-1.594-.602-2.574a49.304 49.304 0 01-.025-1.701c0-.617.007-1.176.025-1.672.034-.937.235-1.559.602-1.866.368-.308.854-.287 1.459.063.605.349 1.091.889 1.458 1.621.368.731.569 1.584.604 2.563.017.516.025 1.083.025 1.701C.025-1.053.017-.496 0 0m-5.873-3.29c.025.76.118 1.515.278 2.267.16.75.396 1.458.707 2.125a8.401 8.401 0 001.166 1.83 6.234 6.234 0 001.66 1.388c.63.364 1.179.541 1.646.53.468-.01.856-.17 1.168-.483.31-.312.549-.748.713-1.305.164-.558.26-1.208.285-1.951.008-.248.015-.523.019-.828a71.624 71.624 0 00-.019-2.746 12.97 12.97 0 00-.278-2.266 9.969 9.969 0 00-.707-2.125A8.063 8.063 0 00-.409-8.678a6.298 6.298 0 00-1.653-1.373c-.639-.37-1.193-.55-1.66-.54-.465.011-.854.169-1.166.474-.311.306-.547.742-.707 1.308a8.218 8.218 0 00-.278 1.946c-.01.248-.016.523-.02.828a94.185 94.185 0 000 1.894c.004.309.01.592.02.851"
          fill="#e1e1e1"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(248.732 218.5)"
        />
        <path
          d="M0 0c.095.055.177.048.246-.018.069-.067.105-.173.105-.32v-8.183c0-.825-.079-1.616-.235-2.37a9.296 9.296 0 00-.7-2.121 7.55 7.55 0 00-1.167-1.781 6.406 6.406 0 00-1.62-1.324c-.622-.36-1.165-.544-1.627-.551-.462-.008-.85.139-1.16.438-.312.298-.543.738-.695 1.316-.151.577-.226 1.279-.226 2.103v8.184c0 .147.035.293.104.44a.66.66 0 00.246.302l1.05.606c.095.054.177.05.246-.017.07-.067.104-.174.104-.32v-8.083c0-.546.048-.985.143-1.315.094-.332.229-.57.402-.716a.786.786 0 01.622-.18c.242.026.506.122.791.287a3.621 3.621 0 011.426 1.531c.174.347.309.742.408 1.185.1.443.149.937.149 1.483v8.083c0 .147.034.292.104.441a.648.648 0 00.246.3z"
          fill="#e1e1e1"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(259.531 230.045)"
        />
        <path
          d="M0 0c-.147-.085-.26-.09-.337-.015a1.698 1.698 0 00-.169.182l-3.63 6.208v-8.224c0-.146-.035-.294-.104-.438a.652.652 0 00-.246-.303l-1.049-.605c-.096-.056-.178-.051-.247.017-.07.065-.104.172-.104.319v12.894c0 .147.034.291.104.44.069.146.151.246.247.3l.907.525c.147.085.259.09.337.014.077-.074.133-.136.168-.182l3.631-6.206v8.222c0 .147.033.292.102.44.07.146.152.246.246.301l1.051.607c.095.055.177.049.246-.018.07-.067.104-.173.104-.32V1.266a1.01 1.01 0 00-.104-.44.652.652 0 00-.246-.303z"
          fill="#e1e1e1"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(268.049 220.99)"
        />
        <path
          d="M0 0a4.676 4.676 0 01-.162 1.093c-.091.314-.225.555-.401.726a.92.92 0 01-.668.253c-.268-.001-.588-.109-.96-.324L-3.928.745v-8.981l1.802 1.041c.355.204.661.457.92.759.26.303.476.637.649 1.004.172.366.304.761.395 1.186.091.425.144.855.162 1.291A41.59 41.59 0 010 0m-2.126 4.281c.64.369 1.195.56 1.666.573.471.011.863-.13 1.18-.427.314-.297.557-.736.725-1.318.169-.582.271-1.281.305-2.099.035-.965.035-1.95 0-2.954a14.146 14.146 0 00-.305-2.452 9.937 9.937 0 00-.719-2.151A7.506 7.506 0 00-.435-8.324a6.368 6.368 0 00-1.627-1.33l-3.266-1.885c-.095-.056-.177-.051-.246.018-.071.064-.104.171-.104.318V1.691c0 .147.033.291.104.44a.656.656 0 00.246.301z"
          fill="#e1e1e1"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(277.33 234.813)"
        />
      </g>
      <g clipPath="url(#am)" transform="matrix(1.33333 0 0 -1.33333 0 666.667)">
        <path
          d="M0 0l-50.22-28.998c-.714-.412-1.293-1.415-1.293-2.239V-95.44c0-.825.582-1.822 1.3-2.227l4.633-2.597c.719-.406 1.88-.4 2.594.013L7.233-71.254c.715.412 1.293 1.415 1.293 2.239v64.203c0 .825-.582 1.822-1.299 2.228L2.594.013C1.875.418.714.412 0 0"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(421.335 451.288)"
        />
        <path
          d="M0 0v64.203c0 .825.579 1.828 1.293 2.24l50.22 28.997c.715.413 1.293.078 1.293-.747V30.491c0-.825-.578-1.828-1.293-2.24L1.293-.746C.579-1.159 0-.825 0 0"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(377.056 351.782)"
        />
        <g clipPath="url(#an)" opacity={0.199997}>
          <path
            d="M0 0c-.688-.399-1.945-.414-2.596-.016l-4.63 2.602c-.716.401-1.302 1.398-1.302 2.223v64.206c0 .43.162.902.412 1.319l7.205-4.112c-.239-.401-.382-.861-.382-1.273V.749C-1.293-.006-.762-.441 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(378.349 351.036)"
          />
        </g>
        <g clipPath="url(#ao)" opacity={0.399994}>
          <path
            d="M0 0c-.102.651-.633.884-1.278.514l-50.215-28.999c-.359-.209-.682-.562-.914-.968l-7.211 4.113c.233.388.544.723.89.92L-8.507 4.579c.711.412 1.869.418 2.592.012l4.631-2.596C-.633 1.625-.09.771 0 0"
            fill="#fff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(429.845 446.71)"
          />
        </g>
        <path
          d="M0 0l-10.405-10.585c-.556-.564-.363-1.405.196-1.769.214-.14 7.203-4.144 7.203-4.144L7.235-4.167z"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(369.82 400.701)"
        />
        <g clipPath="url(#ap)" opacity={0.100006}>
          <path
            d="M0 0l-10.405-10.585c-.556-.564-.363-1.405.196-1.769.214-.14 7.203-4.144 7.203-4.144L7.235-4.167z"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(369.82 400.701)"
          />
        </g>
        <path
          d="M0 0l-10.406-10.585c-.768-.781-.105-2.091.98-1.935L0-11.16C1.012-11.16 1.012 0 0 0"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(377.056 396.534)"
        />
        <path
          d="M0 0l7.463-7.181"
          fill="none"
          stroke="#375a64"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeMiterlimit={10}
          strokeDasharray="none"
          strokeOpacity={1}
          transform="translate(387.541 407.147)"
        />
        <path
          d="M0 0l7.463 15.797"
          fill="none"
          stroke="#375a64"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeMiterlimit={10}
          strokeDasharray="none"
          strokeOpacity={1}
          transform="translate(387.541 395.657)"
        />
        <path
          d="M0 0l7.463-7.181"
          fill="none"
          stroke="#375a64"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeMiterlimit={10}
          strokeDasharray="none"
          strokeOpacity={1}
          transform="translate(411.913 421.218)"
        />
        <path
          d="M0 0l7.463 15.797"
          fill="none"
          stroke="#375a64"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeMiterlimit={10}
          strokeDasharray="none"
          strokeOpacity={1}
          transform="translate(411.913 409.728)"
        />
        <path
          d="M0 0l32.496 18.762"
          fill="none"
          stroke="#375a64"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeMiterlimit={10}
          strokeDasharray="none"
          strokeOpacity={1}
          transform="translate(387.21 377.759)"
        />
        <path
          d="M0 0v-9.072c0-2.761 1.818-3.95 4.062-2.655 2.244 1.296 4.062 4.584 4.062 7.346V4.69"
          fill="none"
          stroke="#375a64"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeMiterlimit={10}
          strokeDasharray="none"
          strokeOpacity={1}
          transform="translate(407.52 389.485)"
        />
        <path
          d="M0 0v-8.758"
          fill="none"
          stroke="#375a64"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeMiterlimit={10}
          strokeDasharray="none"
          strokeOpacity={1}
          transform="translate(411.583 391.83)"
        />
      </g>
      <g clipPath="url(#aq)" transform="matrix(1.33333 0 0 -1.33333 0 666.667)">
        <path
          d="M0 0c.049 1.529-.401 10.001-.875 17.802-.498 8.209-.491 15.726-.491 15.726l13.881-10.369C9.496 4.721 9.2.653 9.156-2.362l-.344-.28c.024-.069.086-.325.084-.771 0-3.58-4.032-5.526-7.174-7.924-1.243-.948-4.852-3.745-8.503-5.597-2.285-1.159-6.936-1.282-7.517.189 4.422 2.766 9.704 6.282 12.742 9.638C-.105-5.505-.101-3.138 0 0"
          fill="#ffaaa8"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(381.288 120.396)"
        />
        <path
          d="M0 0c-.197-.01-.335-1.978.775-2.847 1.282-1.004 4.918-2.664 9.665-1.82 4.748.844 7.27 2.319 9.106 4.009 1.836 1.69 4.408 4.073 6.546 4.652 1.986.537 4.123 1.549 4.736 2.2.612.651.394 3.405.394 3.405z"
          fill="#1a2e35"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(361.583 99.103)"
        />
        <path
          d="M0 0s.518-.066.672-.699c.182-.753.446-2.956.82-4.189.521-1.713.983-5.253.786-6.679-.196-1.426-3.422-2.562-5.474-3.457-2.098-.915-4.489-2.708-6.573-4.599-2.156-1.957-6.787-4.105-9.901-4.23-3.685-.147-7.889.85-8.92 2.513-1.275 2.058-.412 3.325 4.93 6.115 1.012.528 4.542 2.849 5.743 3.686 3.249 2.267 6.428 5.357 7.792 9.16.159.444.325.937.731 1.178.311.185.697.179 1.057.146a9.064 9.064 0 002.666-.668c.75-.315 1.809-.673 1.838-1.612.01-.327-.07-.65-.161-.964-.057-.195-.096-.452.077-.557.164-.1.368.032.505.166.547.54.935 1.216 1.389 1.837.242.332.508.653.822.921.285.245.657.377.907.651.316.346.3.84.294 1.281"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(390.48 119.315)"
        />
        <g clipPath="url(#ar)" opacity={0.300003}>
          <path
            d="M0 0s.518-.066.672-.699c.182-.753.446-2.956.82-4.189.521-1.713.983-5.253.786-6.679-.196-1.426-3.422-2.562-5.474-3.457-2.098-.915-4.489-2.708-6.573-4.599-2.156-1.957-6.787-4.105-9.901-4.23-3.685-.147-7.889.85-8.92 2.513-1.275 2.058-.412 3.325 4.93 6.115 1.012.528 4.542 2.849 5.743 3.686 3.249 2.267 6.428 5.357 7.792 9.16.159.444.325.937.731 1.178.311.185.697.179 1.057.146a9.064 9.064 0 002.666-.668c.75-.315 1.809-.673 1.838-1.612.01-.327-.07-.65-.161-.964-.057-.195-.096-.452.077-.557.164-.1.368.032.505.166.547.54.935 1.216 1.389 1.837.242.332.508.653.822.921.285.245.657.377.907.651.316.346.3.84.294 1.281"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(390.48 119.315)"
          />
        </g>
        <g clipPath="url(#as)" opacity={0.199997}>
          <path
            d="M0 0c-3.333-1.519-1.854-2.084-.896-2.175.957-.091 6.516 3.517 9.872 6.441 3.501 3.049 5.645 6.302 6.946 7.422.485.418 1.004.928 1.465 1.403-.124.743-.229 1.421-.315 1.775-.154.633-.672.699-.672.699.006-.441.023-.935-.295-1.281-.249-.274-.621-.407-.906-.651a5.332 5.332 0 01-.822-.921c-.453-.621-.842-1.297-1.39-1.837C10.199 6.971 3.331 1.519 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(374.08 103.75)"
          />
        </g>
        <path
          d="M0 0a.335.335 0 01.234.561C-.78 1.69-3.617 3.113-6.103 2.721a.336.336 0 01-.281-.384c.029-.183.182-.305.384-.281 2.322.361 4.931-1.051 5.734-1.945A.336.336 0 010 0"
          fill="#ececec"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(373.401 102.085)"
        />
        <path
          d="M0 0a.337.337 0 01.234.561C-.778 1.688-3.614 3.11-6.103 2.72a.337.337 0 11.104-.665c2.322.358 4.93-1.05 5.733-1.944A.336.336 0 010 0"
          fill="#ececec"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(376.277 104.049)"
        />
        <path
          d="M0 0a.337.337 0 01.234.561C-.779 1.689-3.617 3.115-6.103 2.72a.337.337 0 01.104-.665c2.322.361 4.931-1.05 5.733-1.944A.335.335 0 010 0"
          fill="#ececec"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(379.16 105.915)"
        />
        <path
          d="M0 0a.338.338 0 01.234.562C-.778 1.69-3.626 3.112-6.103 2.72a.336.336 0 11.104-.664c2.319.359 4.931-1.05 5.733-1.945A.336.336 0 010 0"
          fill="#ececec"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(381.812 108.017)"
        />
        <path
          d="M0 0a.335.335 0 01.237.557c-1.396 1.602-4.426 2.557-6.912 2.166a.336.336 0 11.104-.665c2.238.351 5.062-.523 6.301-1.943A.339.339 0 010 0"
          fill="#ececec"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(384.41 110.117)"
        />
        <path
          d="M0 0l15.18.332c-.188-10.134-1.755-21.389-1.983-30.898-.043-1.79.098-13.908-.563-17.593-.993-5.536-7.564-4.814-7.925-1.504-.361 3.309.357 17.459.33 18.918.035 6.099-2.861 16.381-4.401 27.322C.562-2.886.072-.524 0 0"
          fill="#ffaaa8"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(409.29 140.46)"
        />
        <path
          d="M0 0c-.125-3.252.17-5.445 1.134-7.453.964-2.007 3.071-2.812 5.317-2.469 2.246.343 5.197 1.556 6.095 4.285.898 2.729.957 4.653.749 7.7z"
          fill="#1a2e35"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(411.532 88.483)"
        />
        <path
          d="M0 0c.896-.159.743-4.526.843-8.924.107-4.714 1.312-6.776 1.441-10.645.148-4.421-.27-6.349-2.055-8.84-1.785-2.492-7.45-4.557-9.752-.519-1.878 3.293-1.637 6.719-1.017 11.058.641 4.486 1.128 7.639 1.274 11.076.14 3.285.221 6.475 1.041 6.763.014-.486.014-1.101.033-1.578.013-.367-.068-2.145.437-2.211.087.413.116.802.129 1.23.012.372.082.772.35 1.03.27.261.672.314 1.046.352.906.089 1.815.161 2.726.154.453-.003.907-.025 1.358-.073.366-.04.794-.113 1.129-.274.205-.1.388-.271.446-.491.044-.163.015-.335-.001-.503-.03-.302-.02-.607.026-.907.021-.132.095-.298.227-.28.107.016.162.143.173.25.054.527.024.916.059 1.531.017.284.008.675.031.96.024.28.047.56.056.841"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(422.52 111.056)"
        />
        <g clipPath="url(#at)" opacity={0.300003}>
          <path
            d="M0 0c.896-.159.743-4.526.843-8.924.107-4.714 1.312-6.776 1.441-10.645.148-4.421-.27-6.349-2.055-8.84-1.785-2.492-7.45-4.557-9.752-.519-1.878 3.293-1.637 6.719-1.017 11.058.641 4.486 1.128 7.639 1.274 11.076.14 3.285.221 6.475 1.041 6.763.014-.486.014-1.101.033-1.578.013-.367-.068-2.145.437-2.211.087.413.116.802.129 1.23.012.372.082.772.35 1.03.27.261.672.314 1.046.352.906.089 1.815.161 2.726.154.453-.003.907-.025 1.358-.073.366-.04.794-.113 1.129-.274.205-.1.388-.271.446-.491.044-.163.015-.335-.001-.503-.03-.302-.02-.607.026-.907.021-.132.095-.298.227-.28.107.016.162.143.173.25.054.527.024.916.059 1.531.017.284.008.675.031.96.024.28.047.56.056.841"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(422.52 111.056)"
          />
        </g>
        <g clipPath="url(#au)" opacity={0.199997}>
          <path
            d="M0 0c-.135-1.58 1.476-1.198 1.759 1.024.283 2.222-.426 7.627-.043 11.459-.1 4.398.054 8.766-.843 8.925-.002-.054-.008-.108-.011-.161l-.094-2.221c-.005-.323-.006-.61-.041-.95C.438 14.855.29 8.591.282 5.622.275 2.652.135 1.58 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(421.647 89.648)"
          />
        </g>
        <g clipPath="url(#av)" opacity={0.199997}>
          <path
            d="M0 0c-.146 1.579-.295 2.65-.324 5.62-.03 2.947-.223 9.136-.531 12.375-.169.56-.131 1.562-.141 1.827-.018.446-.019 1.008-.03 1.476-.003.033-.006.066-.007.099-.593-.218-.797-1.96-.918-4.154-.009-1.274.04-2.855.141-4.773.412-3.829-.263-9.27.037-11.49C-1.474-1.24.146-1.579 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(415.324 89.624)"
          />
        </g>
        <path
          d="M0 0c.042 0 .085.008.126.024.033.015 3.475 1.373 7.027.048a.337.337 0 01.235.631C3.587 2.121.023.709-.128.648A.335.335 0 01-.312.21.335.335 0 010 0"
          fill="#ececec"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(414.732 89.509)"
        />
        <path
          d="M0 0c.038 0 .076.006.114.02.038.014 3.892 1.385 7.869.05a.339.339 0 01.426.213.336.336 0 01-.212.426C3.99 2.118.05.712-.116.653A.337.337 0 010 0"
          fill="#ececec"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(414.31 93.007)"
        />
        <path
          d="M0 0a.337.337 0 01.138.644c-3.917 1.763-8.117.092-8.295.019a.336.336 0 01-.186-.438.341.341 0 01.439-.184c.04.015 4.12 1.635 7.763-.012A.35.35 0 010 0"
          fill="#ececec"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(422.389 96.352)"
        />
        <path
          d="M0 0a.337.337 0 01.143.641c-4.3 2.026-8.29.141-8.458.061a.337.337 0 01.293-.606c.037.018 3.875 1.821 7.876-.063A.334.334 0 010 0"
          fill="#ececec"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(422.453 99.442)"
        />
        <path
          d="M0 0a.337.337 0 01.146.639C-3.876 2.605-8.197.696-8.38.612A.336.336 0 01-8.102 0c.042.018 4.218 1.861 7.953.035A.33.33 0 010 0"
          fill="#ececec"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(422.512 102.915)"
        />
        <path
          d="M0 0c5.408-14.807 5.508-24.026 4.65-39.564a637.326 637.326 0 00-2.08-26.803c.225-2.528 3.758-7.449 4.254-18.621.465-10.445-.989-23.552-.989-23.552s-7.007-2.585-12.929-.055c0 0-6.165 26.722-7.863 39.793-1.483 11.406-3.653 24.883-3.653 24.883l-3.868-24.88s.992-6.686.604-11.377c-.503-6.074-3.468-22.41-3.468-22.41s-7.349-2.643-12.344 1.752c0 0-1.52 24.481-2.25 33.745-.977 12.404-.776 46.428 2.132 63.859z"
          fill="#375a64"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(418.099 239.032)"
        />
        <path
          d="M0 0l-2.65 20.607s-9.818 1.275-14.278 5.436c0 0 3.363-6.106 11.589-7.263L-1.718-.468l-2.149-24.411z"
          fill="#1a2e35"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(399.488 195.113)"
        />
        <path
          d="M0 0l.2.094c12.647 5.957 17.727 19.994 17.108 33.302-.197 4.212-.909 8.443-2.585 12.312-1.677 3.869-4.371 7.369-7.966 9.572-2.755 1.688-5.939 2.538-9.147 2.749-2.928.192-6.72.146-9.48-.986-1.2-.491-1.421-.383-2.713-.092a9.333 9.333 0 01-3.004.181c-2.782-.292-5.151-1.917-6.885-4.05-2.334-2.873-2.614-7.161-1.805-10.642.374-1.606 2.62-3.966 2.524-5.491-.14-2.224-1.755-3.96-3.535-5.912-1.712-1.877-3.757-3.368-4.614-6.198-.737-2.431-.871-7.582 4.567-9.556C-35.25 4.81-27.44-.865-23.123-2.372c2.72-.949 6.141-1.455 8.998-1.089C-9.322-2.847-4.425-2.06 0 0"
          fill="#2b454e"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(402.102 270.878)"
        />
        <path
          d="M0 0c4.651 4.79 13.069 15.634 13.069 15.634l4.759 16.486c.116-.046-2.427-.054-3.192-.008-5.142-.343-6.659-.781-9.858-4.283-2.54-2.78-12.232-16.019-15.601-20.47l-8.943 11.39c-2.141 3.243-1.795 6.868-2.907 9.513-1.112 2.645-2.909 3.813-4.348 6.206-1.439 2.392-1.783 3.95-2.964 2.731-1.183-1.218.181-4.52.359-5.27.177-.751 1.978-2.987-1.031-1.826s-7.663 6.67-8.864 7.794c-1.203 1.124-1.831-.618-1.56-1.993.27-1.374-1.023-4.486-.555-6.765.497-2.418.855-3.523 3.186-5.871 2.171-2.188 7.486-4.87 9.565-7.738 2.078-2.867 6.515-11.791 9.226-17.168 3.445-6.834 6.382-9.087 10.257-6.786S-1.726-1.776 0 0"
          fill="#ffaaa8"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(374.755 253.102)"
        />
        <path
          d="M0 0c2.174-2.085 3.093-4.676 3.246-6.001 0 0 1.004 3.297-2.475 7.019z"
          fill="#ff9090"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(363.932 260.46)"
        />
        <path
          d="M0 0c-6.323 5.2-4.242 11.981-1.932 16.547 4.62 9.131 9.423 12.722 11.427 12.687l12.585-.118c6.013-.821 10.666-.41 12.12-.755 3.698-.876 3.779-3.921 3.924-7.861.159-4.318.619-3.857-.511-10.998 0 0-1.708-5.535-2.385-8.003-.678-2.469-1.925-6.313-.912-10.306.393-1.544 1.44-3.048 3.021-7.309 1.479-3.985 2.049-5.518 3.21-10.822 0 0-8.621-7.99-26.553-5.426C.791-30.477-1.801-26.129-1.801-26.129L.667-10.743C.342-5.354.252-3.403 0 0"
          fill="#00dfbf"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(380.799 256.15)"
        />
        <g clipPath="url(#aw)" opacity={0.100006}>
          <path
            d="M0 0s5.17-4.159 11.188-2.143c6.018 2.017 6.982 7.23 6.982 7.23s.516-6.849-7.07-9.048C3.779-6.084 0 0 0 0"
            fill="#000"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            transform="translate(389.02 257.513)"
          />
        </g>
        <path
          d="M0 0c-2.686 3.343-10.009 11.689-11.348 13.359-.922 1.149-2.179 2.049-3.169 2.381-1.114.373-2.201 1.151-2.933 1.869-.524.514-1.652 2.681-2.109 3.255-1.534 1.928-2.795 3.064-4.813 4.478-2.12 1.488-4.135 2.177-6.307 3.588-1.28.833-2.845 1.664-1.443 3.058.437.436.993.762 1.601.876 2.968.557 6.174-.378 9.895-1.352 1.738-.455 3.351-.939 4.715-1.472.864-.339 1.756-.682 2.485-1.264.97-.774 1.573-1.902 2.222-2.96 1.743-2.84 5.725-4.742 8.199-6.914 2.901-2.549 5.765-4.683 9.071-7.491 2.977-2.528 6.657-5.825 9.005-8.945 2.037-2.706 1.426-4.321.61-5.524C11.547-9.145 2.349-16.052-.922-18.753c-3.495-2.887-5.942-4.683-9.062-7.474-1.569-1.401-4.804-4.872-6.198-6.592-.408-.504-1.13-.699-1.7-.389-3.608 1.969-6.014 12.029-3.223 18.214.714 1.585 1.456 1.647 1.456 1.647C-13.951-9.342 0 0 0 0"
          fill="#ffaaa8"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(434.152 297.937)"
        />
        <path
          d="M0 0s2.379-3.3 6.506-4.648c0 0-3.452-.817-8.082 3.487z"
          fill="#ff9090"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(434.152 297.937)"
        />
        <path
          d="M0 0c.052-9.926 3.229-12.436 4.831-13.168 1.602-.733 4.724-.358 7.756.089l-.105-5.954S8.93-22.63 9.143-25.361c.219-2.74 5.429-3.134 9.394-.532 2.894 1.898 4.976 4.089 5.885 5.129.331.379.515.859.524 1.362l.245 14.012s1.625-1.784 4.592.173c2.96 1.95 3.28 5.681 1.527 7.826-1.249 1.524-4.49 1.686-6.087-.619-.905-1.698-.827-2.886-2.022-2.686-1.151.193-1.286 1.533-1.368 2.485-.083.952-.047 1.268-.116 2.517-2.936.631-5.972 4.714-6.314 7.698-2.093-.913-4.294-1.587-6.384-1.442-3.287.227-4.982.885-6.597 3.257-.364-.459-.737-1.237-.914-1.762C.976 10.48.7 9.486.49 8.161.062 5.468-.016 2.722 0 0"
          fill="#ffaaa8"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(382.733 304.447)"
        />
        <path
          d="M0 0c3.198.363 9.796 2.133 10.864 4.71 0 0-.369-1.625-2.359-3.271-1.99-1.647-8.54-3.425-8.54-3.425z"
          fill="#ff9090"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(395.323 291.37)"
        />
        <path
          d="M0 0a1.302 1.302 0 112.604-.046A1.302 1.302 0 010 0"
          fill="#c76668"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(392.103 296.718)"
        />
        <path
          d="M0 0c-.054-.786.517-1.462 1.273-1.512.757-.049 1.414.548 1.466 1.334.054.785-.516 1.463-1.273 1.511C.71 1.383.053.786 0 0"
          fill="#1a2e35"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(393.772 306.49)"
        />
        <path
          d="M0 0c-.049-.714.47-1.33 1.157-1.374.757-.049 1.28.427 1.333 1.212.049.715-.469 1.33-1.157 1.375C.646 1.257.048.715 0 0"
          fill="#1a2e35"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(384.498 307.082)"
        />
        <path
          d="M0 0l-.994-8.292-4.248 1.764z"
          fill="#ff9090"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(390.91 308.367)"
        />
        <path
          d="M0 0l-2.473 2.349c-.642-.719-.61-1.828.073-2.476C-1.718-.775-.644-.719 0 0"
          fill="#1a2e35"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(398.738 310.074)"
        />
        <path
          d="M0 0l-2.74-2.073a1.649 1.649 0 012.362-.384C.379-1.884.548-.784 0 0"
          fill="#1a2e35"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(386.383 313.18)"
        />
      </g>
    </svg>
  )
}
