import type { Ref } from 'react'
import { forwardRef } from 'react'
import type { AuthCodeRef } from 'react-auth-code-input'
import AuthCode from 'react-auth-code-input'

type OtpCodeInputProps = {
  onChange: (code: string) => void
  length?: number
}
const ForwardOtpCodeInput = (
  { onChange, length }: OtpCodeInputProps,
  ref: Ref<AuthCodeRef>
) => {
  return (
    <AuthCode
      length={length}
      allowedCharacters="numeric"
      containerClassName="mt-10 mx-auto w-max flex gap-[5px] sm:gap-[12px]"
      inputClassName="border focus-within:border-yellow-400 solid focus:border-yellow-400 border-grey bg-white-[100] shadow rounded-sm w-9 h-9 px-[10px]"
      onChange={onChange}
      ref={ref}
    />
  )
}

const OtpCodeInput = forwardRef(ForwardOtpCodeInput)

export { OtpCodeInput }
