import { Check } from 'lucide-react'
import { cn } from 'utils-tailwindcss'
import { DividerBehaviorStyle, EllipseStepStyles } from './stepper.styles'
import { StepProps } from './types'
import { FC, forwardRef } from 'react'

type StepLabelProps = {
  label?: string
  isActive?: boolean
  isDone?: boolean
  labelOnTop?: boolean
  className?: string
}

const StepLabel = forwardRef<HTMLLabelElement, StepLabelProps>(
  ({ label, isActive, isDone, labelOnTop, className }, ref) =>
    label && (
      <label
        ref={ref}
        className={cn(
          'text-black text-center font-semibold absolute xl:w-fit lg:inline-block',
          {
            'text-primary': isActive || isDone,
            'bottom-full': labelOnTop,
            'top-full hidden': !labelOnTop,
          },
          className
        )}
      >
        {label}
      </label>
    )
)

const StepperStep: FC<StepProps> = ({
  children,
  isActive = false,
  isDone,
  label,
  isLastStep = false,
  labelOnTop,
  labelClassName,
  ellipseClassName,
  onClick,
  onDividerClick,
  showIcon = true,
  disableCursorPointer,
}) => {
  return (
    <div
      className={cn(
        'flex flex-col cursor-default w-full justify-center items-start transition-all',
        { 'w-fit': isLastStep }
      )}
    >
      <div className={cn('flex w-full items-center relative')}>
        <button
          onClick={onClick}
          type="button"
          className={cn(
            'cursor-pointer shadow-md',
            EllipseStepStyles({ isActive, isDone: isActive || isDone }),
            { 'cursor-default': !isDone || !!disableCursorPointer },

            ellipseClassName
          )}
        >
          {showIcon && (isDone ? <Check /> : children)}
          <StepLabel
            label={label}
            isActive={isActive}
            isDone={isDone}
            labelOnTop={labelOnTop}
            className={labelClassName}
          />
        </button>
        <div
          onClick={onDividerClick}
          className={cn(DividerBehaviorStyle({ isDone, isLastStep }), {
            'cursor-pointer': !!onDividerClick,
          })}
        />
      </div>
    </div>
  )
}

export { StepperStep }
