import { IconProps } from '../icons'

export const RegisterCompletedIllustration = ({
  size = 20,
  ...rest
}: IconProps) => {
  return (
    <svg
      width="674"
      height="727"
      viewBox="0 0 674 727"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M8.31039 203H0V727H8.31039V203Z" fill="#010101" />
      <path d="M8.31039 438.131H0V485.218H8.31039V438.131Z" fill="#FAE6C9" />
      <path
        d="M169.209 689.486V240.43H369.017V689.486H390.578V220.083H145.402V689.486H169.209Z"
        fill="#349898"
      />
      <path
        d="M169.209 240.43C169.3 389.681 169.3 538.725 169.209 687.561V240.43Z"
        fill="#349898"
      />
      <path
        d="M197.548 283.669C195.686 284.136 193.958 285.034 192.502 286.292C191.046 287.549 189.903 289.131 189.165 290.912C188.849 291.584 188.715 292.327 188.775 293.068C188.811 293.436 188.956 293.786 189.191 294.071C189.426 294.356 189.741 294.565 190.094 294.67C188.005 295.041 185.62 296.003 184.287 297.644C182.944 299.306 182.233 301.393 182.282 303.533C182.259 304.433 182.512 305.317 183.006 306.067C183.538 306.767 184.492 307.236 185.338 306.959C184.519 307.839 183.939 308.917 183.653 310.087C183.389 311.284 183.625 312.642 184.489 313.509C185.352 314.377 186.881 314.577 187.803 313.775C187.65 315.525 188.573 317.519 190.143 318.282C190.937 318.65 191.821 318.774 192.685 318.638C193.549 318.503 194.354 318.115 195 317.523C195.654 319.972 198.572 321.543 201.054 321.186C203.362 320.837 205.214 318.968 206.134 316.816C207.053 314.664 207.195 312.267 207.258 309.93C207.39 305.175 207.258 300.356 205.97 295.779C204.682 291.203 201.399 286.433 197.548 283.669Z"
        fill="#363D47"
      />
      <path
        d="M231.497 330.722C231.497 330.722 227.075 366.469 208.536 357.081C189.997 347.692 184.921 338.301 184.921 338.301L194.199 334.802C197.489 332.765 199.383 330.645 200.891 327.524C202.872 323.419 202.534 318.3 201.176 314.717C201.176 314.717 207.095 315.728 211.433 316.652C213.981 317.194 217.936 317.618 217.807 317.873C217.64 318.223 218.302 323.216 219.841 326.523C220.209 327.303 220.762 327.981 221.452 328.496C222.141 329.011 222.946 329.349 223.796 329.48L231.497 330.722Z"
        fill="#F4937F"
      />
      <path
        d="M287.253 682.634C281.902 683.334 258.966 678.873 248.789 680.923C238.613 682.974 230.929 684.51 229.404 677.337C227.879 670.163 231.952 668.627 233.603 662.99C234.278 660.688 234.25 657.791 234.048 655.11C233.752 651.233 233.077 647.818 233.603 647.367C233.603 647.367 250.554 642.496 253.611 649.414C255.191 652.986 256.49 656.167 258.37 658.928C260.26 661.74 262.833 664.02 265.845 665.552C277.028 671.444 287.253 671.7 290.821 674.261C294.39 676.822 292.604 681.948 287.253 682.634Z"
        fill="#F4937F"
      />
      <path
        d="M295.327 676.57H227.364V684.083H295.327V676.57Z"
        fill="#9BDAD6"
      />
      <path
        d="M261.344 678.618C261.344 676.935 263.722 667.113 270.518 664.586C277.314 662.06 295.323 672.725 295.323 676.935L261.344 678.618Z"
        fill="#9BDAD6"
      />
      <path
        d="M258.37 658.932C249.969 659.435 238.874 656.531 234.048 655.114C233.752 651.237 233.077 647.822 233.603 647.371C233.603 647.371 250.554 642.5 253.611 649.418C255.191 652.99 256.49 656.171 258.37 658.932Z"
        fill="#363D47"
      />
      <path
        d="M296.322 666.266C298.871 676.001 295.048 684.461 295.048 684.461C295.048 684.461 277.989 684.972 272.495 685.483C267.001 685.994 264.467 682.922 266.503 677.544C268.54 672.166 278.48 672.162 278.48 672.162C278.647 668.663 275.914 662.816 273.978 659.128C272.934 657.133 272.109 655.772 272.109 655.772C272.109 655.772 279.681 628.448 284.6 631.18C287.194 632.626 290.672 634.403 293.527 636.209C296.079 637.822 298.133 639.466 298.613 640.915C299.633 643.984 293.774 656.532 296.322 666.266Z"
        fill="#F4937F"
      />
      <path
        d="M262.935 685.533C262.681 683.94 259.624 680.354 269.306 672.414C278.988 664.475 289.952 670.665 293.262 678.975H318.747V685.533H262.935Z"
        fill="#9BDAD6"
      />
      <path
        d="M189.088 434.831L190.188 423.151C190.188 423.151 253.05 403.85 252.03 406.327C251.01 408.804 258.489 425.88 258.489 425.88C258.489 425.88 195.466 450.969 189.088 434.831Z"
        fill="#F4937F"
      />
      <path
        d="M293.527 636.201C286.216 655.715 278.11 658.56 273.978 659.12C272.933 657.126 272.108 655.764 272.108 655.764C272.108 655.764 279.681 628.44 284.6 631.173C287.194 632.618 290.672 634.395 293.527 636.201Z"
        fill="#363D47"
      />
      <path
        d="M215.447 437.958L259.846 423.164C259.846 423.164 289.07 510.562 291.789 537.9L278.086 652.57C278.086 652.57 244.218 655.401 227.225 646.52C227.225 646.52 232.074 631.946 236.061 616.466C250.335 561.043 244.897 532.452 244.897 532.452C244.897 532.452 214.541 457.536 215.447 437.958Z"
        fill="#F1B52E"
      />
      <path
        d="M243.877 468.149C243.877 468.149 257.455 549.868 257.455 550.893C257.455 551.918 270.431 587.392 270.431 587.392L245.235 564.897L237.081 479.518L243.877 468.149Z"
        fill="#363D47"
      />
      <path
        d="M189.089 434.831C189.089 434.831 184.409 484.623 208.878 574.796C208.878 574.796 240.821 630.806 244.897 650.614C244.897 650.614 253.734 660.86 267.325 659.495C280.917 658.131 291.79 627.391 291.79 627.391C291.79 627.391 254.287 557.783 253.608 554.305C252.929 550.827 244.208 488.049 244.208 486.002C244.208 483.955 250.384 426.31 235.052 431.416C224.356 434.992 189.089 434.831 189.089 434.831Z"
        fill="#F1B52E"
      />
      <path
        d="M262.433 685.682C262.165 684.776 262.203 679.611 265.309 675.035C268.07 670.972 272.331 668.677 277.968 668.215L278.055 669.265C272.745 669.695 268.745 671.836 266.172 675.626C263.317 679.825 263.234 684.706 263.436 685.382L262.433 685.682Z"
        fill="#363D47"
      />
      <path
        d="M282.584 611.34C280.739 607.897 260.609 567.549 260.404 567.143C253.66 553.535 241.025 532.432 239.929 531.49L240.594 530.679C242.021 531.861 255.049 553.986 261.34 566.674C261.542 567.08 281.669 607.414 283.503 610.844L282.584 611.34Z"
        fill="#363D47"
      />
      <path
        d="M224.384 581.653C220.206 581.653 217.567 580.103 217.445 580.033L217.978 579.131C218.009 579.131 221.084 580.933 225.721 580.53C231.88 580.016 238.014 575.911 243.459 568.661L244.295 569.294C237.154 578.798 230.264 581.223 225.738 581.58C225.272 581.636 224.819 581.653 224.384 581.653Z"
        fill="#363D47"
      />
      <path
        d="M255.181 416.985C234.418 431.604 205.848 430.17 193.228 428.616C189.196 428.119 186.79 427.608 186.79 427.608C186.79 427.608 187.807 421.121 188.73 401.998C189.113 394.069 188.145 381.92 186.881 370.408C185.095 354.144 182.703 339.154 182.703 339.154L186.783 337.59C186.783 337.59 206.057 353.497 213.288 350.082C218.817 347.471 230.957 330.616 230.957 330.616H235.716C233.913 343.892 240.865 361.699 244.618 377.203C245.113 379.25 245.552 381.259 245.91 383.211C246.735 387.577 247.929 391.864 249.479 396.025C251.409 401.232 253.749 406.276 256.476 411.11C257.025 412.076 257.196 413.213 256.957 414.299C256.717 415.385 256.085 416.342 255.181 416.985Z"
        fill="#9BDAD6"
      />
      <mask
        id="mask0_29_5930"
        maskUnits="userSpaceOnUse"
        x="182"
        y="330"
        width="76"
        height="100"
      >
        <path
          d="M255.181 416.985C234.418 431.604 205.848 430.17 193.228 428.616C189.196 428.119 186.79 427.608 186.79 427.608C186.79 427.608 187.807 421.121 188.73 401.998C189.113 394.069 188.145 381.92 186.881 370.408C185.095 354.144 182.703 339.154 182.703 339.154L186.783 337.59C186.783 337.59 206.057 353.497 213.288 350.082C218.817 347.471 230.957 330.616 230.957 330.616H235.716C233.913 343.892 240.865 361.699 244.618 377.203C245.113 379.25 245.552 381.259 245.91 383.211C246.735 387.577 247.929 391.864 249.479 396.025C251.409 401.232 253.749 406.276 256.476 411.11C257.025 412.076 257.196 413.213 256.957 414.299C256.717 415.385 256.085 416.342 255.181 416.985Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_29_5930)">
        <path
          d="M186.877 351.281C187.41 351.281 187.842 349.32 187.842 346.9C187.842 344.481 187.41 342.52 186.877 342.52C186.345 342.52 185.913 344.481 185.913 346.9C185.913 349.32 186.345 351.281 186.877 351.281Z"
          fill="white"
        />
        <path
          d="M186.877 360.761C187.41 360.761 187.842 358.799 187.842 356.38C187.842 353.96 187.41 351.999 186.877 351.999C186.345 351.999 185.913 353.96 185.913 356.38C185.913 358.799 186.345 360.761 186.877 360.761Z"
          fill="white"
        />
        <path
          d="M190.219 348.157C188.927 350.201 187.517 351.625 187.085 351.338C186.654 351.051 187.319 349.161 188.61 347.118C189.902 345.074 191.309 343.65 191.744 343.937C192.179 344.224 191.51 346.114 190.219 348.157Z"
          fill="white"
        />
        <path
          d="M185.164 356.159C183.872 358.203 182.462 359.627 182.031 359.34C181.599 359.053 182.264 357.167 183.556 355.141C184.847 353.115 186.254 351.673 186.689 351.96C187.124 352.247 186.452 354.116 185.164 356.159Z"
          fill="white"
        />
        <path
          d="M191.563 350.502C189.384 351.531 187.434 351.975 187.208 351.492C186.982 351.01 188.566 349.781 190.742 348.749C192.918 347.717 194.871 347.276 195.097 347.762C195.323 348.249 193.736 349.47 191.563 350.502Z"
          fill="white"
        />
        <path
          d="M183.019 354.533C180.84 355.583 178.89 356.006 178.664 355.523C178.438 355.04 180.022 353.812 182.201 352.78C184.381 351.748 186.327 351.31 186.553 351.793C186.78 352.276 185.199 353.504 183.019 354.533Z"
          fill="white"
        />
        <path
          d="M191.438 353.196C189.046 352.892 187.166 352.216 187.232 351.684C187.298 351.152 189.29 350.985 191.678 351.275C194.066 351.565 195.95 352.255 195.884 352.787C195.818 353.318 193.826 353.5 191.438 353.196Z"
          fill="white"
        />
        <path
          d="M182.076 352.006C179.688 351.701 177.808 351.023 177.874 350.494C177.94 349.966 179.931 349.794 182.32 350.085C184.708 350.375 186.592 351.065 186.525 351.593C186.459 352.121 184.468 352.307 182.076 352.006Z"
          fill="white"
        />
        <path
          d="M190.498 354.83C188.409 353.63 186.926 352.282 187.187 351.817C187.448 351.352 189.36 351.95 191.452 353.15C193.544 354.35 195.024 355.698 194.759 356.163C194.495 356.628 192.587 356.03 190.498 354.83Z"
          fill="white"
        />
        <path
          d="M182.306 350.131C180.217 348.93 178.734 347.58 178.995 347.118C179.256 346.656 181.167 347.247 183.26 348.448C185.352 349.648 186.832 350.998 186.567 351.46C186.302 351.922 184.394 351.331 182.306 350.131Z"
          fill="white"
        />
        <path
          d="M188.26 356.275C187.121 354.14 186.578 352.209 187.048 351.957C187.518 351.705 188.82 353.227 189.959 355.358C191.097 357.489 191.637 359.424 191.17 359.68C190.704 359.935 189.398 358.406 188.26 356.275Z"
          fill="white"
        />
        <path
          d="M183.8 347.919C182.661 345.788 182.118 343.853 182.588 343.601C183.058 343.35 184.36 344.872 185.499 347.006C186.637 349.141 187.177 351.072 186.71 351.324C186.244 351.576 184.949 350.054 183.8 347.919Z"
          fill="white"
        />
        <path
          d="M186.439 355.1C188.012 355.1 189.287 353.818 189.287 352.237C189.287 350.656 188.012 349.375 186.439 349.375C184.866 349.375 183.591 350.656 183.591 352.237C183.591 353.818 184.866 355.1 186.439 355.1Z"
          fill="#F1B52E"
        />
        <path
          d="M215.746 356.895C215.746 359.312 215.314 361.275 214.781 361.275C214.249 361.275 213.82 359.312 213.82 356.895C213.82 354.477 214.249 352.514 214.781 352.514C215.314 352.514 215.746 354.473 215.746 356.895Z"
          fill="white"
        />
        <path
          d="M215.746 366.374C215.746 368.795 215.314 370.758 214.781 370.758C214.249 370.758 213.82 368.795 213.82 366.374C213.82 363.953 214.249 361.993 214.781 361.993C215.314 361.993 215.746 363.956 215.746 366.374Z"
          fill="white"
        />
        <path
          d="M218.124 358.151C216.832 360.194 215.422 361.618 214.973 361.331C214.524 361.044 215.206 359.155 216.498 357.115C217.79 355.075 219.196 353.647 219.631 353.934C220.067 354.221 219.415 356.1 218.124 358.151Z"
          fill="white"
        />
        <path
          d="M213.068 366.157C211.777 368.201 210.366 369.621 209.917 369.338C209.468 369.054 210.151 367.161 211.442 365.118C212.734 363.074 214.141 361.65 214.576 361.937C215.011 362.224 214.356 364.114 213.068 366.157Z"
          fill="white"
        />
        <path
          d="M219.461 360.492C217.281 361.541 215.332 361.965 215.106 361.482C214.879 360.999 216.463 359.771 218.639 358.742C220.815 357.713 222.768 357.269 222.995 357.752C223.221 358.235 221.64 359.463 219.461 360.492Z"
          fill="white"
        />
        <path
          d="M210.924 364.529C208.744 365.558 206.795 365.999 206.568 365.516C206.342 365.033 207.926 363.805 210.106 362.776C212.285 361.747 214.231 361.303 214.457 361.786C214.684 362.269 213.103 363.497 210.924 364.529Z"
          fill="white"
        />
        <path
          d="M219.339 363.189C216.95 362.885 215.07 362.209 215.136 361.678C215.203 361.146 217.194 360.978 219.582 361.268C221.971 361.559 223.854 362.251 223.788 362.78C223.722 363.308 221.73 363.494 219.339 363.189Z"
          fill="white"
        />
        <path
          d="M209.98 362C207.592 361.695 205.712 361.02 205.778 360.488C205.844 359.956 207.836 359.788 210.224 360.079C212.612 360.369 214.496 361.058 214.426 361.59C214.356 362.122 212.372 362.304 209.98 362Z"
          fill="white"
        />
        <path
          d="M218.402 364.827C216.313 363.626 214.83 362.276 215.091 361.81C215.352 361.345 217.264 361.943 219.356 363.143C221.449 364.344 222.928 365.691 222.664 366.156C222.399 366.622 220.491 366.027 218.402 364.827Z"
          fill="white"
        />
        <path
          d="M210.21 360.124C208.121 358.924 206.638 357.576 206.899 357.111C207.16 356.646 209.071 357.244 211.164 358.444C213.256 359.644 214.736 360.992 214.471 361.457C214.207 361.922 212.299 361.324 210.21 360.124Z"
          fill="white"
        />
        <path
          d="M216.164 366.269C215.025 364.138 214.482 362.203 214.952 361.951C215.422 361.699 216.724 363.221 217.859 365.356C218.994 367.49 219.541 369.422 219.075 369.674C218.608 369.926 217.302 368.403 216.164 366.269Z"
          fill="white"
        />
        <path
          d="M211.704 357.917C210.566 355.782 210.022 353.851 210.492 353.595C210.962 353.34 212.264 354.869 213.403 357C214.541 359.131 215.081 361.066 214.614 361.318C214.148 361.57 212.842 360.048 211.704 357.917Z"
          fill="white"
        />
        <path
          d="M214.343 365.093C215.916 365.093 217.191 363.811 217.191 362.23C217.191 360.65 215.916 359.368 214.343 359.368C212.77 359.368 211.495 360.65 211.495 362.23C211.495 363.811 212.77 365.093 214.343 365.093Z"
          fill="#F1B52E"
        />
        <path
          d="M235.597 340.141C236.13 340.141 236.562 338.179 236.562 335.76C236.562 333.34 236.13 331.379 235.597 331.379C235.065 331.379 234.633 333.34 234.633 335.76C234.633 338.179 235.065 340.141 235.597 340.141Z"
          fill="white"
        />
        <path
          d="M235.597 349.623C236.13 349.623 236.562 347.662 236.562 345.242C236.562 342.823 236.13 340.861 235.597 340.861C235.065 340.861 234.633 342.823 234.633 345.242C234.633 347.662 235.065 349.623 235.597 349.623Z"
          fill="white"
        />
        <path
          d="M238.939 337.016C237.648 339.059 236.238 340.483 235.806 340.196C235.374 339.909 236.039 338.023 237.328 335.997C238.616 333.971 240.029 332.53 240.461 332.817C240.893 333.104 240.228 334.972 238.939 337.016Z"
          fill="white"
        />
        <path
          d="M233.881 345.022C232.593 347.066 231.183 348.49 230.748 348.203C230.313 347.916 230.981 346.027 232.269 343.983C233.557 341.94 234.971 340.515 235.403 340.802C235.834 341.089 235.173 342.979 233.881 345.022Z"
          fill="white"
        />
        <path
          d="M240.271 339.333C242.45 338.303 244.032 337.076 243.805 336.591C243.578 336.107 241.629 336.549 239.45 337.579C237.272 338.609 235.69 339.836 235.916 340.32C236.143 340.805 238.093 340.363 240.271 339.333Z"
          fill="white"
        />
        <path
          d="M231.74 343.395C229.561 344.423 227.611 344.868 227.385 344.381C227.159 343.895 228.739 342.67 230.919 341.641C233.098 340.613 235.048 340.168 235.274 340.651C235.5 341.134 233.92 342.362 231.74 343.395Z"
          fill="white"
        />
        <path
          d="M240.154 342.055C237.766 341.754 235.882 341.075 235.952 340.547C236.022 340.019 238.01 339.847 240.398 340.134C242.786 340.421 244.666 341.117 244.6 341.646C244.534 342.174 242.543 342.36 240.154 342.055Z"
          fill="white"
        />
        <path
          d="M230.797 340.865C228.408 340.561 226.525 339.885 226.595 339.353C226.664 338.821 228.652 338.653 231.04 338.944C233.429 339.234 235.309 339.924 235.243 340.456C235.177 340.987 233.185 341.169 230.797 340.865Z"
          fill="white"
        />
        <path
          d="M239.215 343.692C237.126 342.492 235.643 341.141 235.908 340.679C236.172 340.218 238.08 340.809 240.172 342.009C242.265 343.209 243.744 344.56 243.48 345.022C243.215 345.484 241.307 344.903 239.215 343.692Z"
          fill="white"
        />
        <path
          d="M231.023 338.989C228.934 337.789 227.451 336.442 227.715 335.976C227.98 335.511 229.888 336.109 231.98 337.309C234.072 338.51 235.552 339.857 235.287 340.322C235.023 340.788 233.115 340.189 231.023 338.989Z"
          fill="white"
        />
        <path
          d="M236.976 345.134C235.841 343.003 235.298 341.068 235.765 340.816C236.231 340.564 237.537 342.087 238.675 344.221C239.814 346.355 240.357 348.287 239.887 348.539C239.417 348.791 238.115 347.269 236.976 345.134Z"
          fill="white"
        />
        <path
          d="M232.519 336.782C231.381 334.647 230.838 332.716 231.308 332.46C231.778 332.205 233.076 333.734 234.215 335.865C235.353 337.996 235.897 339.931 235.426 340.183C234.956 340.435 233.658 338.913 232.519 336.782Z"
          fill="white"
        />
        <path
          d="M237.789 342.192C238.39 340.731 237.7 339.057 236.247 338.452C234.794 337.847 233.128 338.541 232.526 340.001C231.924 341.462 232.615 343.136 234.068 343.741C235.521 344.346 237.187 343.652 237.789 342.192Z"
          fill="#F1B52E"
        />
        <path
          d="M245.091 378.669C245.624 378.669 246.056 376.708 246.056 374.288C246.056 371.869 245.624 369.907 245.091 369.907C244.559 369.907 244.127 371.869 244.127 374.288C244.127 376.708 244.559 378.669 245.091 378.669Z"
          fill="white"
        />
        <path
          d="M245.091 388.149C245.624 388.149 246.056 386.187 246.056 383.768C246.056 381.348 245.624 379.387 245.091 379.387C244.559 379.387 244.127 381.348 244.127 383.768C244.127 386.187 244.559 388.149 245.091 388.149Z"
          fill="white"
        />
        <path
          d="M248.476 375.595C249.767 373.553 250.448 371.664 249.998 371.377C249.549 371.09 248.138 372.514 246.848 374.556C245.558 376.599 244.876 378.487 245.326 378.774C245.776 379.061 247.186 377.638 248.476 375.595Z"
          fill="white"
        />
        <path
          d="M243.419 383.598C244.709 381.556 245.39 379.667 244.941 379.38C244.491 379.093 243.081 380.517 241.79 382.559C240.5 384.602 239.819 386.49 240.268 386.777C240.718 387.064 242.128 385.641 243.419 383.598Z"
          fill="white"
        />
        <path
          d="M249.771 377.885C247.591 378.914 245.641 379.358 245.415 378.872C245.189 378.386 246.769 377.161 248.949 376.132C251.128 375.104 253.078 374.659 253.304 375.146C253.531 375.632 251.946 376.857 249.771 377.885Z"
          fill="white"
        />
        <path
          d="M241.234 381.92C239.055 382.949 237.105 383.393 236.879 382.91C236.653 382.427 238.233 381.199 240.413 380.167C242.592 379.135 244.542 378.694 244.768 379.18C244.994 379.667 243.41 380.891 241.234 381.92Z"
          fill="white"
        />
        <path
          d="M249.648 380.593C247.26 380.289 245.377 379.614 245.446 379.082C245.516 378.55 247.504 378.382 249.892 378.672C252.28 378.963 254.16 379.652 254.094 380.184C254.028 380.716 252.037 380.887 249.648 380.593Z"
          fill="white"
        />
        <path
          d="M240.305 379.39C237.916 379.086 236.033 378.41 236.102 377.882C236.172 377.354 238.157 377.182 240.548 377.469C242.94 377.756 244.817 378.452 244.751 378.981C244.684 379.509 242.679 379.694 240.305 379.39Z"
          fill="white"
        />
        <path
          d="M248.709 382.218C246.62 381.017 245.137 379.67 245.402 379.205C245.666 378.739 247.574 379.338 249.667 380.534C251.759 381.731 253.239 383.085 252.974 383.551C252.709 384.016 250.801 383.418 248.709 382.218Z"
          fill="white"
        />
        <path
          d="M240.517 377.518C238.428 376.318 236.945 374.967 237.209 374.502C237.474 374.036 239.382 374.635 241.474 375.835C243.567 377.035 245.046 378.382 244.782 378.848C244.517 379.313 242.609 378.718 240.517 377.518Z"
          fill="white"
        />
        <path
          d="M246.47 383.663C245.332 381.528 244.792 379.597 245.259 379.341C245.725 379.086 247.031 380.615 248.169 382.746C249.308 384.877 249.851 386.812 249.381 387.064C248.911 387.316 247.616 385.794 246.47 383.663Z"
          fill="white"
        />
        <path
          d="M244.923 378.728C245.392 378.475 244.85 376.541 243.712 374.409C242.574 372.277 241.271 370.754 240.802 371.007C240.333 371.26 240.875 373.193 242.013 375.325C243.151 377.458 244.454 378.981 244.923 378.728Z"
          fill="white"
        />
        <path
          d="M244.65 382.487C246.222 382.487 247.498 381.206 247.498 379.625C247.498 378.044 246.222 376.763 244.65 376.763C243.077 376.763 241.802 378.044 241.802 379.625C241.802 381.206 243.077 382.487 244.65 382.487Z"
          fill="#F1B52E"
        />
        <path
          d="M220.425 386.7C220.425 389.118 219.993 391.081 219.461 391.081C218.928 391.081 218.5 389.118 218.5 386.7C218.5 384.282 218.928 382.319 219.461 382.319C219.993 382.319 220.425 384.279 220.425 386.7Z"
          fill="white"
        />
        <path
          d="M220.425 396.179C220.425 398.6 219.993 400.563 219.461 400.563C218.928 400.563 218.5 398.6 218.5 396.179C218.5 393.757 218.928 391.798 219.461 391.798C219.993 391.798 220.425 393.761 220.425 396.179Z"
          fill="white"
        />
        <path
          d="M222.803 387.942C221.511 389.985 220.101 391.409 219.652 391.122C219.203 390.836 219.885 388.946 221.177 386.903C222.468 384.859 223.875 383.438 224.31 383.725C224.745 384.012 224.094 385.912 222.803 387.942Z"
          fill="white"
        />
        <path
          d="M217.748 395.962C216.456 398.005 215.046 399.426 214.597 399.142C214.148 398.859 214.83 396.966 216.122 394.923C217.414 392.879 218.82 391.455 219.255 391.742C219.691 392.029 219.036 393.918 217.748 395.962Z"
          fill="white"
        />
        <path
          d="M224.14 390.297C221.96 391.329 220.011 391.77 219.784 391.287C219.558 390.805 221.142 389.576 223.318 388.548C225.494 387.519 227.447 387.075 227.673 387.557C227.9 388.04 226.319 389.268 224.14 390.297Z"
          fill="white"
        />
        <path
          d="M215.603 394.335C213.424 395.364 211.474 395.805 211.248 395.322C211.022 394.839 212.606 393.611 214.785 392.582C216.965 391.553 218.911 391.109 219.137 391.592C219.363 392.074 217.783 393.303 215.603 394.335Z"
          fill="white"
        />
        <path
          d="M224.021 392.995C221.629 392.69 219.749 392.015 219.815 391.483C219.881 390.951 221.873 390.783 224.261 391.074C226.649 391.364 228.533 392.054 228.467 392.585C228.401 393.117 226.409 393.299 224.021 392.995Z"
          fill="white"
        />
        <path
          d="M214.66 391.791C212.272 391.486 210.392 390.811 210.458 390.279C210.524 389.747 212.515 389.579 214.904 389.87C217.292 390.16 219.176 390.849 219.109 391.381C219.043 391.913 217.052 392.109 214.66 391.791Z"
          fill="white"
        />
        <path
          d="M223.081 394.632C220.992 393.432 219.509 392.081 219.77 391.616C220.031 391.151 221.943 391.749 224.035 392.949C226.127 394.149 227.607 395.496 227.342 395.962C227.078 396.427 225.17 395.829 223.081 394.632Z"
          fill="white"
        />
        <path
          d="M214.89 389.929C212.801 388.729 211.318 387.382 211.579 386.917C211.84 386.451 213.751 387.05 215.844 388.25C217.936 389.45 219.416 390.797 219.151 391.263C218.886 391.728 216.978 391.13 214.89 389.929Z"
          fill="white"
        />
        <path
          d="M220.843 396.074C219.704 393.943 219.161 392.008 219.631 391.756C220.101 391.504 221.403 393.026 222.542 395.16C223.68 397.295 224.22 399.226 223.753 399.478C223.287 399.73 221.981 398.208 220.843 396.074Z"
          fill="white"
        />
        <path
          d="M216.383 387.721C215.244 385.587 214.701 383.655 215.171 383.4C215.641 383.145 216.943 384.67 218.082 386.805C219.22 388.939 219.76 390.871 219.293 391.123C218.827 391.374 217.521 389.852 216.383 387.721Z"
          fill="white"
        />
        <path
          d="M219.022 394.898C220.595 394.898 221.87 393.617 221.87 392.036C221.87 390.455 220.595 389.174 219.022 389.174C217.449 389.174 216.174 390.455 216.174 392.036C216.174 393.617 217.449 394.898 219.022 394.898Z"
          fill="#F1B52E"
        />
        <path
          d="M187.841 386.714C188.374 386.714 188.806 384.753 188.806 382.333C188.806 379.914 188.374 377.952 187.841 377.952C187.309 377.952 186.877 379.914 186.877 382.333C186.877 384.753 187.309 386.714 187.841 386.714Z"
          fill="white"
        />
        <path
          d="M187.841 396.193C188.374 396.193 188.806 394.232 188.806 391.813C188.806 389.393 188.374 387.432 187.841 387.432C187.309 387.432 186.877 389.393 186.877 391.813C186.877 394.232 187.309 396.193 187.841 396.193Z"
          fill="white"
        />
        <path
          d="M191.184 383.59C189.892 385.633 188.482 387.057 188.05 386.77C187.619 386.483 188.284 384.594 189.572 382.55C190.86 380.507 192.273 379.083 192.723 379.37C193.172 379.657 192.472 381.546 191.184 383.59Z"
          fill="white"
        />
        <path
          d="M186.167 391.645C187.457 389.603 188.138 387.714 187.689 387.427C187.239 387.14 185.829 388.564 184.538 390.606C183.248 392.649 182.567 394.537 183.016 394.824C183.466 395.111 184.876 393.688 186.167 391.645Z"
          fill="white"
        />
        <path
          d="M192.521 385.93C190.341 386.959 188.391 387.403 188.165 386.92C187.939 386.437 189.519 385.209 191.699 384.177C193.878 383.145 195.828 382.704 196.054 383.19C196.281 383.676 194.696 384.901 192.521 385.93Z"
          fill="white"
        />
        <path
          d="M183.983 389.964C181.804 391.014 179.854 391.438 179.628 390.955C179.402 390.472 180.982 389.244 183.162 388.211C185.341 387.179 187.291 386.742 187.517 387.225C187.743 387.708 186.163 388.936 183.983 389.964Z"
          fill="white"
        />
        <path
          d="M192.398 388.642C190.01 388.338 188.13 387.663 188.196 387.131C188.262 386.599 190.254 386.431 192.642 386.721C195.03 387.012 196.91 387.701 196.844 388.233C196.778 388.765 194.786 388.933 192.398 388.642Z"
          fill="white"
        />
        <path
          d="M183.04 387.438C180.652 387.134 178.768 386.455 178.838 385.927C178.907 385.398 180.895 385.227 183.284 385.517C185.672 385.808 187.552 386.497 187.486 387.025C187.42 387.554 185.428 387.739 183.04 387.438Z"
          fill="white"
        />
        <path
          d="M191.459 390.262C189.37 389.062 187.887 387.715 188.152 387.25C188.416 386.784 190.324 387.383 192.417 388.583C194.509 389.783 195.989 391.13 195.724 391.596C195.459 392.061 193.551 391.463 191.459 390.262Z"
          fill="white"
        />
        <path
          d="M183.267 385.562C181.178 384.362 179.695 383.011 179.959 382.55C180.224 382.088 182.132 382.679 184.224 383.879C186.317 385.079 187.796 386.43 187.532 386.892C187.267 387.354 185.359 386.762 183.267 385.562Z"
          fill="white"
        />
        <path
          d="M189.223 391.707C188.085 389.573 187.541 387.642 188.008 387.39C188.475 387.138 189.78 388.66 190.919 390.791C192.057 392.922 192.6 394.857 192.13 395.112C191.66 395.368 190.358 393.838 189.223 391.707Z"
          fill="white"
        />
        <path
          d="M184.764 383.351C183.625 381.22 183.082 379.285 183.552 379.033C184.022 378.781 185.321 380.303 186.459 382.438C187.598 384.572 188.141 386.504 187.671 386.756C187.201 387.008 185.902 385.493 184.764 383.351Z"
          fill="white"
        />
        <path
          d="M187.862 390.456C189.415 390.202 190.469 388.732 190.217 387.172C189.965 385.611 188.502 384.552 186.95 384.805C185.397 385.058 184.343 386.529 184.595 388.089C184.847 389.649 186.31 390.709 187.862 390.456Z"
          fill="#F1B52E"
        />
        <path
          d="M198.877 415.19C199.41 415.19 199.842 413.228 199.842 410.809C199.842 408.389 199.41 406.428 198.877 406.428C198.345 406.428 197.913 408.389 197.913 410.809C197.913 413.228 198.345 415.19 198.877 415.19Z"
          fill="white"
        />
        <path
          d="M198.877 424.672C199.41 424.672 199.842 422.711 199.842 420.291C199.842 417.872 199.41 415.91 198.877 415.91C198.345 415.91 197.913 417.872 197.913 420.291C197.913 422.711 198.345 424.672 198.877 424.672Z"
          fill="white"
        />
        <path
          d="M202.221 412.065C200.929 414.108 199.519 415.532 199.07 415.245C198.621 414.958 199.303 413.069 200.591 411.025C201.879 408.982 203.293 407.561 203.742 407.845C204.191 408.128 203.509 410.021 202.221 412.065Z"
          fill="white"
        />
        <path
          d="M197.165 420.071C195.873 422.111 194.463 423.535 194.014 423.248C193.565 422.961 194.247 421.075 195.535 419.049C196.824 417.023 198.237 415.582 198.686 415.868C199.135 416.155 198.453 418.034 197.165 420.071Z"
          fill="white"
        />
        <path
          d="M203.557 414.406C201.377 415.455 199.428 415.879 199.201 415.396C198.975 414.913 200.556 413.685 202.735 412.656C204.914 411.627 206.864 411.183 207.09 411.666C207.317 412.149 205.733 413.377 203.557 414.406Z"
          fill="white"
        />
        <path
          d="M195.021 418.441C192.841 419.491 190.891 419.914 190.665 419.431C190.439 418.948 192.019 417.72 194.199 416.691C196.378 415.662 198.328 415.218 198.554 415.701C198.781 416.184 197.2 417.412 195.021 418.441Z"
          fill="white"
        />
        <path
          d="M203.435 417.104C201.047 416.8 199.167 416.124 199.233 415.593C199.299 415.061 201.291 414.893 203.679 415.183C206.067 415.474 207.947 416.163 207.881 416.695C207.815 417.227 205.838 417.409 203.435 417.104Z"
          fill="white"
        />
        <path
          d="M194.077 415.914C191.689 415.609 189.805 414.934 189.875 414.402C189.945 413.87 191.933 413.702 194.321 413.993C196.709 414.283 198.589 414.972 198.523 415.504C198.457 416.036 196.465 416.218 194.077 415.914Z"
          fill="white"
        />
        <path
          d="M202.495 418.734C200.406 417.537 198.923 416.187 199.188 415.721C199.452 415.256 201.36 415.854 203.453 417.055C205.545 418.255 207.025 419.602 206.76 420.067C206.495 420.533 204.588 419.938 202.495 418.734Z"
          fill="white"
        />
        <path
          d="M194.303 414.039C192.214 412.839 190.731 411.492 190.995 411.026C191.26 410.561 193.168 411.159 195.26 412.356C197.353 413.553 198.832 414.907 198.568 415.372C198.303 415.837 196.395 415.236 194.303 414.039Z"
          fill="white"
        />
        <path
          d="M200.267 420.183C199.129 418.052 198.585 416.117 199.052 415.865C199.518 415.613 200.824 417.135 201.963 419.266C203.101 421.397 203.644 423.336 203.174 423.588C202.704 423.84 201.395 422.318 200.267 420.183Z"
          fill="white"
        />
        <path
          d="M195.8 411.828C194.661 409.697 194.118 407.762 194.588 407.51C195.058 407.258 196.357 408.78 197.495 410.914C198.634 413.049 199.177 414.98 198.707 415.232C198.237 415.484 196.938 413.962 195.8 411.828Z"
          fill="white"
        />
        <path
          d="M198.436 419.008C200.009 419.008 201.284 417.726 201.284 416.145C201.284 414.565 200.009 413.283 198.436 413.283C196.863 413.283 195.588 414.565 195.588 416.145C195.588 417.726 196.863 419.008 198.436 419.008Z"
          fill="#F1B52E"
        />
        <path
          d="M248.747 408.895C249.279 408.895 249.711 406.933 249.711 404.514C249.711 402.094 249.279 400.133 248.747 400.133C248.214 400.133 247.782 402.094 247.782 404.514C247.782 406.933 248.214 408.895 248.747 408.895Z"
          fill="white"
        />
        <path
          d="M248.747 418.374C249.279 418.374 249.711 416.413 249.711 413.993C249.711 411.574 249.279 409.612 248.747 409.612C248.214 409.612 247.782 411.574 247.782 413.993C247.782 416.413 248.214 418.374 248.747 418.374Z"
          fill="white"
        />
        <path
          d="M252.086 405.788C250.794 407.831 249.384 409.256 248.935 408.969C248.486 408.682 249.168 406.792 250.46 404.749C251.751 402.705 253.161 401.281 253.593 401.568C254.025 401.855 253.377 403.727 252.086 405.788Z"
          fill="white"
        />
        <path
          d="M247.031 413.772C245.739 415.816 244.329 417.24 243.88 416.953C243.431 416.666 244.114 414.78 245.405 412.754C246.697 410.728 248.103 409.287 248.539 409.574C248.974 409.86 248.312 411.736 247.031 413.772Z"
          fill="white"
        />
        <path
          d="M253.423 408.111C251.243 409.161 249.294 409.584 249.067 409.101C248.841 408.618 250.425 407.39 252.605 406.358C254.784 405.326 256.73 404.888 256.96 405.371C257.19 405.854 255.602 407.082 253.423 408.111Z"
          fill="white"
        />
        <path
          d="M244.886 412.146C242.706 413.196 240.757 413.619 240.53 413.136C240.304 412.653 241.888 411.425 244.068 410.396C246.247 409.368 248.197 408.923 248.423 409.406C248.649 409.889 247.065 411.117 244.886 412.146Z"
          fill="white"
        />
        <path
          d="M253.304 410.808C250.916 410.504 249.032 409.829 249.098 409.297C249.165 408.765 251.156 408.597 253.544 408.887C255.933 409.178 257.816 409.867 257.75 410.399C257.684 410.931 255.692 411.113 253.304 410.808Z"
          fill="white"
        />
        <path
          d="M243.946 409.637C241.557 409.332 239.674 408.653 239.74 408.125C239.806 407.597 241.798 407.425 244.186 407.716C246.574 408.006 248.458 408.695 248.392 409.224C248.325 409.752 246.334 409.924 243.946 409.637Z"
          fill="white"
        />
        <path
          d="M252.364 412.436C250.275 411.236 248.792 409.889 249.057 409.424C249.322 408.958 251.229 409.557 253.318 410.757C255.407 411.957 256.89 413.304 256.626 413.769C256.361 414.235 254.453 413.643 252.364 412.436Z"
          fill="white"
        />
        <path
          d="M244.172 407.744C242.083 406.544 240.6 405.193 240.865 404.731C241.129 404.269 243.037 404.861 245.126 406.061C247.215 407.261 248.698 408.612 248.433 409.077C248.169 409.542 246.261 408.937 244.172 407.744Z"
          fill="white"
        />
        <path
          d="M250.126 413.888C248.987 411.757 248.444 409.822 248.914 409.57C249.384 409.318 250.686 410.84 251.825 412.971C252.963 415.102 253.503 417.037 253.036 417.293C252.57 417.548 251.264 416.019 250.126 413.888Z"
          fill="white"
        />
        <path
          d="M245.666 405.533C244.527 403.402 243.984 401.467 244.454 401.215C244.924 400.963 246.226 402.485 247.365 404.619C248.503 406.754 249.046 408.685 248.576 408.937C248.106 409.189 246.804 407.667 245.666 405.533Z"
          fill="white"
        />
        <path
          d="M248.305 412.713C249.878 412.713 251.153 411.431 251.153 409.851C251.153 408.27 249.878 406.988 248.305 406.988C246.732 406.988 245.457 408.27 245.457 409.851C245.457 411.431 246.732 412.713 248.305 412.713Z"
          fill="#F1B52E"
        />
        <path
          d="M230.399 423.867C230.399 426.289 229.968 428.252 229.435 428.252C228.902 428.252 228.471 426.289 228.471 423.867C228.471 421.446 228.902 419.486 229.435 419.486C229.968 419.486 230.399 421.449 230.399 423.867Z"
          fill="white"
        />
        <path
          d="M230.399 433.351C230.399 435.769 229.968 437.732 229.435 437.732C228.902 437.732 228.471 435.769 228.471 433.351C228.471 430.933 228.902 428.97 229.435 428.97C229.968 428.97 230.399 430.929 230.399 433.351Z"
          fill="white"
        />
        <path
          d="M232.777 425.127C231.485 427.167 230.076 428.591 229.626 428.304C229.177 428.017 229.86 426.131 231.148 424.088C232.436 422.044 233.849 420.62 234.299 420.907C234.748 421.194 234.065 423.083 232.777 425.127Z"
          fill="white"
        />
        <path
          d="M227.719 433.13C226.431 435.174 225.021 436.598 224.586 436.311C224.151 436.024 224.819 434.134 226.107 432.094C227.395 430.054 228.809 428.627 229.258 428.914C229.707 429.201 229.011 431.087 227.719 433.13Z"
          fill="white"
        />
        <path
          d="M234.114 427.482C231.935 428.511 229.985 428.955 229.759 428.469C229.533 427.982 231.113 426.758 233.293 425.729C235.472 424.7 237.422 424.256 237.648 424.742C237.874 425.229 236.29 426.432 234.114 427.482Z"
          fill="white"
        />
        <path
          d="M225.577 431.502C223.398 432.531 221.448 432.975 221.222 432.492C220.995 432.01 222.576 430.781 224.756 429.749C226.935 428.717 228.885 428.276 229.111 428.762C229.337 429.249 227.77 430.473 225.577 431.502Z"
          fill="white"
        />
        <path
          d="M233.992 430.166C231.604 429.861 229.72 429.186 229.79 428.654C229.86 428.122 231.848 427.954 234.236 428.245C236.624 428.535 238.504 429.224 238.438 429.756C238.372 430.288 236.38 430.47 233.992 430.166Z"
          fill="white"
        />
        <path
          d="M224.638 428.973C222.249 428.669 220.366 427.993 220.435 427.465C220.505 426.937 222.493 426.748 224.881 427.052C227.27 427.356 229.15 428.035 229.084 428.564C229.017 429.092 227.023 429.277 224.638 428.973Z"
          fill="white"
        />
        <path
          d="M233.052 431.8C230.963 430.599 229.48 429.252 229.744 428.787C230.009 428.321 231.917 428.92 234.009 430.117C236.102 431.313 237.581 432.667 237.317 433.133C237.052 433.598 235.144 433 233.052 431.8Z"
          fill="white"
        />
        <path
          d="M224.86 427.101C222.772 425.901 221.288 424.55 221.553 424.085C221.818 423.619 223.725 424.218 225.818 425.418C227.91 426.618 229.39 427.969 229.125 428.431C228.861 428.892 226.953 428.301 224.86 427.101Z"
          fill="white"
        />
        <path
          d="M230.813 433.246C229.678 431.111 229.135 429.18 229.602 428.924C230.068 428.669 231.374 430.198 232.512 432.329C233.651 434.46 234.194 436.395 233.724 436.647C233.254 436.899 231.948 435.377 230.813 433.246Z"
          fill="white"
        />
        <path
          d="M226.357 424.889C225.219 422.758 224.676 420.823 225.146 420.571C225.616 420.319 226.914 421.841 228.053 423.972C229.191 426.103 229.734 428.038 229.264 428.294C228.794 428.549 227.496 427.02 226.357 424.889Z"
          fill="white"
        />
        <path
          d="M228.993 432.069C230.566 432.069 231.841 430.788 231.841 429.207C231.841 427.626 230.566 426.345 228.993 426.345C227.421 426.345 226.146 427.626 226.146 429.207C226.146 430.788 227.421 432.069 228.993 432.069Z"
          fill="#F1B52E"
        />
      </g>
      <path
        d="M206.322 438.13C200.419 438.168 194.525 437.679 188.709 436.668C188.488 436.626 188.277 436.54 188.09 436.414C187.903 436.288 187.743 436.125 187.62 435.935C187.498 435.746 187.415 435.533 187.376 435.31C187.338 435.087 187.344 434.858 187.396 434.638C187.495 434.222 187.748 433.859 188.104 433.625C188.46 433.391 188.892 433.302 189.311 433.379C196.273 434.564 203.34 435.013 210.395 434.719C222.473 434.285 240.601 431.618 259.098 421.485C259.481 421.277 259.931 421.228 260.349 421.349C260.768 421.47 261.122 421.752 261.337 422.132C261.445 422.326 261.514 422.54 261.54 422.76C261.566 422.981 261.547 423.205 261.486 423.419C261.425 423.632 261.322 423.831 261.183 424.005C261.045 424.178 260.873 424.322 260.679 424.428C241.572 434.894 222.859 437.63 210.392 438.074C208.981 438.112 207.624 438.13 206.322 438.13Z"
        fill="#F1B52E"
      />
      <path
        d="M188.729 401.991C189.112 394.062 188.144 381.913 186.881 370.401C187.793 370.608 192.768 401.935 193.227 428.609C189.196 428.112 186.79 427.601 186.79 427.601C186.79 427.601 187.807 421.114 188.729 401.991Z"
        fill="#363D47"
      />
      <path
        d="M249.485 396.018C246.801 401.106 241.763 400.025 234.838 399.598C226.483 399.083 203.696 392.012 203.696 392.012L244.625 377.196C245.119 379.243 245.558 381.252 245.917 383.204C246.742 387.57 247.936 391.856 249.485 396.018Z"
        fill="#363D47"
      />
      <path
        d="M207.717 438.739C196.427 438.739 188.694 437.2 188.607 437.182L188.813 436.154C188.91 436.171 198.746 438.127 212.431 437.599C225.045 437.112 243.608 434.351 260.407 423.977L260.953 424.869C243.953 435.366 225.188 438.165 212.442 438.648C210.813 438.711 209.232 438.739 207.717 438.739Z"
        fill="#363D47"
      />
      <path
        d="M245.179 487.244C244.727 487.244 244.445 486.579 241.59 478.696C240.117 474.633 238.658 470.511 238.645 470.469L239.63 470.119C241.179 474.511 243.491 480.967 244.762 484.266C244.803 478.559 244.849 464.021 244.873 455.619H245.917C245.917 456.854 245.834 485.95 245.775 486.726L245.74 487.15L245.312 487.23L245.179 487.244Z"
        fill="#363D47"
      />
      <path
        d="M182.71 339.147C182.71 339.147 172.517 339.091 170.818 358.074C169.119 377.057 171.514 407.111 170.818 415.649C170.121 424.187 170.469 429.995 172.517 441.948C174.564 453.901 179.313 477.125 179.313 477.125H189.089C189.089 477.125 186.303 433.067 189.089 419.407C191.874 405.746 187.129 352.808 182.71 339.147Z"
        fill="#F4937F"
      />
      <path
        d="M235.723 330.61C235.723 330.61 233.767 345.04 233.004 354.005C233.004 354.005 229.436 370.909 229.436 377.827C229.436 377.827 239.532 393.153 244.131 392.894C248.73 392.635 251.153 363.477 251.863 353.494C253.026 337.101 241.795 328.825 235.723 330.61Z"
        fill="#F4937F"
      />
      <path
        d="M196.88 492.566C195.373 488.777 193.949 482.419 193.298 481.604L193.214 481.506C192.13 480.196 191.121 478.826 190.192 477.401C188.58 474.952 181.224 472.282 179.34 477.401C179.34 477.401 179.18 480.526 179.114 483.78H179.086V485.159C179.086 486.208 179.086 487.206 179.103 488.087L179.145 497.133C179.145 497.133 183.393 504.593 184.183 504.831C184.973 505.069 188.127 508.33 188.127 508.33C188.319 508.411 188.528 508.44 188.734 508.416C188.94 508.391 189.137 508.313 189.304 508.19C190.244 509.075 191.111 509.971 191.111 509.971C191.111 509.971 192.796 510.692 192.772 508.193C194.105 509.026 195.362 509.838 195.362 509.838C195.362 509.838 195.86 510.888 195.261 507.039C195.031 505.548 190.185 501.405 190.185 501.024C190.185 500.642 188.253 495.464 188.253 495.464C188.396 493.329 189.343 490.53 190.108 488.563C191.703 490.974 192.786 493.688 193.291 496.538C193.291 496.538 196.615 501.087 197.935 499.949C199.254 498.812 198.391 496.373 196.88 492.566Z"
        fill="#F4937F"
      />
      <path
        d="M240.691 379.278C240.691 379.278 238.724 374.551 217.146 376.602C205.901 377.651 184.921 379.751 184.921 379.751V390.392C184.921 390.392 202.081 391.074 210.235 393.292C218.389 395.511 240.577 399.636 245.378 392.697C250.179 385.759 240.691 379.278 240.691 379.278Z"
        fill="#F4937F"
      />
      <path
        d="M178.974 377.627C177.4 377.904 175.04 378.967 173.118 380.094C172.076 380.666 171.111 381.372 170.249 382.193C170.202 382.243 170.162 382.298 170.127 382.358C168.912 383.289 167.763 384.184 167.69 384.321C166.618 385.969 167.168 387.407 167.659 388.327L167.489 388.597C167.286 388.913 167.158 389.272 167.116 389.646C167.074 390.02 167.118 390.398 167.245 390.752L168.338 393.842C168.33 394.085 168.342 394.329 168.373 394.57L168.536 395.836C168.579 396.168 168.719 396.478 168.94 396.728C169.16 396.977 169.45 397.155 169.772 397.236L169.153 401.638C169.153 401.638 170.124 403.573 172.192 401.288C174.26 399.003 174.26 397.712 174.26 397.712L179.2 394.661C181.553 393.398 183.822 391.983 185.993 390.427C188.036 389.027 188.618 379.384 188.618 379.384C188.618 379.384 181.578 377.172 178.974 377.627Z"
        fill="#F4937F"
      />
      <path
        d="M220.666 327.862C210.204 327.407 203.021 315.16 203.021 315.16H212.073C215.057 315.797 217.39 316.395 217.24 316.693C216.972 317.228 218.037 322.694 220.144 326.84C220.328 327.18 220.499 327.53 220.666 327.862Z"
        fill="#363D47"
      />
      <path
        d="M199.808 282.825C196.71 286.84 195.091 291.809 195.227 296.888C195.286 298.625 195.503 300.353 195.878 302.049C197.41 309.09 201.413 316.158 206.058 319.657C210.152 322.743 215.423 324.265 218.156 324.906C219.705 325.255 222.609 325.255 223.852 324.241C225.216 323.142 225.676 320.836 227.309 317.267C231.556 308.005 229.965 292.294 226.344 285.75C222.724 279.207 211.388 268.762 199.808 282.825Z"
        fill="#F4937F"
      />
      <path
        d="M215.429 285.796C215.429 285.796 211.314 289.473 207.958 296.37C204.602 303.267 195.215 304.033 195.215 304.033C195.215 304.033 191.038 295.145 196.521 285.029C202.004 274.913 212.686 273.535 217.563 275.834C222.441 278.133 223.054 279.053 223.054 279.053L215.429 285.796Z"
        fill="#363D47"
      />
      <path
        d="M214.058 281.964C214.019 282.08 214.172 282.349 214.5 282.738C215.478 283.91 218.023 286.181 221.682 288.707C226.556 292.08 230.254 300.762 230.337 296.986C230.355 296.045 230.222 283.959 223.054 279.053C215.885 274.147 214.058 281.964 214.058 281.964Z"
        fill="#363D47"
      />
      <path
        d="M199.115 299.936C198.893 299.694 194.311 295.261 193.966 301.006C193.621 306.752 197.253 313.768 200.184 311.927C203.116 310.087 201.629 302.654 199.115 299.936Z"
        fill="#F4937F"
      />
      <path
        d="M223.911 311.83C223.774 311.83 223.638 311.82 223.503 311.798C223.103 311.74 222.727 311.569 222.419 311.305C222.111 311.042 221.884 310.696 221.763 310.308L223.016 309.958C223.073 310.103 223.167 310.23 223.29 310.326C223.412 310.421 223.558 310.481 223.712 310.5C224.136 310.54 224.561 310.444 224.927 310.227C225.3 310.031 225.561 309.73 225.523 309.545C225.43 309.348 225.291 309.177 225.119 309.045L225.025 308.957C223.939 307.908 223.238 306.521 223.037 305.021L224.325 304.846C224.488 306.054 225.052 307.171 225.927 308.016L226.01 308.096C226.393 308.398 226.669 308.815 226.797 309.286C226.988 310.238 226.271 310.994 225.533 311.386C225.037 311.665 224.48 311.817 223.911 311.83Z"
        fill="#363D47"
      />
      <path
        d="M215.552 303.519C215.552 303.8 215.469 304.075 215.313 304.309C215.158 304.542 214.937 304.724 214.679 304.832C214.421 304.939 214.137 304.967 213.862 304.913C213.588 304.858 213.336 304.722 213.139 304.524C212.941 304.325 212.806 304.072 212.752 303.796C212.697 303.521 212.725 303.235 212.832 302.976C212.939 302.716 213.12 302.494 213.353 302.338C213.585 302.182 213.859 302.099 214.138 302.099C214.513 302.099 214.873 302.248 215.138 302.515C215.403 302.781 215.552 303.142 215.552 303.519Z"
        fill="#363D47"
      />
      <path
        d="M228.815 302.766C228.815 303.55 228.366 304.187 227.813 304.187C227.259 304.187 226.807 303.55 226.807 302.766C226.807 301.983 227.256 301.346 227.813 301.346C228.37 301.346 228.815 301.983 228.815 302.766Z"
        fill="#363D47"
      />
      <path
        d="M210.83 301.895L210.134 300.797C210.116 300.808 210.1 300.821 210.085 300.835L210.228 300.681C213.521 297.053 216.971 298.008 219.28 299.446L218.583 300.559C214.423 297.973 211.969 300.674 211.168 301.559C211.07 301.686 210.957 301.799 210.83 301.895Z"
        fill="#363D47"
      />
      <path
        d="M225.209 300.517L224.252 299.635C224.315 299.569 225.76 298.001 227.706 298.001C228.844 298.001 229.885 298.533 230.808 299.565L229.84 300.44C229.175 299.695 228.447 299.313 227.709 299.31C226.379 299.306 225.223 300.506 225.209 300.517Z"
        fill="#363D47"
      />
      <path
        d="M221.985 318.275C219.805 318.275 217.984 316.424 217.904 316.34L218.778 315.483C218.778 315.5 220.519 317.232 222.291 317.026C223.207 316.914 224.011 316.274 224.69 315.126L225.734 315.752C224.853 317.25 223.739 318.09 222.427 318.247C222.28 318.265 222.132 318.274 221.985 318.275Z"
        fill="#363D47"
      />
      <path
        d="M198.359 313.709C196.988 313.709 196.009 312.659 195.156 311.525L196.201 310.738C196.859 311.613 197.576 312.404 198.397 312.404C199.139 312.404 199.755 311.62 199.922 310.892C200.128 310.007 199.902 309.023 199.626 308.093L200.876 307.722C201.2 308.824 201.461 310.007 201.19 311.186C200.918 312.365 199.867 313.677 198.404 313.709H198.359Z"
        fill="#363D47"
      />
      <path
        d="M197.587 310.504C197.398 310.017 197.153 309.554 196.856 309.125C196.668 308.831 196.476 308.527 196.306 308.201C195.731 307.068 195.486 305.794 195.598 304.527C195.71 303.259 196.175 302.049 196.939 301.035L197.966 301.836C197.361 302.656 196.992 303.627 196.903 304.644C196.813 305.661 197.005 306.682 197.458 307.596C197.601 307.872 197.771 308.142 197.952 308.425C198.308 308.943 198.599 309.502 198.819 310.091L197.587 310.504Z"
        fill="#363D47"
      />
      <path
        d="M318.75 681.567H263.077V682.617H318.75V681.567Z"
        fill="#363D47"
      />
      <path
        d="M263.374 679.804H227.364V680.853H263.374V679.804Z"
        fill="#363D47"
      />
      <path
        d="M234.766 376.952C233.988 376.817 233.222 376.621 232.475 376.364C231.43 376.035 230.428 375.727 229.449 375.755L229.418 374.706C230.567 374.671 231.695 375.02 232.785 375.363C233.48 375.601 234.192 375.785 234.915 375.913L234.766 376.952Z"
        fill="#363D47"
      />
      <path
        d="M170.946 403.262L170.633 402.261C173.498 401.355 173.721 397.736 173.724 397.712V397.394L174.013 397.261C174.094 397.226 181.861 393.698 184.482 390.08L184.674 389.814L189.517 390.679L189.332 391.728L185.126 390.976C182.47 394.363 176.144 397.443 174.72 398.107C174.609 399.119 173.979 402.289 170.946 403.262Z"
        fill="#363D47"
      />
      <path
        d="M169.236 383.008L169.713 381.84C171.822 380.256 174.105 378.919 176.516 377.858C180.095 376.37 188.402 378.799 188.754 378.907L188.472 379.87C188.392 379.845 180.151 377.441 176.896 378.792C173.543 380.178 169.63 383.208 169.236 383.008Z"
        fill="#363D47"
      />
      <mask
        id="mask1_29_5930"
        maskUnits="userSpaceOnUse"
        x="167"
        y="377"
        width="22"
        height="26"
      >
        <path
          d="M178.974 377.627C177.401 377.904 175.04 378.967 173.118 380.094C171.862 380.832 167.764 384.177 167.677 384.314C166.604 385.962 167.155 387.4 167.645 388.32L167.475 388.59C167.272 388.906 167.145 389.265 167.103 389.639C167.06 390.013 167.104 390.391 167.231 390.745L168.324 393.835C168.317 394.078 168.328 394.321 168.359 394.563L168.523 395.829C168.565 396.161 168.706 396.471 168.926 396.721C169.147 396.97 169.437 397.148 169.759 397.229L169.139 401.631C169.139 401.631 170.11 403.566 172.178 401.281C174.246 398.996 174.246 397.705 174.246 397.705L179.187 394.654C181.544 393.394 183.817 391.982 185.993 390.427C188.037 389.027 188.618 379.384 188.618 379.384C188.618 379.384 181.578 377.172 178.974 377.627Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_29_5930)">
        <path
          d="M168.655 398.654C168.568 398.433 168.498 398.258 169.418 395.701C170.019 394.038 171.153 392.624 172.641 391.68L173.968 390.844L174.522 391.733L173.195 392.569C171.906 393.393 170.927 394.624 170.41 396.068C169.714 397.989 169.668 398.367 169.665 398.423C169.665 398.374 169.655 398.325 169.637 398.279L168.655 398.654Z"
          fill="#363D47"
        />
        <path
          d="M168.262 395.018L167.26 394.731L167.987 392.148C168.275 391.129 168.886 390.232 169.728 389.594C172.722 387.354 173.081 387.211 173.21 387.145L173.596 388.117C173.533 388.145 173.063 388.39 170.344 390.423C169.681 390.919 169.199 391.622 168.973 392.421L168.262 395.018Z"
          fill="#363D47"
        />
        <path
          d="M166.769 389.563L165.808 389.143C166.378 387.979 167.182 386.947 168.17 386.112C169.157 385.277 170.307 384.658 171.545 384.293L171.834 385.304C170.746 385.629 169.735 386.175 168.863 386.907C167.992 387.64 167.279 388.543 166.769 389.563Z"
          fill="#363D47"
        />
      </g>
      <path
        d="M185.05 390.885L184.793 389.867L184.921 390.378L184.789 389.871C185.291 389.717 185.744 389.434 186.104 389.051C186.464 388.667 186.719 388.196 186.843 387.684L187.86 387.922C187.692 388.62 187.344 389.262 186.851 389.782C186.357 390.303 185.736 390.683 185.05 390.885Z"
        fill="#363D47"
      />
      <path
        d="M180.186 410.354L179.932 409.336C180.151 409.28 185.352 407.982 189.022 409.354L188.674 410.337C185.297 409.077 180.238 410.337 180.186 410.354Z"
        fill="#363D47"
      />
      <mask
        id="mask2_29_5930"
        maskUnits="userSpaceOnUse"
        x="215"
        y="423"
        width="77"
        height="230"
      >
        <path
          d="M215.447 437.958L259.846 423.164C259.846 423.164 289.07 510.562 291.789 537.9L278.086 652.57C278.086 652.57 244.218 655.401 227.225 646.52C227.225 646.52 232.074 631.946 236.061 616.466C250.335 561.043 244.897 532.452 244.897 532.452C244.897 532.452 214.541 457.536 215.447 437.958Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_29_5930)">
        <path
          d="M274.396 549.412C274.443 549.392 274.486 549.362 274.522 549.325C274.558 549.288 274.586 549.244 274.605 549.195L273.637 548.796C273.839 548.3 274.35 548.31 275.865 548.342C279.91 548.426 290.651 548.653 296.771 537.823L297.68 538.341C291.25 549.717 280.057 549.479 275.844 549.391C275.361 549.361 274.877 549.368 274.396 549.412Z"
          fill="#363D47"
        />
      </g>
      <path
        d="M234.254 623.233L239.128 650.617L245.896 654.718L244.904 650.617C239.977 636.271 234.261 623.233 234.261 623.233H234.254Z"
        fill="#363D47"
      />
      <path
        d="M193.697 474.721L192.761 474.255C198.679 462.173 198.105 452.561 197.798 447.421C197.649 444.929 197.576 443.68 198.31 443.222L198.871 444.107C198.648 444.366 198.739 445.895 198.826 447.379C199.153 452.61 199.734 462.386 193.697 474.721Z"
        fill="#363D47"
      />
      <path d="M420 688.961H112.815V690.011H420V688.961Z" fill="#363D47" />
      <path
        d="M169.056 688.968L8.31055 727V203L169.056 241.032V688.968Z"
        fill="#349898"
      />
      <path
        d="M42.9727 465.546V238.988L149.288 260.455V461.119L42.9727 465.546ZM44.0171 240.269V464.45L148.243 460.112V261.316L44.0171 240.269Z"
        fill="#363D47"
      />
      <path
        d="M42.9727 690.123V483.356L149.288 476.564V669.17L148.867 669.254L42.9727 690.123ZM44.0171 484.343V688.845L148.243 668.309V477.684L44.0171 484.343Z"
        fill="#363D47"
      />
      <path
        d="M35.3412 519.712L19.0303 522.445V421.278H35.3412V519.712Z"
        fill="#FAE6C9"
      />
      <path
        d="M63.7152 440.258C56.6478 440.08 45.0787 445.423 40.5562 447.309H25.4951V457.554H42.1472C42.9493 457.555 43.7401 457.366 44.4553 457.001C45.1705 456.636 45.7897 456.106 46.2624 455.455C54.3291 449.93 61.0519 447.659 64.4881 446.781C65.1942 446.597 65.8195 446.183 66.2658 445.604C66.712 445.024 66.9538 444.312 66.953 443.579C66.9559 442.709 66.6178 441.873 66.0118 441.251C65.4059 440.63 64.5809 440.273 63.7152 440.258Z"
        fill="#FAE6C9"
      />
      <path
        d="M35.3406 457.029H25.4844V458.079H35.3406V457.029Z"
        fill="#363D47"
      />
      <path
        d="M35.3406 446.783H25.4844V447.833H35.3406V446.783Z"
        fill="#363D47"
      />
      <path
        d="M20.5444 522.445C20.5444 522.193 20.4608 496.95 20.419 471.988C20.3947 457.33 20.3877 445.632 20.4016 437.227C20.4295 421.177 20.4747 421.131 20.6941 420.907L21.0666 420.75V421.8C21.185 421.801 21.3003 421.761 21.3924 421.686C21.4846 421.612 21.5479 421.507 21.5714 421.39C21.3242 423.122 21.4739 488.248 21.5714 522.441L20.5444 522.445Z"
        fill="#363D47"
      />
      <path
        d="M31.0902 481.642C29.0988 481.642 28.0195 478.031 28.0195 474.644C28.0195 471.257 29.0988 467.646 31.0902 467.646C33.0816 467.646 34.1644 471.257 34.1644 474.644C34.1644 478.031 33.0851 481.642 31.0902 481.642ZM31.0902 468.674C30.2442 468.674 29.064 470.942 29.064 474.623C29.064 478.304 30.2442 480.571 31.0902 480.571C31.9362 480.571 33.12 478.304 33.12 474.623C33.12 470.942 31.9362 468.674 31.0902 468.674Z"
        fill="#363D47"
      />
      <path
        d="M148.923 235.738L148.684 236.76L168.973 241.561L169.212 240.54L148.923 235.738Z"
        fill="#363D47"
      />
      <path
        d="M379.747 689.486H378.703V227.55H159.433V239.629H158.389V226.5H379.747V689.486Z"
        fill="#363D47"
      />
      <g filter="url(#filter0_d_29_5930)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M383.5 322C429.616 322 467 284.616 467 238.5C467 192.384 429.616 155 383.5 155C337.384 155 300 192.384 300 238.5C300 284.616 337.384 322 383.5 322Z"
          fill="#FFFAF7"
        />
      </g>
      <path
        d="M455 238.5C455 277.988 422.988 310 383.5 310C344.012 310 312 277.988 312 238.5C312 199.012 344.012 167 383.5 167C422.988 167 455 199.012 455 238.5Z"
        fill="url(#paint0_linear_29_5930)"
        stroke="white"
        strokeWidth="8"
        strokeLinejoin="round"
      />
      <path
        d="M359 237.443L376.279 255L409 223"
        stroke="white"
        strokeWidth="9.19206"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M595.612 353.523C601.51 353.523 606.292 348.742 606.292 342.844C606.292 336.945 601.51 332.164 595.612 332.164C589.714 332.164 584.933 336.945 584.933 342.844C584.933 348.742 589.714 353.523 595.612 353.523Z"
        fill="url(#paint1_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M170.68 210.755C176.578 210.755 181.359 205.973 181.359 200.075C181.359 194.177 176.578 189.396 170.68 189.396C164.781 189.396 160 194.177 160 200.075C160 205.973 164.781 210.755 170.68 210.755Z"
        fill="url(#paint2_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M525.914 332.164C528.708 332.164 530.973 329.899 530.973 327.106C530.973 324.312 528.708 322.047 525.914 322.047C523.12 322.047 520.855 324.312 520.855 327.106C520.855 329.899 523.12 332.164 525.914 332.164Z"
        fill="url(#paint3_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M540.528 299.563C542.701 299.563 544.463 297.802 544.463 295.629C544.463 293.456 542.701 291.694 540.528 291.694C538.355 291.694 536.594 293.456 536.594 295.629C536.594 297.802 538.355 299.563 540.528 299.563Z"
        fill="url(#paint4_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M532.66 251.225C534.833 251.225 536.595 249.463 536.595 247.29C536.595 245.117 534.833 243.355 532.66 243.355C530.487 243.355 528.726 245.117 528.726 247.29C528.726 249.463 530.487 251.225 532.66 251.225Z"
        fill="url(#paint5_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M498.372 318.675C500.235 318.675 501.745 317.165 501.745 315.302C501.745 313.44 500.235 311.93 498.372 311.93C496.51 311.93 495 313.44 495 315.302C495 317.165 496.51 318.675 498.372 318.675Z"
        fill="url(#paint6_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M477.014 367.014C478.256 367.014 479.262 366.008 479.262 364.766C479.262 363.524 478.256 362.518 477.014 362.518C475.772 362.518 474.766 363.524 474.766 364.766C474.766 366.008 475.772 367.014 477.014 367.014Z"
        fill="url(#paint7_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M489.379 171.409C490.621 171.409 491.628 170.402 491.628 169.16C491.628 167.919 490.621 166.912 489.379 166.912C488.137 166.912 487.131 167.919 487.131 169.16C487.131 170.402 488.137 171.409 489.379 171.409Z"
        fill="url(#paint8_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M579.312 208.506C580.554 208.506 581.56 207.5 581.56 206.258C581.56 205.016 580.554 204.01 579.312 204.01C578.07 204.01 577.063 205.016 577.063 206.258C577.063 207.5 578.07 208.506 579.312 208.506Z"
        fill="url(#paint9_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M418.557 136.56C419.799 136.56 420.805 135.554 420.805 134.312C420.805 133.07 419.799 132.063 418.557 132.063C417.315 132.063 416.309 133.07 416.309 134.312C416.309 135.554 417.315 136.56 418.557 136.56Z"
        fill="url(#paint10_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M331.435 138.809C333.608 138.809 335.369 137.047 335.369 134.874C335.369 132.701 333.608 130.939 331.435 130.939C329.262 130.939 327.5 132.701 327.5 134.874C327.5 137.047 329.262 138.809 331.435 138.809Z"
        fill="url(#paint11_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M245.435 141.057C248.54 141.057 251.056 138.541 251.056 135.436C251.056 132.332 248.54 129.815 245.435 129.815C242.331 129.815 239.814 132.332 239.814 135.436C239.814 138.541 242.331 141.057 245.435 141.057Z"
        fill="url(#paint12_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M288.717 119.698C290.89 119.698 292.651 117.937 292.651 115.764C292.651 113.591 290.89 111.829 288.717 111.829C286.544 111.829 284.782 113.591 284.782 115.764C284.782 117.937 286.544 119.698 288.717 119.698Z"
        fill="url(#paint13_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M208.339 79.2284C210.202 79.2284 211.712 77.7185 211.712 75.8559C211.712 73.9933 210.202 72.4834 208.339 72.4834C206.477 72.4834 204.967 73.9933 204.967 75.8559C204.967 77.7185 206.477 79.2284 208.339 79.2284Z"
        fill="url(#paint14_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M222.953 246.728C224.815 246.728 226.325 245.218 226.325 243.356C226.325 241.493 224.815 239.983 222.953 239.983C221.09 239.983 219.58 241.493 219.58 243.356C219.58 245.218 221.09 246.728 222.953 246.728Z"
        fill="url(#paint15_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M271.292 325.42C273.155 325.42 274.665 323.91 274.665 322.047C274.665 320.185 273.155 318.675 271.292 318.675C269.43 318.675 267.92 320.185 267.92 322.047C267.92 323.91 269.43 325.42 271.292 325.42Z"
        fill="url(#paint16_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.58 385C222.684 385 225.201 382.484 225.201 379.38C225.201 376.275 222.684 373.759 219.58 373.759C216.476 373.759 213.959 376.275 213.959 379.38C213.959 382.484 216.476 385 219.58 385Z"
        fill="url(#paint17_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M329.749 385C331.612 385 333.122 383.49 333.122 381.627C333.122 379.765 331.612 378.255 329.749 378.255C327.887 378.255 326.377 379.765 326.377 381.627C326.377 383.49 327.887 385 329.749 385Z"
        fill="url(#paint18_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M230.823 302.936C232.064 302.936 233.071 301.929 233.071 300.688C233.071 299.446 232.064 298.439 230.823 298.439C229.581 298.439 228.574 299.446 228.574 300.688C228.574 301.929 229.581 302.936 230.823 302.936Z"
        fill="url(#paint19_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M394.95 114.078C398.054 114.078 400.571 111.561 400.571 108.457C400.571 105.352 398.054 102.836 394.95 102.836C391.846 102.836 389.329 105.352 389.329 108.457C389.329 111.561 391.846 114.078 394.95 114.078Z"
        fill="url(#paint20_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M383.71 61.2416C386.814 61.2416 389.33 58.7251 389.33 55.6208C389.33 52.5165 386.814 50 383.71 50C380.605 50 378.089 52.5165 378.089 55.6208C378.089 58.7251 380.605 61.2416 383.71 61.2416Z"
        fill="url(#paint21_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M420.243 79.2285C422.416 79.2285 424.178 77.4669 424.178 75.2939C424.178 73.1209 422.416 71.3594 420.243 71.3594C418.07 71.3594 416.309 73.1209 416.309 75.2939C416.309 77.4669 418.07 79.2285 420.243 79.2285Z"
        fill="url(#paint22_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M522.543 107.332C524.716 107.332 526.478 105.57 526.478 103.397C526.478 101.224 524.716 99.4629 522.543 99.4629C520.37 99.4629 518.608 101.224 518.608 103.397C518.608 105.57 520.37 107.332 522.543 107.332Z"
        fill="url(#paint23_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M509.615 143.306C511.477 143.306 512.987 141.796 512.987 139.933C512.987 138.07 511.477 136.561 509.615 136.561C507.752 136.561 506.242 138.07 506.242 139.933C506.242 141.796 507.752 143.306 509.615 143.306Z"
        fill="url(#paint24_linear_29_5930)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M575.939 189.395C577.801 189.395 579.311 187.885 579.311 186.023C579.311 184.16 577.801 182.65 575.939 182.65C574.076 182.65 572.566 184.16 572.566 186.023C572.566 187.885 574.076 189.395 575.939 189.395Z"
        fill="url(#paint25_linear_29_5930)"
      />
      <defs>
        <filter
          id="filter0_d_29_5930"
          x="280"
          y="155"
          width="207"
          height="207"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="10" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.843137 0 0 0 0 0.843137 0 0 0 0 0.843137 0 0 0 0.236533 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_29_5930"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_29_5930"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_29_5930"
          x1="326.456"
          y1="112.667"
          x2="460.76"
          y2="119.462"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_29_5930"
          x1="587.543"
          y1="325.044"
          x2="606.541"
          y2="326.006"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_29_5930"
          x1="162.611"
          y1="182.276"
          x2="181.608"
          y2="183.237"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_29_5930"
          x1="522.092"
          y1="318.674"
          x2="531.091"
          y2="319.13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_29_5930"
          x1="537.556"
          y1="289.071"
          x2="544.555"
          y2="289.425"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_29_5930"
          x1="529.687"
          y1="240.732"
          x2="536.686"
          y2="241.087"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_29_5930"
          x1="495.824"
          y1="309.681"
          x2="501.824"
          y2="309.985"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_29_5930"
          x1="475.315"
          y1="361.019"
          x2="479.315"
          y2="361.221"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_29_5930"
          x1="487.68"
          y1="165.413"
          x2="491.68"
          y2="165.616"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_29_5930"
          x1="577.613"
          y1="202.511"
          x2="581.613"
          y2="202.713"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_29_5930"
          x1="416.858"
          y1="130.565"
          x2="420.858"
          y2="130.767"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_29_5930"
          x1="328.462"
          y1="128.316"
          x2="335.461"
          y2="128.671"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_29_5930"
          x1="241.188"
          y1="126.068"
          x2="251.187"
          y2="126.574"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_29_5930"
          x1="285.744"
          y1="109.206"
          x2="292.743"
          y2="109.56"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_29_5930"
          x1="205.791"
          y1="70.2351"
          x2="211.79"
          y2="70.5386"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_29_5930"
          x1="220.404"
          y1="237.735"
          x2="226.404"
          y2="238.039"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_29_5930"
          x1="268.744"
          y1="316.426"
          x2="274.744"
          y2="316.73"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_29_5930"
          x1="215.333"
          y1="370.012"
          x2="225.332"
          y2="370.517"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_29_5930"
          x1="327.201"
          y1="376.007"
          x2="333.201"
          y2="376.31"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_29_5930"
          x1="229.124"
          y1="296.941"
          x2="233.123"
          y2="297.143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_29_5930"
          x1="390.703"
          y1="99.0887"
          x2="400.702"
          y2="99.5946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_29_5930"
          x1="379.463"
          y1="46.2528"
          x2="389.462"
          y2="46.7587"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_29_5930"
          x1="417.27"
          y1="68.7363"
          x2="424.269"
          y2="69.0904"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_29_5930"
          x1="519.57"
          y1="96.8399"
          x2="526.569"
          y2="97.194"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_29_5930"
          x1="507.067"
          y1="134.312"
          x2="513.066"
          y2="134.616"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_29_5930"
          x1="573.391"
          y1="180.402"
          x2="579.39"
          y2="180.706"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
      </defs>
    </svg>
  )
}
