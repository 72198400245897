import { type VariantProps } from 'class-variance-authority'
import * as React from 'react'
import { cn } from 'utils-tailwindcss'
import { notificationBadgeVariants } from './notification-badge.styles'

export interface NotificationBadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof notificationBadgeVariants> {}

function NotificationBadge({
  className,
  variant,
  ...props
}: NotificationBadgeProps) {
  return (
    <span
      className={cn(notificationBadgeVariants({ variant }), className)}
      {...props}
    />
  )
}

export { NotificationBadge, notificationBadgeVariants }
