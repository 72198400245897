import { IconProps } from '../icons'

export const RegisterCompleted2Illustration = ({
  size = 20,
  ...rest
}: IconProps) => {
  return (
    <svg
      width="342"
      height="256"
      viewBox="0 0 342 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M333.508 231.946C338.015 231.946 341.669 228.292 341.669 223.785C341.669 219.278 338.015 215.624 333.508 215.624C329.001 215.624 325.347 219.278 325.347 223.785C325.347 228.292 329.001 231.946 333.508 231.946Z"
        fill="url(#paint0_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.78314 122.846C13.2904 122.846 16.9442 119.192 16.9442 114.685C16.9442 110.177 13.2904 106.523 8.78314 106.523C4.27591 106.523 0.62207 110.177 0.62207 114.685C0.62207 119.192 4.27591 122.846 8.78314 122.846Z"
        fill="url(#paint1_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M280.246 215.624C282.381 215.624 284.111 213.893 284.111 211.758C284.111 209.623 282.381 207.893 280.246 207.893C278.111 207.893 276.38 209.623 276.38 211.758C276.38 213.893 278.111 215.624 280.246 215.624Z"
        fill="url(#paint2_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M291.414 190.712C293.074 190.712 294.421 189.366 294.421 187.705C294.421 186.044 293.074 184.698 291.414 184.698C289.753 184.698 288.407 186.044 288.407 187.705C288.407 189.366 289.753 190.712 291.414 190.712Z"
        fill="url(#paint3_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M285.401 153.771C287.062 153.771 288.408 152.425 288.408 150.765C288.408 149.104 287.062 147.758 285.401 147.758C283.741 147.758 282.395 149.104 282.395 150.765C282.395 152.425 283.741 153.771 285.401 153.771Z"
        fill="url(#paint4_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M259.199 205.315C260.623 205.315 261.776 204.162 261.776 202.738C261.776 201.315 260.623 200.161 259.199 200.161C257.776 200.161 256.622 201.315 256.622 202.738C256.622 204.162 257.776 205.315 259.199 205.315Z"
        fill="url(#paint5_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M242.877 242.256C243.826 242.256 244.595 241.486 244.595 240.537C244.595 239.589 243.826 238.819 242.877 238.819C241.928 238.819 241.159 239.589 241.159 240.537C241.159 241.486 241.928 242.256 242.877 242.256Z"
        fill="url(#paint6_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M252.327 92.778C253.275 92.778 254.045 92.0088 254.045 91.0599C254.045 90.111 253.275 89.3418 252.327 89.3418C251.378 89.3418 250.608 90.111 250.608 91.0599C250.608 92.0088 251.378 92.778 252.327 92.778Z"
        fill="url(#paint7_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M321.051 121.128C322 121.128 322.769 120.358 322.769 119.41C322.769 118.461 322 117.691 321.051 117.691C320.102 117.691 319.333 118.461 319.333 119.41C319.333 120.358 320.102 121.128 321.051 121.128Z"
        fill="url(#paint8_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198.205 66.1472C199.154 66.1472 199.924 65.378 199.924 64.4291C199.924 63.4802 199.154 62.7109 198.205 62.7109C197.257 62.7109 196.487 63.4802 196.487 64.4291C196.487 65.378 197.257 66.1472 198.205 66.1472Z"
        fill="url(#paint9_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M131.629 67.866C133.289 67.866 134.635 66.5198 134.635 64.8593C134.635 63.1987 133.289 61.8525 131.629 61.8525C129.968 61.8525 128.622 63.1987 128.622 64.8593C128.622 66.5198 129.968 67.866 131.629 67.866Z"
        fill="url(#paint10_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.9096 69.5838C68.2818 69.5838 70.2049 67.6607 70.2049 65.2885C70.2049 62.9162 68.2818 60.9932 65.9096 60.9932C63.5373 60.9932 61.6143 62.9162 61.6143 65.2885C61.6143 67.6607 63.5373 69.5838 65.9096 69.5838Z"
        fill="url(#paint11_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.9852 53.2615C100.646 53.2615 101.992 51.9153 101.992 50.2548C101.992 48.5942 100.646 47.248 98.9852 47.248C97.3247 47.248 95.9785 48.5942 95.9785 50.2548C95.9785 51.9153 97.3247 53.2615 98.9852 53.2615Z"
        fill="url(#paint12_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.5616 22.336C38.9849 22.336 40.1387 21.1822 40.1387 19.7588C40.1387 18.3355 38.9849 17.1816 37.5616 17.1816C36.1382 17.1816 34.9844 18.3355 34.9844 19.7588C34.9844 21.1822 36.1382 22.336 37.5616 22.336Z"
        fill="url(#paint13_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.7295 150.336C50.1529 150.336 51.3067 149.182 51.3067 147.759C51.3067 146.335 50.1529 145.182 48.7295 145.182C47.3062 145.182 46.1523 146.335 46.1523 147.759C46.1523 149.182 47.3062 150.336 48.7295 150.336Z"
        fill="url(#paint14_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.669 210.47C87.0923 210.47 88.2462 209.316 88.2462 207.893C88.2462 206.469 87.0923 205.315 85.669 205.315C84.2456 205.315 83.0918 206.469 83.0918 207.893C83.0918 209.316 84.2456 210.47 85.669 210.47Z"
        fill="url(#paint15_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.1517 256C48.524 256 50.447 254.077 50.447 251.704C50.447 249.332 48.524 247.409 46.1517 247.409C43.7795 247.409 41.8564 249.332 41.8564 251.704C41.8564 254.077 43.7795 256 46.1517 256Z"
        fill="url(#paint16_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130.341 256C131.764 256 132.918 254.846 132.918 253.423C132.918 252 131.764 250.846 130.341 250.846C128.918 250.846 127.764 252 127.764 253.423C127.764 254.846 128.918 256 130.341 256Z"
        fill="url(#paint17_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.7435 193.289C55.6924 193.289 56.4616 192.52 56.4616 191.571C56.4616 190.622 55.6924 189.853 54.7435 189.853C53.7946 189.853 53.0254 190.622 53.0254 191.571C53.0254 192.52 53.7946 193.289 54.7435 193.289Z"
        fill="url(#paint18_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.165 48.9666C182.538 48.9666 184.461 47.0435 184.461 44.6713C184.461 42.299 182.538 40.376 180.165 40.376C177.793 40.376 175.87 42.299 175.87 44.6713C175.87 47.0435 177.793 48.9666 180.165 48.9666Z"
        fill="url(#paint19_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171.577 8.5906C173.949 8.5906 175.872 6.66753 175.872 4.2953C175.872 1.92307 173.949 0 171.577 0C169.204 0 167.281 1.92307 167.281 4.2953C167.281 6.66753 169.204 8.5906 171.577 8.5906Z"
        fill="url(#paint20_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M199.494 22.3357C201.155 22.3357 202.501 20.9895 202.501 19.329C202.501 17.6684 201.155 16.3223 199.494 16.3223C197.833 16.3223 196.487 17.6684 196.487 19.329C196.487 20.9895 197.833 22.3357 199.494 22.3357Z"
        fill="url(#paint21_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M277.67 43.8123C279.33 43.8123 280.677 42.4661 280.677 40.8055C280.677 39.145 279.33 37.7988 277.67 37.7988C276.009 37.7988 274.663 39.145 274.663 40.8055C274.663 42.4661 276.009 43.8123 277.67 43.8123Z"
        fill="url(#paint22_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M267.79 71.3018C269.213 71.3018 270.367 70.148 270.367 68.7246C270.367 67.3013 269.213 66.1475 267.79 66.1475C266.367 66.1475 265.213 67.3013 265.213 68.7246C265.213 70.148 266.367 71.3018 267.79 71.3018Z"
        fill="url(#paint23_linear_29_4804)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M318.474 106.524C319.897 106.524 321.051 105.37 321.051 103.946C321.051 102.523 319.897 101.369 318.474 101.369C317.05 101.369 315.896 102.523 315.896 103.946C315.896 105.37 317.05 106.524 318.474 106.524Z"
        fill="url(#paint24_linear_29_4804)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_29_4804"
          x1="327.342"
          y1="210.183"
          x2="341.859"
          y2="210.918"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_29_4804"
          x1="2.617"
          y1="101.083"
          x2="17.1345"
          y2="101.817"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_29_4804"
          x1="277.325"
          y1="205.315"
          x2="284.202"
          y2="205.663"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_29_4804"
          x1="289.142"
          y1="182.694"
          x2="294.491"
          y2="182.964"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_29_4804"
          x1="283.13"
          y1="145.753"
          x2="288.478"
          y2="146.024"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_29_4804"
          x1="257.252"
          y1="198.443"
          x2="261.837"
          y2="198.675"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_29_4804"
          x1="241.579"
          y1="237.674"
          x2="244.635"
          y2="237.829"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_29_4804"
          x1="251.028"
          y1="88.1964"
          x2="254.085"
          y2="88.351"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_29_4804"
          x1="319.753"
          y1="116.546"
          x2="322.809"
          y2="116.701"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_29_4804"
          x1="196.907"
          y1="61.5655"
          x2="199.964"
          y2="61.7202"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_29_4804"
          x1="129.357"
          y1="59.8481"
          x2="134.706"
          y2="60.1187"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_29_4804"
          x1="62.6642"
          y1="58.1296"
          x2="70.305"
          y2="58.5162"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_29_4804"
          x1="96.7135"
          y1="45.2436"
          x2="102.062"
          y2="45.5142"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_29_4804"
          x1="35.6144"
          y1="15.4635"
          x2="40.1988"
          y2="15.6955"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_29_4804"
          x1="46.7823"
          y1="143.464"
          x2="51.3668"
          y2="143.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_29_4804"
          x1="83.7218"
          y1="203.597"
          x2="88.3062"
          y2="203.829"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_29_4804"
          x1="42.9064"
          y1="244.546"
          x2="50.5472"
          y2="244.932"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_29_4804"
          x1="128.394"
          y1="249.128"
          x2="132.978"
          y2="249.36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_29_4804"
          x1="53.4454"
          y1="188.707"
          x2="56.5017"
          y2="188.862"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_29_4804"
          x1="176.92"
          y1="37.5124"
          x2="184.561"
          y2="37.899"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_29_4804"
          x1="168.331"
          y1="-2.86353"
          x2="175.972"
          y2="-2.47696"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_29_4804"
          x1="197.222"
          y1="14.3178"
          x2="202.571"
          y2="14.5884"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_29_4804"
          x1="275.398"
          y1="35.7944"
          x2="280.747"
          y2="36.065"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_29_4804"
          x1="265.843"
          y1="64.4293"
          x2="270.427"
          y2="64.6613"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_29_4804"
          x1="316.526"
          y1="99.651"
          x2="321.111"
          y2="99.883"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89318" />
          <stop offset="1" stopColor="#EA8407" />
        </linearGradient>
      </defs>
    </svg>
  )
}
