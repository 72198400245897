'use client'

import { format } from 'date-fns'
import { enUS, es } from 'date-fns/locale'
import { parseDateToISO } from 'libs'
import { Calendar as CalendarIcon } from 'lucide-react'
import { forwardRef, useEffect, useState } from 'react'
import { DateRange } from 'react-day-picker'
import { cn } from 'utils-tailwindcss'
import { DatePickerProps } from './types'
import { SelectStyles } from '../select/select.styles'
import { Popover, PopoverContent, PopoverTrigger } from '../popover'
import { Button } from '../button'
import { Calendar } from '../calendar'

export type { DateRange }

export const isDateRange = (
  date: Date | Partial<DateRange>
): date is DateRange => typeof date === 'object' && 'from' in date

const getDefaultValue = (date: Date | null | string | undefined) => {
  if (!date) return

  return typeof date === 'string' ? parseDateToISO(date) : date
}

export const DatePicker = forwardRef<HTMLButtonElement, DatePickerProps>(
  (
    {
      placeholder = 'Pick a date',
      defaultValue,
      onChange,
      single,
      locale = 'es',
      numberOfMonths = 1,
      fromYear = new Date().getFullYear() - 96,
      toYear = new Date().getFullYear() - 19,
      disabled,
      popoverClassnames,
      disabledDate,
      readOnly,
      id,
      popoverContentProps,
    },
    ref
  ) => {
    const [open, setOpen] = useState(false)
    const [date, setDate] = useState<Date | undefined>(
      single ? getDefaultValue(defaultValue) : undefined
    )
    const [dateRange, setDateRange] = useState<DateRange | undefined>(
      !single && defaultValue ? (defaultValue as DateRange) : undefined
    )

    useEffect(() => {
      if (single) {
        setDate(getDefaultValue(defaultValue))
      } else {
        setDateRange(defaultValue as DateRange)
      }
    }, [defaultValue, single])

    const currentLocale = locale === 'es' ? es : enUS
    const formattedDate =
      date &&
      format(date, 'PP', {
        locale: currentLocale,
      })

    const singleText =
      formattedDate && single ? formattedDate : <span>{placeholder}</span>

    const rangeDateText =
      !single && dateRange?.from ? (
        dateRange.to ? (
          <>
            {format(dateRange.from, 'LLL dd, y', { locale: currentLocale })} -{' '}
            {format(dateRange.to, 'LLL dd, y', { locale: currentLocale })}
          </>
        ) : (
          format(dateRange.from, 'LLL dd, y', { locale: currentLocale })
        )
      ) : (
        <span>{placeholder}</span>
      )

    const onSelect = (dateValue: Date | DateRange | undefined) => {
      if (single) {
        setDate(dateValue as Date)
        onChange?.(dateValue as Date)
        setOpen(false)
      } else {
        const dateRangeValue = dateValue as DateRange
        setDateRange(dateRangeValue)
        onChange?.(dateRangeValue)
      }
    }

    return (
      <Popover open={open} onOpenChange={setOpen} modal={true}>
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            id={!readOnly ? id : undefined}
            variant="outline"
            disabled={disabled}
            className={cn(
              SelectStyles.trigger(),
              'w-full justify-start text-left font-normal',
              {
                'text-muted-foreground':
                  !date && !dateRange?.from && !dateRange?.to && !defaultValue,
                'pointer-events-none': readOnly,
              }
            )}
          >
            <CalendarIcon className="w-4 h-4 mr-2" />
            {single ? singleText : rangeDateText}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className={cn('w-auto p-0', popoverClassnames)}
          onOpenAutoFocus={(e) => e.preventDefault()}
          {...popoverContentProps}
        >
          {single ? (
            <Calendar
              mode="single"
              selected={date}
              onSelect={onSelect}
              locale={currentLocale}
              fromYear={fromYear}
              toYear={toYear}
              today={date || new Date()}
              initialFocus
              disabled={disabledDate}
              numberOfMonths={numberOfMonths}
            />
          ) : (
            <Calendar
              disabled={disabledDate}
              initialFocus
              mode="range"
              fromYear={fromYear}
              toYear={toYear}
              locale={currentLocale}
              today={date}
              defaultMonth={dateRange?.from}
              selected={dateRange}
              onSelect={onSelect}
              numberOfMonths={numberOfMonths}
            />
          )}
        </PopoverContent>
      </Popover>
    )
  }
)

DatePicker.displayName = 'DatePicker'
