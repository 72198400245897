'use client'

import { clearPhonePrefix } from 'libs'
import type {
  ChangeEventHandler,
  ForwardedRef,
  InputHTMLAttributes,
} from 'react'
import { forwardRef } from 'react'
import { cn } from 'utils-tailwindcss'
import { handleAcceptOnlyNumbers } from '../../libs'
import { CustomInputStyles } from '../input/input.style'
import { CuIcon } from '../icons'

type CubanPhoneInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'value' | 'onChange'
> & {
  containerClassName?: string
  className?: string
  value?: string
  onChange: ChangeEventHandler<HTMLInputElement>
}

export const maskCubanPhone = (value: string) => {
  return value.replace(/\D/g, '').replace(/(\d{4})(\d{4})$/, '$1 $2')
}

const CubanPhoneInputWithRef = (
  {
    value,
    onChange,
    containerClassName: containerClassnames,
    className: inputClassnames,
    ...restOfProps
  }: CubanPhoneInputProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const _onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phoneText = e.target.value

    if (phoneText.length > 8) return

    onChange?.(e)
  }

  return (
    <div
      className={cn(
        CustomInputStyles(),
        'flex gap-2 items-center w-full',
        containerClassnames
      )}
    >
      <span className="flex items-center gap-2 text-sm">
        <CuIcon />
        +53
      </span>
      <input
        {...restOfProps}
        className={cn(
          'w-full font-normal border-none outline-none',
          inputClassnames
        )}
        maxLength={8}
        onKeyDown={handleAcceptOnlyNumbers}
        onChange={_onChangeText}
        ref={ref}
        value={maskCubanPhone(clearPhonePrefix({ value, countryCode: 'cu' }))}
      />
    </div>
  )
}

export const CubanPhoneInput = forwardRef(CubanPhoneInputWithRef)
