import { cva } from 'class-variance-authority'

export const CardStyles = {
  root: cva('rounded-lg border bg-card text-card-foreground shadow-sm'),
  header: cva('flex flex-col space-y-1.5 p-6'),
  title: cva('text-2xl font-semibold leading-none tracking-tight'),
  description: cva('text-sm text-muted-foreground'),
  content: cva('p-6'),
  footer: cva('flex items-center p-6 pt-0'),
}
