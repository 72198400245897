import { IconProps } from './types'

const VisaIcon = ({ size = 20, color = '#F5F6F9', ...rest }: IconProps) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 37.6 23.6"
    xmlSpace="preserve"
    {...rest}
  >
    <style>{'.st1{fill:#fff}'}</style>
    <path
      id="XMLID_10_"
      d="M35.6 0H2C.9 0 0 .9 0 2v19.6c0 1.1.9 2 2 2h33.6c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2z"
      fill="#0e4595"
    />
    <g id="XMLID_1_">
      <path
        id="XMLID_2_"
        className="st1"
        d="M15.6 6.9L13.9 16.7 16.6 16.7 18.3 6.9z"
      />
      <path
        id="XMLID_3_"
        className="st1"
        d="M23.6 10.8c-.9-.5-1.5-.8-1.5-1.2 0-.4.5-.8 1.5-.8.9 0 1.5.2 2 .4l.2.1.4-2.1c-.5-.2-1.4-.4-2.4-.4-2.6 0-4.5 1.3-4.5 3.2 0 1.4 1.3 2.2 2.3 2.7 1 .5 1.4.8 1.4 1.2 0 .7-.8 1-1.6 1-1.1 0-1.6-.1-2.5-.5l-.3-.2-.4 2.2c.6.3 1.8.5 3 .5 2.8 0 4.6-1.3 4.7-3.4 0-1.1-.7-2-2.3-2.7z"
      />
      <path
        id="XMLID_8_"
        className="st1"
        d="M33.2 6.9h-2.1c-.6 0-1.1.2-1.4.8l-4 9h2.8s.5-1.2.6-1.5h3.4c.1.3.3 1.5.3 1.5h2.5l-2.1-9.8zm-3.3 6.4c.2-.6 1.1-2.7 1.1-2.7s.2-.6.4-.9l.2.8s.5 2.3.6 2.8h-2.3z"
      />
      <path
        id="XMLID_9_"
        className="st1"
        d="M11.7 6.9l-2.6 6.7-.3-1.4c-.5-1.6-2-3.3-3.7-4.1l2.4 8.6h2.8l4.2-9.8h-2.8z"
      />
    </g>
    <path
      id="XMLID_5_"
      d="M7.8 7.8c-.1-.7-.6-.8-1.2-.9H2.3v.2c3.3.9 5.5 2.8 6.5 5.2l-1-4.5z"
      fill="#f2ae14"
    />
  </svg>
)

export { VisaIcon }
