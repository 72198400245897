import { IconProps } from './types'

const MasterCardIcon = ({ size = 20, ...rest }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 37.6 23.6"
    xmlSpace="preserve"
    {...rest}
  >
    <path d="M35.6 0H2C.9 0 0 .9 0 2v19.6c0 1.1.9 2 2 2h33.6c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2z" />
    <ellipse cx={23.6} cy={11.8} rx={7.7} ry={7.8} fill="#f79f1a" />
    <ellipse cx={14} cy={11.8} rx={7.7} ry={7.8} fill="#ea001b" />
    <path
      d="M18.8 5.7c-1.8 1.4-3 3.6-3 6.1s1.2 4.7 3 6.1c1.8-1.4 3-3.6 3-6.1s-1.2-4.7-3-6.1z"
      fill="#ff5f01"
    />
  </svg>
)

export { MasterCardIcon }
