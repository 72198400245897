import { IconProps } from '../icons'

export const Register2Illustration = ({ size = 20, ...rest }: IconProps) => {
  return (
    <svg
      width="342"
      height="516"
      viewBox="0 0 342 516"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_29_992)">
        <path d="M342 469.053H0V470.053H342V469.053Z" fill="#010101" />
        <path
          d="M282.803 127.4C282.506 127.733 278.706 132.732 277.951 133.246C277.196 133.759 269.428 128.613 269.428 128.613L270.701 111.379C270.701 111.379 282.803 104.893 283.695 105.276C284.586 105.659 283.655 126.4 282.803 127.4Z"
          fill="#AA3F24"
        />
        <path
          d="M270.701 111.362C270.701 111.362 271.339 104.113 271.592 103.479C271.846 102.846 274.905 96.1037 274.905 96.1037C274.905 96.1037 278.345 90.0011 278.729 87.7114C278.729 87.7114 280.399 87.3315 280.399 90.5111C280.432 92.3146 280.039 94.1006 279.25 95.7237L276.755 102.589V110.752L270.701 111.362Z"
          fill="#AA3F24"
        />
        <path
          d="M273.028 110.132C273.028 109.219 273.967 96.9436 273.967 96.9436L276.612 85.5518C276.612 85.5518 278.983 86.315 279.11 90.1278C279.237 93.9406 278.308 98.2768 278.308 98.2768L279.123 108.362L273.028 110.132Z"
          fill="#AA3F24"
        />
        <path
          d="M279.901 467.17V39.4481H88.2443V467.17H67.5591V20.0674H302.737V467.17H279.901Z"
          fill="#F1B52E"
        />
        <path
          d="M87.9003 41.831L53.3159 0V508.238L87.9003 469.553V41.831Z"
          fill="#F1B52E"
        />
        <path
          d="M277.951 133.228C277.951 133.228 266.747 147.463 258.184 160.092C251.338 170.187 238.437 180.849 234.306 181.172C232.379 181.325 228.362 180.572 224.621 179.729C221.282 178.969 218.133 178.132 216.924 177.802L216.399 177.659C210.659 172.846 209.51 151.946 209.51 151.946L226.916 154.679L227.076 154.706L228.799 154.972L233.164 151.946C237.525 148.923 273.035 119.304 273.035 119.304L277.951 133.228Z"
          fill="#AA3F24"
        />
        <path
          d="M224.615 179.726C221.275 178.966 218.126 178.129 216.917 177.799C220.753 170.635 223.835 163.093 226.114 155.296C226.158 155.166 226.655 154.689 226.695 154.556C226.695 154.696 227.056 154.556 227.069 154.702C227.872 163.123 227.038 171.62 224.615 179.726Z"
          fill="#010101"
        />
        <path
          d="M135.496 82.1787C133.295 85.0616 131.849 88.4445 129.675 91.3441C128.045 93.5238 126.001 95.4368 124.809 97.8732C123.497 100.569 123.32 103.729 121.803 106.325C120.635 108.342 118.744 109.868 117.499 111.841C116.253 113.814 115.829 116.677 117.562 118.264C118.477 119.11 119.82 119.417 120.675 120.32C122.344 122.083 121.586 125.436 123.62 126.779C125.106 127.779 127.097 126.993 128.883 127.113C132.006 127.299 134.277 130.142 137.232 131.112C138.466 131.504 139.782 131.568 141.049 131.296C142.315 131.024 143.489 130.426 144.452 129.562C145.387 128.683 146.07 127.571 146.429 126.34C146.788 125.11 146.811 123.806 146.496 122.563C147.648 122.147 148.961 122.613 149.912 123.373C150.864 124.133 151.552 125.16 152.34 126.083C154.01 128.042 156.211 129.612 158.722 130.259C161.233 130.905 164.065 130.552 166.156 129.059C167.452 128.142 168.474 126.819 169.95 126.219C171.619 125.533 173.72 125.886 175.146 124.756C176.458 123.733 176.686 121.9 177.397 120.423C178.108 118.947 179.341 117.74 180.109 116.277C182.112 112.498 180.566 107.798 178.105 104.279C175.644 100.759 172.337 97.8698 170.247 94.147C167.799 89.7909 167.091 84.435 163.645 80.7955C161.307 78.3058 157.907 76.9527 154.498 76.4161C147.608 75.3229 139.964 76.3295 135.496 82.1787Z"
          fill="#010101"
        />
        <path
          d="M226.802 153.649C226.802 153.649 225.603 166.298 218.867 179.129L211.25 178.129L207.209 175.99C207.209 175.99 198.667 175.826 191.366 175.47C186.15 175.21 181.568 174.85 181.184 174.377C181.154 174.333 181.101 174.26 181.037 174.157C179.287 171.46 166.874 148.437 176.903 139.021C176.903 139.021 207.293 148.184 213.411 151.546L216.854 151.316L226.802 153.649Z"
          fill="#349898"
        />
        <path
          d="M128.92 223.773C128.92 223.773 128.683 211.108 126.616 205.846L178.509 207.909C178.509 207.909 177.654 208.462 178.125 215.204C178.596 221.947 140.762 239.874 128.92 223.773Z"
          fill="#AA3F24"
        />
        <path
          d="M152.861 154.396C137.676 151.44 133.886 139.288 135.86 134.459C135.86 134.459 138.301 130.959 139.092 128.933C139.884 126.906 138.942 124.65 138.311 119.664L156.378 121.837C156.511 123.847 156.746 125.85 157.083 127.836C157.146 128.19 157.239 128.646 157.346 129.123C157.559 130.171 157.824 131.208 158.141 132.229C158.221 132.319 158.318 132.416 158.428 132.519C159.871 133.852 163.735 135.639 163.735 135.639C163.735 135.639 168.046 157.346 152.861 154.396Z"
          fill="#AA3F24"
        />
        <path
          d="M128.322 214.474C128.322 214.474 165.522 219.14 177.985 211.348V203.556L127.658 209.788L128.322 214.474Z"
          fill="#010101"
        />
        <path
          d="M194.95 328.165C194.95 328.165 194.355 329.955 193.544 332.581C191.874 338.057 189.259 347.166 189.259 351.245C189.259 351.342 189.259 351.442 189.259 351.545C189.216 357.491 187.87 364.4 184.918 371.303C181.912 378.322 174.535 408.741 170.184 423.449C165.832 438.157 165.508 465.566 165.508 465.566C159.477 474.259 146.416 475.262 145.745 472.585C145.073 469.909 148.75 455.205 149.418 450.192C150.086 445.179 148.75 407.068 149.418 397.039C150.086 387.01 153.76 375.312 156.331 368.293C157.333 365.563 157.547 360.817 157.446 356.035C157.29 348.506 156.331 340.88 156.331 340.88C157.203 337.341 162.052 334.727 168.04 332.831C179.424 329.202 194.95 328.165 194.95 328.165Z"
          fill="#AA3F24"
        />
        <path
          d="M191.366 175.46C186.15 175.2 181.568 174.84 181.184 174.366C181.154 174.323 181.101 174.25 181.037 174.146L182.707 161.618L191.366 175.46Z"
          fill="#010101"
        />
        <path
          d="M182.396 208.005C162.122 218.237 126.626 211.575 126.626 211.575C125.186 206.852 125.14 205.999 123.954 202.429C121.172 194.197 119.052 188.885 117.756 185.872C116.811 183.675 116.31 182.702 116.31 182.702C116.28 159.175 117.455 143.058 117.455 143.058L136.284 133.815C136.284 133.815 137.676 148.11 150.417 148.33C163.842 148.557 160.395 133.815 160.395 133.815L176.929 139.011C189.76 162.228 182.396 208.005 182.396 208.005Z"
          fill="#349898"
        />
        <path
          d="M125.534 169.67L123.927 202.429C121.146 194.197 119.025 188.884 117.729 185.871L125.534 169.67Z"
          fill="#010101"
        />
        <path
          d="M156.294 332.975C156.144 340.661 154.625 348.41 152.621 355.709C149.084 368.68 144.105 380.205 142.866 387.334C140.862 398.866 127.791 437.474 127.541 454.525C127.29 471.576 125.203 483.275 125.717 486.281C126.232 489.287 114.333 488.614 112.216 487.614C104.364 483.891 108.208 467.057 109.21 456.022C110.212 444.987 108.709 402.362 109.21 387.331C109.654 374.053 116.754 359.212 119.609 349.36C119.967 348.16 120.265 346.943 120.501 345.713C121.149 341.818 121.396 337.867 121.239 333.922C121.16 330.988 120.914 328.062 120.501 325.156C120.501 325.156 155.937 308.608 156.308 330.669C156.314 331.439 156.314 332.205 156.294 332.975Z"
          fill="#AA3F24"
        />
        <path
          d="M164.272 117.597C163.653 119.895 162.852 122.141 161.878 124.313C161.57 124.989 161.228 125.648 160.853 126.289C159.851 128.009 158.849 129.019 158.011 129.076C156.171 129.199 145.371 127.019 143.283 125.453C143.187 125.376 143.07 125.283 142.949 125.166C142.666 124.906 142.515 124.75 142.515 124.75C141.641 123.846 140.825 122.889 140.071 121.883C136.674 117.431 132.186 109.658 132.62 102.129C133.235 91.4909 139.633 86.2949 139.633 86.2949C148.223 80.1324 158.535 84.7251 162.95 91.011C167.365 97.2968 166.109 110.952 164.272 117.597Z"
          fill="#AA3F24"
        />
        <path
          d="M127.514 218.311L90.8859 327.752C90.8859 327.752 94.0618 332.565 99.0043 334.168C103.947 335.771 114.533 347.323 123.006 345.077C131.478 342.83 143.016 346.04 145.895 348.929C148.774 351.819 169.606 352.136 174.184 347.646C178.763 343.157 183.011 341.547 191.126 341.547C199.241 341.547 208.775 338.341 210.893 333.528C213.01 328.716 219.365 326.146 220.07 323.26C220.774 320.373 179.02 213.588 179.02 213.588C179.02 213.588 157.046 223.56 129.057 217.444C128.738 217.376 128.405 217.426 128.121 217.586C127.837 217.745 127.621 218.003 127.514 218.311Z"
          fill="#349898"
        />
        <path
          d="M155.052 87.0345C155.226 88.9942 153.646 90.7007 151.97 91.7572C150.293 92.8138 148.366 93.627 147.1 95.1534C145.574 97.0032 145.327 99.5462 144.389 101.743C143.61 103.495 142.402 105.025 140.876 106.189C139.35 107.354 137.555 108.117 135.656 108.408C134.417 105.742 132.26 103.659 129.698 102.139C129.949 96.9065 131.839 91.8072 135.332 87.8511C138.825 83.895 144.015 81.3287 149.325 81.3587C150.887 81.3587 152.521 81.6252 153.766 82.5551C155.012 83.485 155.73 85.648 155.052 87.0345Z"
          fill="#010101"
        />
        <path
          d="M167.675 102.613C167.442 101.113 166.126 100.017 164.793 99.2502C163.461 98.4836 161.985 97.867 160.99 96.7071C159.814 95.3373 159.504 93.4309 158.475 91.9511C157.746 90.9577 156.733 90.2085 155.568 89.8026C154.403 89.3967 153.143 89.3533 151.953 89.6781C152.119 87.3168 151.611 84.9568 150.487 82.8723C153.058 82.8406 155.599 83.419 157.902 84.56C160.205 85.701 162.203 87.3719 163.731 89.4348C166.543 93.321 166.897 97.9036 167.675 102.613Z"
          fill="#010101"
        />
        <path
          d="M133.468 103.84C133.127 103.612 132.733 103.475 132.323 103.444C131.914 103.412 131.503 103.487 131.131 103.66C130.206 104.163 129.932 105.346 129.885 106.386C129.809 108.123 130.086 109.858 130.7 111.485C131.034 112.382 131.508 113.278 132.31 113.818C133.111 114.358 134.313 114.422 134.981 113.738C135.466 113.245 135.582 112.512 135.629 111.825C135.82 109.369 135.876 105.299 133.468 103.84Z"
          fill="#AA3F24"
        />
        <path
          d="M104.852 513.68C104.852 513.68 116.41 518.423 126.983 514.317C131.992 512.38 134.383 506.588 132.072 501.762C132.002 501.612 131.926 501.462 131.849 501.312C129.902 497.589 125.801 481.721 126.031 479.195C126.262 476.669 109.247 476.045 107.383 480.991C105.52 485.937 101.502 503.322 101.502 503.322C101.502 503.322 100.721 511.94 104.852 513.68Z"
          fill="#010101"
        />
        <path
          d="M142.699 488.463C142.699 488.463 143.367 474.669 144.168 468.573C144.204 468.304 144.31 468.049 144.477 467.835C144.644 467.62 144.865 467.455 145.118 467.354C145.371 467.254 145.646 467.223 145.914 467.264C146.183 467.306 146.436 467.418 146.646 467.589C149.144 469.589 153.496 472.495 156.311 470.902C160.452 468.569 160.786 464.887 165.475 462.547C165.475 462.547 184.904 478.395 193.611 481.001C202.317 483.607 204.664 488.62 204.331 491.963C204.331 491.963 177.2 496.296 171.84 493.629C166.48 490.963 142.372 494.366 142.699 488.463Z"
          fill="#010101"
        />
        <path
          d="M89.0359 205.846C88.762 205.426 89.0626 202.79 89.5067 199.847C90.1402 196.021 91.59 192.376 93.758 189.158C93.9526 188.858 94.1138 188.537 94.2388 188.201C98.293 177.536 107.383 148.374 109.855 146.647C112.914 144.511 117.432 143.058 117.432 143.058C117.432 143.058 126.539 158.869 126.616 167.424C126.693 175.98 118.992 196.064 118.992 196.064L118.731 199.813L116.1 210.388C115.699 211.474 115.026 212.438 114.146 213.191C113.265 213.943 112.206 214.457 111.07 214.685C106.265 215.634 95.892 216.448 89.0359 205.846Z"
          fill="#349898"
        />
        <path
          d="M114.971 208.655C114.771 209.827 114.256 210.922 113.481 211.825C111.013 214.774 105.961 216.307 100.5 215.404C96.4929 214.738 93.0899 212.888 91.0361 210.525C89.4265 208.675 88.6349 206.525 88.9889 204.352C89.383 202.019 91.0661 200.086 93.504 198.853C96.1757 197.52 99.7189 196.983 103.463 197.603C109.567 198.603 114.203 202.346 114.951 206.462C115.084 207.186 115.091 207.929 114.971 208.655Z"
          fill="#010101"
        />
        <path
          d="M114.951 206.462C114.53 208.129 114.029 209.995 113.481 211.825C112.603 214.765 111.604 217.607 110.619 219.364C107.841 224.327 94.1286 260.785 94.1286 260.785L82.7574 255.119C82.7574 255.119 87.1222 242.061 88.9055 221.664C89.2161 218.114 89.3764 214.045 89.8773 211.122C90.2881 208.722 90.6588 206.062 91.0495 204.249C91.5671 201.826 93.2303 199.916 93.5041 198.86C96.1757 197.527 99.719 196.99 103.463 197.61C109.567 198.613 114.203 202.346 114.951 206.462Z"
          fill="#AA3F24"
        />
        <path
          d="M85.7029 253.836C85.7029 253.836 72.8123 257.405 72.8857 264.281C72.9024 265.731 71.8838 273 73.8542 279.122C74.0479 279.729 81.5552 283.249 83.3919 281.642C85.2287 280.036 86.3775 279.176 88.9323 273.677C91.487 268.177 92.559 260.012 92.559 260.012L85.7029 253.836Z"
          fill="#AA3F24"
        />
        <path
          d="M158.462 132.525C150.447 132.022 144.252 126.899 142.452 125.253C142.542 125.333 142.626 125.4 142.699 125.456C144.766 127.009 155.369 129.156 157.366 129.079C157.7 130.506 158.101 132.155 158.171 132.232C158.241 132.309 158.338 132.422 158.462 132.525Z"
          fill="#010101"
        />
        <path
          d="M158.879 115.771C158.215 115.765 157.568 115.56 157.022 115.183C156.476 114.805 156.056 114.273 155.817 113.654L156.952 113.258C157.096 113.651 157.358 113.991 157.703 114.229C158.048 114.467 158.459 114.593 158.879 114.587C159.299 114.582 159.706 114.447 160.046 114.201C160.385 113.954 160.639 113.608 160.773 113.211C159.267 111.766 158.379 109.798 158.291 107.715L159.497 107.672C159.54 108.603 159.769 109.516 160.171 110.357C160.572 111.198 161.138 111.951 161.835 112.571L162.082 112.788L162.028 113.121C161.895 113.814 161.541 114.446 161.02 114.923C160.498 115.399 159.836 115.696 159.133 115.767C159.048 115.773 158.964 115.774 158.879 115.771Z"
          fill="#010101"
        />
        <path
          d="M152.344 107.696C152.348 108.001 152.26 108.301 152.093 108.557C151.926 108.813 151.686 109.013 151.404 109.133C151.123 109.252 150.812 109.285 150.511 109.228C150.21 109.171 149.934 109.025 149.716 108.81C149.498 108.595 149.35 108.321 149.289 108.021C149.228 107.722 149.258 107.411 149.374 107.129C149.491 106.846 149.689 106.605 149.944 106.435C150.199 106.265 150.498 106.175 150.804 106.176C151.005 106.174 151.205 106.213 151.391 106.289C151.577 106.364 151.747 106.476 151.89 106.617C152.033 106.758 152.146 106.926 152.224 107.111C152.302 107.296 152.343 107.495 152.344 107.696Z"
          fill="#010101"
        />
        <path
          d="M165.291 106.899C165.295 107.204 165.208 107.504 165.041 107.76C164.874 108.015 164.634 108.216 164.353 108.335C164.071 108.455 163.76 108.488 163.46 108.431C163.159 108.374 162.882 108.229 162.664 108.015C162.447 107.8 162.298 107.526 162.237 107.227C162.175 106.927 162.205 106.617 162.321 106.334C162.437 106.052 162.635 105.81 162.889 105.64C163.143 105.47 163.442 105.379 163.748 105.379C163.95 105.378 164.149 105.416 164.336 105.492C164.522 105.567 164.692 105.679 164.835 105.82C164.978 105.961 165.092 106.129 165.171 106.314C165.249 106.499 165.29 106.698 165.291 106.899Z"
          fill="#010101"
        />
        <path
          d="M157.059 121.487H157.006C156.161 121.476 155.329 121.283 154.566 120.922C153.803 120.56 153.127 120.039 152.584 119.394L153.482 118.65C153.917 119.165 154.457 119.581 155.067 119.868C155.677 120.156 156.341 120.309 157.016 120.317H157.059C157.727 120.313 158.387 120.168 158.994 119.89C159.602 119.613 160.143 119.209 160.582 118.707L161.464 119.467C160.915 120.096 160.239 120.602 159.479 120.95C158.72 121.298 157.895 121.481 157.059 121.487Z"
          fill="#010101"
        />
        <path
          d="M147.224 106.239L146.252 105.539C147.155 104.342 148.445 103.493 149.902 103.136C151.367 102.791 152.906 102.957 154.264 103.606L153.733 104.669C152.634 104.136 151.384 104.001 150.196 104.287C149.008 104.574 147.958 105.264 147.224 106.239Z"
          fill="#010101"
        />
        <path
          d="M166.927 105.659C166.634 105.071 166.198 104.564 165.659 104.186C165.12 103.808 164.496 103.57 163.842 103.493C163.187 103.416 162.524 103.502 161.912 103.745C161.3 103.988 160.758 104.378 160.335 104.882L159.407 104.129C159.958 103.471 160.665 102.96 161.463 102.642C162.261 102.324 163.127 102.21 163.98 102.309C164.834 102.408 165.65 102.718 166.354 103.21C167.058 103.703 167.628 104.362 168.013 105.129L166.927 105.659Z"
          fill="#010101"
        />
        <path
          d="M131.715 111.162C130.919 110.227 130.432 109.068 130.323 107.846C130.213 106.623 130.486 105.397 131.104 104.336L132.139 104.946C131.647 105.791 131.43 106.769 131.517 107.743C131.604 108.717 131.992 109.64 132.627 110.385L131.715 111.162Z"
          fill="#010101"
        />
        <path
          d="M135.205 114.304C135.205 115.507 134.384 116.481 133.372 116.481C132.36 116.481 131.535 115.507 131.535 114.304C131.535 113.101 132.356 112.128 133.372 112.128C134.387 112.128 135.205 113.104 135.205 114.304Z"
          fill="#FAE6C9"
        />
        <mask
          id="mask0_29_992"
          maskUnits="userSpaceOnUse"
          x="90"
          y="213"
          width="131"
          height="139"
        >
          <path
            d="M127.891 217.181L90.8859 327.752C90.8859 327.752 94.0618 332.565 99.0043 334.168C103.947 335.771 114.533 347.323 123.006 345.077C131.478 342.83 143.016 346.04 145.895 348.929C148.774 351.819 169.606 352.136 174.184 347.646C178.763 343.157 183.011 341.547 191.126 341.547C199.241 341.547 208.775 338.341 210.893 333.528C213.01 328.716 219.365 326.146 220.07 323.26C220.774 320.373 179.02 213.588 179.02 213.588C179.02 213.588 156.435 223.84 127.891 217.181Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_29_992)">
          <path
            d="M123.537 353.106L122.615 352.716C131.336 331.922 135.244 309.432 134.046 286.921L135.048 286.868C136.252 309.53 132.317 332.172 123.537 353.106Z"
            fill="#010101"
          />
          <path
            d="M226.826 344.783C215.054 336.211 205.426 323.453 198.212 306.875C191.824 292.187 188.077 276.386 184.45 261.108L185.425 260.878C189.042 276.113 192.772 291.874 199.117 306.475C206.261 322.893 215.775 335.511 227.4 343.973L226.826 344.783Z"
            fill="#010101"
          />
          <path
            d="M148.216 323.81L147.214 323.707C149.399 302.891 149.054 281.888 146.186 261.155L147.187 261.019C150.064 281.831 150.409 302.915 148.216 323.81Z"
            fill="#010101"
          />
          <path
            d="M187.446 373.613C178.174 356.345 173.394 337.031 173.543 317.44H174.545C174.397 336.863 179.138 356.013 188.331 373.133L187.446 373.613Z"
            fill="#010101"
          />
          <path
            d="M124.589 219.827C124.589 219.827 157.61 227.116 184.938 214.918L187.887 220.284C187.887 220.284 159.567 232.199 123.076 224.56L124.589 219.827Z"
            fill="#010101"
          />
        </g>
        <path
          d="M131.311 371.72C127.313 371.739 123.392 370.613 120.017 368.474L120.561 367.634C123.32 369.37 126.462 370.41 129.714 370.662C132.966 370.914 136.231 370.371 139.226 369.08L139.633 369.993C137.011 371.143 134.176 371.732 131.311 371.72Z"
          fill="#010101"
        />
        <path
          d="M175.54 370.23L175.507 369.23C179.08 369.11 182.521 367.856 185.332 365.65L185.953 366.437C182.974 368.773 179.327 370.102 175.54 370.23Z"
          fill="#010101"
        />
        <path
          d="M232.069 171.101C230.352 161.102 228.338 155.196 228.318 155.136L229.267 154.803C229.287 154.859 231.327 160.839 233.054 170.921L232.069 171.101Z"
          fill="#010101"
        />
        <path
          d="M212.562 151.293C212.278 151.233 211.998 151.159 211.717 151.076C211.758 154.65 210.894 158.176 209.206 161.328L210.088 161.801C211.811 158.584 212.715 154.994 212.723 151.346L212.562 151.293Z"
          fill="#010101"
        />
        <path
          d="M213.538 151.652C213.565 158.469 212.162 165.216 209.42 171.46L210.338 171.863C213.367 164.997 213.925 162.058 213.621 154.562L213.538 151.652Z"
          fill="#010101"
        />
        <path
          d="M118.567 196.333C111.244 184.958 103.152 186.521 103.072 186.538L102.858 185.561C103.215 185.485 111.688 183.795 119.412 195.793L118.567 196.333Z"
          fill="#010101"
        />
        <path
          d="M133.271 209.595V208.595C133.605 208.595 165.815 208.322 178.152 195.717L178.87 196.413C166.236 209.322 134.611 209.588 133.271 209.595Z"
          fill="#010101"
        />
        <mask
          id="mask1_29_992"
          maskUnits="userSpaceOnUse"
          x="142"
          y="462"
          width="63"
          height="33"
        >
          <path
            d="M142.699 488.463C142.699 488.463 143.367 474.669 144.168 468.573C144.204 468.304 144.31 468.049 144.477 467.835C144.644 467.62 144.865 467.455 145.118 467.354C145.371 467.254 145.646 467.223 145.914 467.264C146.183 467.306 146.436 467.418 146.646 467.589C149.144 469.589 153.496 472.495 156.311 470.902C160.452 468.569 160.786 464.887 165.475 462.547C165.475 462.547 184.904 478.395 193.611 481.001C202.317 483.607 204.664 488.62 204.331 491.963C204.331 491.963 177.2 496.296 171.84 493.629C166.48 490.963 142.372 494.366 142.699 488.463Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_29_992)">
          <path
            d="M192.154 491.04C188.918 491.004 185.691 490.696 182.507 490.12C180.446 489.767 178.359 489.347 176.338 488.94C170.851 487.83 165.178 486.691 159.54 486.857C158.412 486.891 157.26 486.977 156.144 487.064C154.221 487.251 152.288 487.315 150.357 487.254C147.772 487.114 144.172 486.417 141.674 483.874L142.389 483.178C144.649 485.487 147.989 486.127 150.403 486.254C152.291 486.312 154.181 486.25 156.061 486.067C157.186 485.981 158.352 485.891 159.504 485.857C165.258 485.677 170.988 486.841 176.535 487.957C178.539 488.364 180.626 488.784 182.673 489.134C192.395 490.8 199.548 490.163 205.179 487.161L205.65 488.044C201.375 490.323 196.613 491.04 192.154 491.04Z"
            fill="#FAE6C9"
          />
          <path
            d="M183.642 480.271L183.238 480.144C174.517 477.358 166.485 472.761 159.671 466.656L160.339 465.913C166.944 471.828 174.711 476.307 183.144 479.065C184.061 477.467 185.261 476.049 186.684 474.879L187.329 475.645C185.893 476.817 184.705 478.261 183.832 479.895L183.642 480.271Z"
            fill="#FAE6C9"
          />
          <path
            d="M168.2 463.767C165.134 465.426 162.605 467.92 160.906 470.959C160.761 471.222 160.715 471.528 160.776 471.821C160.837 472.115 161.002 472.377 161.24 472.559L165.127 475.522C165.261 475.624 165.414 475.699 165.577 475.741C165.74 475.783 165.91 475.792 166.077 475.767C166.243 475.743 166.403 475.685 166.547 475.598C166.691 475.511 166.816 475.395 166.914 475.259C168.036 473.692 170.274 470.793 172.495 469.26C172.639 469.157 172.759 469.026 172.849 468.874C172.938 468.722 172.995 468.552 173.014 468.377C173.033 468.202 173.015 468.024 172.96 467.856C172.906 467.689 172.817 467.534 172.698 467.403L169.716 464.07C169.533 463.858 169.285 463.713 169.01 463.658C168.736 463.603 168.45 463.642 168.2 463.767Z"
            fill="#F1B52E"
          />
        </g>
        <mask
          id="mask2_29_992"
          maskUnits="userSpaceOnUse"
          x="101"
          y="477"
          width="32"
          height="39"
        >
          <path
            d="M104.852 513.68C104.852 513.68 116.41 518.423 126.983 514.317C131.992 512.38 134.383 506.588 132.072 501.762C132.002 501.612 131.926 501.462 131.849 501.312C129.902 497.589 125.801 481.721 126.031 479.195C126.262 476.669 109.247 476.045 107.383 480.991C105.52 485.937 101.502 503.322 101.502 503.322C101.502 503.322 100.721 511.94 104.852 513.68Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_29_992)">
          <path
            d="M105.189 507.908C102.795 507.908 100.27 507.041 99.1212 504.908L100.003 504.435C101.158 506.578 104.1 507.171 106.505 506.808C107.807 506.574 109.089 506.24 110.339 505.808C111.608 505.381 112.908 505.047 114.226 504.808C116.994 504.368 119.793 504.788 122.495 505.195C123.747 505.381 125.039 505.575 126.302 505.678C131.034 506.068 134.871 504.678 136.571 502.012L137.419 502.545C134.677 506.894 128.673 506.894 126.218 506.691C124.923 506.584 123.613 506.391 122.348 506.198C119.72 505.805 117.004 505.398 114.383 505.811C113.114 506.047 111.864 506.376 110.643 506.794C109.343 507.243 108.009 507.588 106.655 507.828C106.169 507.891 105.679 507.918 105.189 507.908Z"
            fill="#FAE6C9"
          />
          <path
            d="M122.495 497.839L121.793 497.742C119.406 497.409 117.248 497.172 114.991 497.535C113.735 497.735 112.747 497.869 111.471 497.612L111.137 497.542L111.083 497.209C110.002 490.229 109.857 483.136 110.653 476.118L111.655 476.232C110.883 483.041 111.007 489.922 112.025 496.699C112.969 496.804 113.924 496.758 114.854 496.562C117.021 496.229 119.058 496.385 121.256 496.672C120.005 489.711 120.018 482.582 121.296 475.625L122.278 475.818C120.982 482.873 121.014 490.106 122.371 497.149L122.495 497.839Z"
            fill="#FAE6C9"
          />
          <path
            d="M110.516 480.774C113.247 480.161 118.387 479.507 122.384 480.701C122.878 480.85 123.311 481.153 123.618 481.567C123.926 481.981 124.092 482.482 124.091 482.997V484.403C124.091 484.734 124.023 485.062 123.89 485.365C123.757 485.668 123.563 485.941 123.32 486.165C123.076 486.39 122.789 486.562 122.476 486.671C122.162 486.779 121.83 486.822 121.5 486.797C118.721 486.58 114.296 486.317 111.214 486.566C110.884 486.592 110.552 486.549 110.239 486.44C109.927 486.331 109.64 486.159 109.397 485.934C109.154 485.709 108.961 485.437 108.829 485.134C108.696 484.831 108.628 484.504 108.629 484.173V483.117C108.63 482.571 108.817 482.041 109.16 481.615C109.503 481.189 109.981 480.892 110.516 480.774Z"
            fill="#F1B52E"
          />
        </g>
        <path
          d="M270.066 121.4C270.066 121.4 270.317 120.14 270.701 118.643C271.158 116.88 271.803 114.781 272.444 114.024C273.633 112.624 279.494 107.025 279.494 107.025C279.494 107.025 283.835 102.829 284.206 101.812C284.576 100.796 286.5 102.956 285.608 106.515C284.717 110.075 279.874 115.544 279.621 115.924C279.367 116.304 278.322 126.349 276.615 127.622C274.908 128.895 269.939 125.982 270.066 121.4Z"
          fill="#AA3F24"
        />
        <path d="M53.3126 0H43.4609V508.238H53.3126V0Z" fill="#010101" />
        <path
          d="M63.0239 235.621H81.1276V305.059H63.0239V310.175H83.9896C84.9347 310.175 85.841 309.8 86.5093 309.133C87.1775 308.466 87.5529 307.562 87.5529 306.618V233.858C87.5529 232.915 87.1776 232.01 86.5095 231.343C85.8413 230.675 84.935 230.3 83.9896 230.299H63.0406L63.0239 235.621Z"
          fill="#010101"
        />
        <path
          d="M88.5916 256.436L89.0358 265.198L86.6446 267.741C85.3489 269.117 83.7626 270.327 81.9025 270.684C80.7737 270.9 81.3448 276.426 91.1164 272.53C92.3052 272.057 94.2421 270.164 95.2406 269.134C95.448 268.919 95.6044 268.66 95.6986 268.377C95.7928 268.094 95.8224 267.793 95.785 267.497C95.6548 266.471 95.3542 264.358 94.6562 260.002C94.2354 257.382 88.5916 256.436 88.5916 256.436Z"
          fill="#AA3F24"
        />
        <path
          d="M75.4639 262.244L80.4732 268.863L86.4844 272.123C86.4844 272.123 89.7505 272.633 90.5653 273.853C90.8174 274.242 90.9836 274.681 91.0529 275.139C91.0849 275.344 91.0735 275.553 91.0194 275.754C90.9653 275.954 90.8698 276.141 90.7389 276.302C90.608 276.463 90.4446 276.595 90.2594 276.689C90.0742 276.783 89.8712 276.838 89.6636 276.849C87.2692 277.002 82.2966 276.879 79.0338 273.753C74.1982 269.117 73.5871 269.486 73.5871 269.486C73.5871 269.486 71.0757 263.771 75.4639 262.244Z"
          fill="#AA3F24"
        />
        <path
          d="M74.6023 274.913L79.3478 280.732L86.5946 282.565L91.3502 281.152C91.6034 281.079 91.8342 280.944 92.0215 280.759C92.2088 280.573 92.3465 280.344 92.4221 280.092C92.6459 279.329 92.7194 278.256 91.5939 277.806C89.7572 277.066 84.2068 276.316 84.2068 276.316L76.0817 268.174C76.0817 268.174 73.6371 269.607 74.6023 274.913Z"
          fill="#AA3F24"
        />
        <path
          d="M73.8541 279.122L76.6393 282.539C77.3597 283.421 78.3456 284.049 79.4512 284.328L82.2765 285.045C83.2055 285.278 84.1802 285.257 85.0984 284.985L88.1975 284.065C88.1975 284.065 89.3095 282.102 87.8001 281.496C86.2906 280.889 82.1863 280.726 82.1863 280.726L75.0697 273.59C75.0697 273.59 73.0559 274.766 73.8541 279.122Z"
          fill="#AA3F24"
        />
        <path
          d="M83.4653 275.413C82.8553 275.435 82.2524 275.277 81.7316 274.959C81.2107 274.642 80.7951 274.178 80.5365 273.626C80.3421 273.233 80.2655 272.792 80.3158 272.356C80.3662 271.921 80.5414 271.509 80.8204 271.17C81.9762 269.893 83.3444 268.825 84.8647 268.014L85.3355 268.897C83.9179 269.655 82.6413 270.65 81.5618 271.84C81.414 272.036 81.3252 272.271 81.3057 272.516C81.2862 272.76 81.3368 273.006 81.4516 273.223C81.6308 273.596 81.9167 273.909 82.2732 274.12C82.6298 274.332 83.041 274.434 83.4553 274.413C83.9529 274.413 84.711 274.133 85.5125 273.836C86.8183 273.353 88.2944 272.81 89.7805 272.93L89.697 273.93C88.4346 273.826 87.0654 274.333 85.8598 274.776C84.9415 275.116 84.1399 275.413 83.4653 275.413Z"
          fill="#010101"
        />
        <path
          d="M88.2275 277.476C84.0097 276.886 81.4783 275.916 81.121 275.773C78.8 275.223 75.1098 271.773 74.9395 271.633L75.6074 270.903C75.6441 270.94 79.3176 274.346 81.3681 274.806L81.4516 274.833C81.4783 274.833 84.0264 275.879 88.3545 276.499L88.2275 277.476Z"
          fill="#010101"
        />
        <path
          d="M88.2644 282.559C86.8985 282.465 82.7808 281.809 80.4498 281.422C79.5682 281.276 78.7703 280.814 78.2056 280.122L74.2148 275.23L74.993 274.6L79.0004 279.492C79.4109 279.994 79.9901 280.329 80.6301 280.435C82.4936 280.742 86.9753 281.469 88.3478 281.559L88.2644 282.559Z"
          fill="#010101"
        />
        <path
          d="M94.2756 259.715C92.2718 257.382 91.7742 256.382 91.3434 255.506L91.2566 255.336L92.1549 254.893L92.2417 255.066C92.6659 255.929 93.1067 256.819 95.0336 259.065L94.2756 259.715Z"
          fill="#010101"
        />
        <path
          d="M81.6788 269.483C79.3411 267.597 77.8616 264.444 77.8015 264.311L78.7099 263.891C78.7099 263.921 80.1492 266.97 82.3066 268.703L81.6788 269.483Z"
          fill="#010101"
        />
        <path
          d="M86.0536 274.379L85.0517 274.269C85.1004 273.702 85.0146 273.13 84.8011 272.602C84.5876 272.073 84.2524 271.602 83.8228 271.227C82.9184 271.657 82.1476 272.324 81.592 273.156L80.7571 272.606C81.4762 271.524 82.5031 270.682 83.7058 270.187L83.963 270.08L84.1868 270.24C84.8306 270.709 85.3411 271.338 85.6684 272.063C85.9957 272.789 86.1284 273.587 86.0536 274.379Z"
          fill="#010101"
        />
        <path
          d="M271.185 119.401L270.22 119.121C270.357 118.654 271.556 114.525 272.016 113.788C272.477 113.052 277.72 107.296 278.315 106.643L279.056 107.309C276.825 109.755 273.172 113.818 272.871 114.308C272.571 114.798 271.672 117.674 271.185 119.401Z"
          fill="#010101"
        />
        <path
          d="M272.527 112.535C272.444 109.815 273.239 99.9102 273.272 99.4902L274.274 99.5702C274.274 99.6735 273.449 109.839 273.533 112.502L272.527 112.535Z"
          fill="#010101"
        />
        <path
          d="M278.018 128.089L277.066 127.779C278.953 122.007 279.11 115.988 279.113 115.928L280.115 115.951C280.118 116.011 279.958 122.17 278.018 128.089Z"
          fill="#010101"
        />
        <path
          d="M63.4514 243.62C65.5706 243.62 67.2885 239.068 67.2885 233.452C67.2885 227.836 65.5706 223.283 63.4514 223.283C61.3322 223.283 59.6143 227.836 59.6143 233.452C59.6143 239.068 61.3322 243.62 63.4514 243.62Z"
          fill="#010101"
        />
        <path
          d="M63.4514 316.651C65.5706 316.651 67.2885 312.098 67.2885 306.482C67.2885 300.866 65.5706 296.313 63.4514 296.313C61.3322 296.313 59.6143 300.866 59.6143 306.482C59.6143 312.098 61.3322 316.651 63.4514 316.651Z"
          fill="#010101"
        />
        <path
          d="M65.1546 262.744C63.4248 262.744 62.5231 259.221 62.5231 255.745C62.5231 252.269 63.4248 248.746 65.1546 248.746C66.8845 248.746 67.7862 252.266 67.7862 255.745C67.7862 259.225 66.8812 262.744 65.1546 262.744ZM65.1546 249.746C64.6003 249.746 63.5249 251.862 63.5249 255.745C63.5249 259.628 64.6003 261.744 65.1546 261.744C65.709 261.744 66.7843 259.625 66.7843 255.745C66.7843 251.866 65.709 249.739 65.1546 249.739V249.746Z"
          fill="#010101"
        />
        <path
          d="M66.3937 255.742C66.3937 257.832 65.8393 259.525 65.1547 259.525C64.47 259.525 63.9124 257.832 63.9124 255.742C63.9124 253.653 64.47 251.956 65.1547 251.956C65.8393 251.956 66.3937 253.653 66.3937 255.742Z"
          fill="#010101"
        />
        <path
          d="M72.2683 22.1077L71.4956 22.7441L87.5223 42.1266L88.2951 41.4902L72.2683 22.1077Z"
          fill="#010101"
        />
        <path
          d="M286.804 469.553H285.802V34.5721H81.485V33.5723H286.804V469.553Z"
          fill="#010101"
        />
      </g>
      <defs>
        <clipPath id="clip0_29_992">
          <rect width="342" height="516" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
