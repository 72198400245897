import { IconProps } from './types'

const DiscoverIcon = ({ size = 20, ...rest }: IconProps) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 37.6 23.6"
    xmlSpace="preserve"
    {...rest}
  >
    <style>{'.st1{fill:#fff}'}</style>
    <g id="XMLID_89_">
      <path
        id="XMLID_77_"
        d="M34.9 0H2.6C1.2 0 0 1.2 0 2.6V21c0 1.4 1.2 2.6 2.6 2.6h32.3c1.5 0 2.6-1.2 2.6-2.6V2.7c.1-1.5-1.1-2.7-2.6-2.7z"
        fill="#4d4d4d"
      />
      <g id="XMLID_76_">
        <path
          id="XMLID_78_"
          className="st1"
          d="M11.3 9.5c-.6-.2-.8-.3-.8-.6s.3-.5.7-.5c.3 0 .5.1.8.4l.5-.6c-.4-.3-.9-.5-1.5-.5-.9 0-1.6.6-1.6 1.3 0 .6.3 1 1.2 1.3.4.1.6.2.7.3.2.1.3.3.3.5 0 .4-.3.7-.8.7s-.9-.2-1.1-.6l-.6.6c.5.6 1 .9 1.8.9 1 0 1.7-.6 1.7-1.5.1-1-.2-1.3-1.3-1.7z"
        />
        <path id="XMLID_79_" className="st1" d="M7.9 7.7H8.9V12.4H7.9z" />
        <path
          id="XMLID_82_"
          className="st1"
          d="M4.7 7.7H3.2v4.7h1.4c.8 0 1.3-.2 1.8-.5.6-.4.9-1.1.9-1.8.1-1.4-1-2.4-2.6-2.4zm1.2 3.6c-.3.3-.7.4-1.4.4h-.3V8.5h.3c.6 0 1 .1 1.4.4.3.3.5.7.5 1.2 0 .4-.2.9-.5 1.2z"
        />
        <path
          id="XMLID_83_"
          className="st1"
          d="M13.1 10.1c0 1.4 1.2 2.4 2.7 2.4.4 0 .8-.1 1.2-.3v-1.1c-.4.4-.7.5-1.2.5-1 0-1.7-.7-1.7-1.6 0-.9.7-1.6 1.7-1.6.5 0 .8.2 1.2.5v-1c-.4-.2-.8-.3-1.2-.3-1.5 0-2.7 1.1-2.7 2.5z"
        />
        <path
          id="XMLID_84_"
          className="st1"
          d="M27.7 12.4L30.5 12.4 30.5 11.6 28.7 11.6 28.7 10.4 30.5 10.4 30.5 9.6 28.7 9.6 28.7 8.5 30.5 8.5 30.5 7.7 27.7 7.7z"
        />
        <path
          id="XMLID_87_"
          className="st1"
          d="M33.3 10.4c.7-.1 1.2-.6 1.2-1.3 0-.9-.7-1.4-1.8-1.4h-1.5v4.7h1v-1.9h.1l1.4 1.9h1.2l-1.6-2zm-.8-.5h-.3V8.5h.3c.6 0 1 .2 1 .7-.1.4-.4.7-1 .7z"
        />
        <path
          id="XMLID_88_"
          className="st1"
          d="M20 7.6c-1.5 0-2.7 1.1-2.7 2.5s1.2 2.5 2.7 2.5 2.7-1.1 2.7-2.5-1.2-2.5-2.7-2.5z"
        />
        <path
          id="XMLID_100_"
          className="st1"
          d="M24.8 10.9L23.5 7.7 22.4 7.7 24.5 12.5 25.1 12.5 27.3 7.7 26.2 7.7z"
        />
      </g>
      <path
        id="XMLID_90_"
        d="M10.7 23.6H35c1.5 0 2.6-1.2 2.6-2.6v-7.4c-1.3.8-10.7 7-26.9 10z"
        fill="#f47216"
      />
    </g>
  </svg>
)

export { DiscoverIcon }
