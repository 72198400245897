import { forwardRef, KeyboardEvent } from 'react'
import { GenericForwardRef } from '../types'

export const genericForwardRef = forwardRef as GenericForwardRef

export const handleAcceptOnlyNumbers = (
  event: KeyboardEvent<HTMLInputElement>
) => {
  const key = event.key
  const isNumber = /^\d$/.test(key)
  const isDeleteKey = key === 'Delete' || event.keyCode === 46
  const isBackspaceKey = key === 'Backspace' || event.keyCode === 8
  const isCtrlKey =
    (event.ctrlKey || event.metaKey) && (key === 'c' || key === 'v')
  const isTabKey = key === 'Tab' || event.keyCode === 9

  const isArrowLeft = key === 'ArrowLeft' || event.keyCode === 37
  const isArrowRight = key === 'ArrowRight' || event.keyCode === 39

  if (
    !isNumber &&
    !isDeleteKey &&
    !isBackspaceKey &&
    !isCtrlKey &&
    !isTabKey &&
    !isArrowLeft &&
    !isArrowRight
  ) {
    event.preventDefault()
  }
}

export const handleWorkAsInputNumber = (
  event: KeyboardEvent<HTMLInputElement>
) => {
  const key = event.key
  const isNumber = /^\d$/.test(key)
  const isDeleteKey =
    key === 'Delete' || key === 'Del' || key === 'X' || event.keyCode === 46
  const isBackspaceKey =
    key === 'Backspace' || event.code === 'Backspace' || event.keyCode === 8
  const isDecimalPoint =
    key === '.' || event.code === 'Period' || event.code === 'NumpadDecimal'
  const isTabKey = key === 'Tab' || event.keyCode === 9
  const isArrowLeft = key === 'ArrowLeft' || event.keyCode === 37
  const isArrowRight = key === 'ArrowRight' || event.keyCode === 39

  if (
    !(
      isNumber ||
      isDeleteKey ||
      isBackspaceKey ||
      isDecimalPoint ||
      isTabKey ||
      isArrowLeft ||
      isArrowRight
    )
  ) {
    event.preventDefault()
  }
}

export const handleAcceptOnlyLetters = (
  event: KeyboardEvent<HTMLInputElement>
) => {
  const regex = /^[a-zA-Z ]*$/
  const key = event.key
  const isLetter = regex.test(key)
  const isSpace = key === ' ' || event.keyCode === 32
  const isDeleteKey = key === 'Delete' || event.keyCode === 46
  const isBackspaceKey = key === 'Backspace' || event.keyCode === 8
  const isTabKey = key === 'Tab' || event.keyCode === 9
  const isCtrlKey =
    (event.ctrlKey || event.metaKey) && (key === 'c' || key === 'v')

  const lastCharacterIsSpace = event.currentTarget.value.endsWith(' ')

  if (
    (isSpace && lastCharacterIsSpace) ||
    (isSpace && event.currentTarget.value.includes('  '))
  ) {
    event.preventDefault()
  }

  if (!isLetter && !isDeleteKey && !isBackspaceKey && !isCtrlKey && !isTabKey) {
    event.preventDefault()
  }
}
